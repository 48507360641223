<template>
  <div id="app">
    <MainLayout>
      <router-view />
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
  name: "App",
  components: {
    MainLayout
  }
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  height: 100%;
}

#app {
  height: 100%;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.v-card__title {
  word-break: normal;
}
</style>
