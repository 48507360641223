<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="800"
  >
    <v-card>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6">
            <div class="plan-container">
              <div class="pro-plan"></div>
              <div class="plan-content">
                <div class="feature-name mb-4 pl-4">
                  <h2 class="mb-0">Pro Edition</h2>
                  <h3>FREE!</h3>
                </div>
                <div class="feature-list">
                  <ul>
                    <li>
                      Estimated ARV
                    </li>
                    <li>
                      ARV Range
                    </li>
                    <li>
                      List of Comparable Properties
                    </li>
                    <li>
                      Discard / Undiscard Compareable Properties
                    </li>
                    <li>
                      Quick Compare Two Properties
                    </li>
                    <li>
                      Compare Multiple Properties in Detail
                    </li>
                    <li>
                      Adjust Bathoroom and Square Footage
                    </li>
                    <li>
                      View Property Floorplans
                    </li>
                  </ul>
                </div>
                <div class="pro">
                  <v-btn
                    block
                    tile
                    color="primary"
                    dark
                    @click.stop="openProSignUp = true"
                  >
                    Sign Up for FREE Pro Edition
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="plan-container">
              <div class="free-plan"></div>
              <div class="plan-content">
                <div class="feature-name mb-4 pl-4">
                  <h2 class="mb-0">Limited Use</h2>
                  <h3>No sign-up required</h3>
                </div>
                <div class="feature-list">
                  <ul>
                    <li>
                      Estimated ARV
                    </li>
                    <li>
                      ARV Range
                    </li>
                    <li>
                      List of Comparable Properties
                    </li>
                  </ul>
                </div>
                <div class="free">
                  <v-btn
                    block
                    tile
                    color="green"
                    dark
                    @click.stop="show = false"
                  >
                    Continue without sign-up
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <ProUserSignUpDialog v-model="openProSignUp" @close="show = false" />
    <FreeSellerSignUpDialog
      v-model="openFreeSellerSignUp"
      @close="show = false"
    />
  </v-dialog>
</template>

<script>
import ProUserSignUpDialog from "@/components/ProUserSignUp/ProUserSignUpDialog";
import FreeSellerSignUpDialog from "@/components/FreeUserSignUp/FreeSellerSignUpDialog";

export default {
  components: {
    FreeSellerSignUpDialog,
    ProUserSignUpDialog
  },
  props: {
    value: Boolean
  },
  data: () => ({
    openProSignUp: false,
    openFreeSellerSignUp: false
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.plan-container {
  height: 100%;
  padding-bottom: 0.6rem;
  background: #fdfdfc;
  border-top: 0;
  text-align: center;

  .free-plan {
    width: 100%;
    height: 10px;
    background: green;
  }

  .seller-plan {
    width: 100%;
    height: 10px;
    background: var(--v-secondary-base);
  }

  .pro-plan {
    width: 100%;
    height: 10px;
    background: var(--v-primary-base);
  }

  .plan-content {
    display: flex;
    flex-direction: column;
    padding: 1em;
    height: 100%;
    border: 1px solid #e0e2e5;
    border-top: 0;
  }

  .feature-name {
    h2 {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }

  .feature-list {
    flex-grow: 1;
    ul {
      padding: 0;
      list-style-type: none;
    }

    li {
      padding: 1rem;
      &:nth-child(even) {
        background: #ebebeb;
      }
    }
  }
}
</style>
