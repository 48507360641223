<template>
  <div
    class="map-compare-popup-compare-item-container d-flex flex-column align-center justify-center"
  >
    <div v-if="empty" :class="imageClasses" />
    <div class="image-wrapper">
      <v-btn
        v-if="!selected && !empty"
        fab
        small
        class="close-btn"
        @click="onCompareItemRemove"
      >
        x
      </v-btn>
      <v-img
        v-if="!empty"
        :src="selectedImageUrl"
        aspect-ratio="1"
        :class="imageClasses"
      />
    </div>
    <div v-if="!empty" class="selected-info d-flex flex-column align-start">
      <p class="selected-property-info mb-2">{{ streetAddress }}</p>
      <p class="selected-property-info">
        {{ `${beds} bd` }} | {{ `${baths} ba` }} | {{ `${sqFt} sqft` }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapComparePopupCompareItem",
  components: {
    //
  },
  props: {
    propertyData: Object,
    selected: Boolean,
    empty: Boolean
  },
  data: () => ({
    //
  }),
  computed: {
    selectedImageUrl() {
      const { mlsImageUrls } = this.propertyData || {};
      if (mlsImageUrls && mlsImageUrls[0]) {
        return mlsImageUrls[0];
      }

      return `https://maps.googleapis.com/maps/api/streetview?size=200x200&location=${this.propertyData.latitude},${this.propertyData.longitude}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
    },
    streetAddress() {
      const { address } = this.propertyData;
      return (address && address.split(",")[0]) || "";
    },
    beds() {
      return this.propertyData.beds;
    },
    baths() {
      return this.propertyData.baths;
    },
    sqFt() {
      return this.propertyData.livingArea || 0;
    },
    imageClasses() {
      let imgClass = "item-img";
      if (this.selected) {
        imgClass += " selected-img";
      } else if (this.empty) {
        imgClass += " empty-img";
      }
      return imgClass;
    }
  },
  mounted() {
    //
  },
  methods: {
    onCompareItemRemove() {
      this.$emit("remove-compare-item", this.propertyData);
    }
  }
};
</script>

<style lang="scss" scoped>
.map-compare-popup-compare-item-container {
  // width: 100%;
  // position: relative;

  .selected-img {
    border: solid 2px #74cfe7;
  }

  .empty-img {
    border: dashed 2px #74cfe7;
  }

  .image-wrapper {
    position: relative;
  }

  .item-img {
    position: relative;
    width: 140px;
    height: 140px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .selected-property-info {
    font-family: "Helvetica Neue Medium";
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  .popup-right {
    flex: 1;
  }

  .popup-title {
    font-family: "Helvetica Neue Medium";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .popup-description {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 14px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: -10px;
    width: 20px !important;
    height: 20px !important;
    min-width: 20px;
    border-radius: 50%;
    z-index: 1;
  }
}
</style>
