<template>
  <div class="question-container">
    <div class="flex-grow-1">
      <p>
        <slot name="question" />
      </p>
    </div>
    <div class="text-center">
      <div class="mb-16">
        <img :src="imgSrc" />
      </div>
      <div>
        <button
          class="btn mr-3"
          :class="{ active: selectedAnswer === true }"
          @click="$emit('answer', true)"
        >
          Yes!
        </button>
        <button
          class="btn ml-3"
          :class="{ active: selectedAnswer === false }"
          @click="$emit('answer', false)"
        >
          No.
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      default: "img/images/rehab/excellent-house.png"
    },
    selectedAnswer: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.question-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.btn {
  border: 1px solid #a0c3d2;
  border-radius: 3px;
  padding: 10px 20px;
  width: 100px;

  &.active {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 3px #74cfe7;
    background-color: #e8f4fc;
    outline: none;
  }
}
</style>
