<template>
  <div class="address-icon-container">
    <img :src="addressIcon" class="address-icon" />
    <div class="order-wrapper">
      <span class="order">{{ orderNumber }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AddressIcon",
  props: {
    type: String,
    index: Number
  },
  computed: {
    ...mapGetters("homesStore", ["suggestedComps", "discardedComps"]),
    orderNumber() {
      switch (this.type) {
        case "Selected":
          return "";
        case "Suggested":
          return this.index;
        case "Discarded":
          return this.suggestedComps.length + this.index;
        case "Pre-Discarded":
          return (
            this.suggestedComps.length + this.discardedComps.length + this.index
          );
        default:
          return this.index;
      }
    },
    addressIcon() {
      let addressIconImg = "/img/images/marker.svg";
      switch (this.type) {
        case "Selected":
          addressIconImg = "/img/images/marker.svg";
          break;
        case "Suggested":
          addressIconImg = "/img/images/home_green.svg";
          break;
        case "Discarded":
          addressIconImg = "/img/images/home_red.svg";
          break;
        case "Pre-Discarded":
          addressIconImg = "/img/images/home_gray.svg";
          break;
        default:
          break;
      }
      return addressIconImg;
    }
  },
  mounted() {
    //
  }
};
</script>

<style lang="scss" scoped>
.address-icon-container {
  position: relative;

  .address-icon {
    height: 30px;
  }

  .order-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .order {
      color: #fff;
      font-size: 12px;
      font-weight: bold;
    }
  }
}
</style>
