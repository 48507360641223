<template>
  <div class="search-container">
    <div class="autocomplete-container">
      <gmap-autocomplete
        :disabled="disabled"
        class="gmap-autocomplete"
        @place_changed="setPlace"
      ></gmap-autocomplete>
    </div>
    <div :style="style" class="search-btn" @click="onSearchClick">
      <v-icon color="white">mdi-magnify</v-icon>
      <span v-if="displayButtonText">Search</span>
    </div>
    <Loader :loading="loading" />
    <SearchErrorDialog :errorMessage="errorMessage" v-model="showErrorDialog" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loader from "@/components/Shared/Loader";
import SearchErrorDialog from "@/components/SearchErrorDialog";

export default {
  name: "HomeSearch",
  components: {
    Loader,
    SearchErrorDialog
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    displayButtonText: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    address: "",
    formattedAddress: "",
    loading: false,
    showErrorDialog: false,
    errorMessage: ""
  }),
  computed: {
    style() {
      return {
        height: `${this.height}px`
      };
    }
  },
  methods: {
    ...mapActions("viewsStore", ["setSnack"]),
    ...mapActions("homesStore", ["getArv"]),
    setPlace(place) {
      const { address_components, geometry, formatted_address } = place;
      const { location } = geometry;

      const streetAddress = formatted_address.split(",")[0].trim();
      const city = formatted_address.split(",")[1].trim();
      const state = formatted_address
        .split(",")[2]
        .trim()
        .split(" ")[0]
        .trim();
      const zip1 = formatted_address
        .split(",")[2]
        .trim()
        .split(" ")[1]
        .trim();

      const county = address_components.find(component => {
        return (
          component.long_name.includes("County") &&
          component.types.includes("administrative_area_level_2")
        );
      });

      const zip2 = address_components.find(component => {
        return component.types.includes("postal_code_suffix");
      });

      this.address = {
        address: streetAddress,
        city: city,
        county: county?.long_name || "",
        state: state,
        latitude: location.lat(),
        longitude: location.lng(),
        zip1: zip1,
        zip2: zip2?.long_name || ""
      };

      this.formattedAddress = formatted_address;

      this.onSearchClick();
    },
    async onSearchClick() {
      if (this.disabled) {
        this.setSnack("Down for maintenance, please try agian later!");
        return;
      }

      if (!this.address) {
        this.setSnack("You should select a location to search properties!");
        return;
      }

      try {
        this.loading = true;

        await this.getArv({
          address: this.address,
          formattedAddress: this.formattedAddress
        });

        if (this.$route.path !== "/properties") {
          this.$router.push("/properties");
        }
      } catch (e) {
        this.showErrorDialog = true;
        this.errorMessage = e;
        console.error("Calculate ARV Error:", e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pac-target-input {
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
}

.search-container {
  display: flex;
  padding: 2px;
  border-radius: 35px;
  background-image: linear-gradient(275deg, #03b6da, #6348c6);

  .search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    border-radius: inherit;
    color: #fff;
    font-family: "Helvetica Neue Medium";
    font-size: 20px;
    cursor: pointer;
  }

  .autocomplete-container {
    width: 100%;
    padding-left: 25px;
    background-color: white;
    border-radius: 35px 0 0 35px;

    .gmap-autocomplete {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
