<template>
  <div class="compare-table-container">
    <h2 class="text-left table-title pl-4">{{ title }}</h2>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="filteredHeaders"
      :items="calculatedProperties"
      :items-per-page="-1"
      hide-default-footer
      class="compare-table elevation-0"
    >
      <template v-slot:[`item.address`]="{ item }">
        <div v-if="type !== 'Selected'">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                :id="item.id === 0 ? 'v-step-2-1' : ''"
                v-on="on"
                class="address d-flex align-center justify-start"
                @click="onAddressClick(item)"
              >
                <address-icon :type="type" :index="getIndex(item)" />
                <span class="ml-2" :class="addressColumnClass">{{
                  getStreetAddress(item.address)
                }}</span>
              </div>
            </template>
            <span v-if="isAuthenticated && isProUser">Quick Compare</span>
            <div v-else class="text-center">
              Please sign-up for a FREE Pro Edition user account
              <br />
              In order to access the Quick-Compare feature
            </div>
          </v-tooltip>
        </div>
        <div v-else>
          <div
            :id="item.id === 0 ? 'v-step-2-1' : ''"
            class="address d-flex align-center justify-start"
            @click="onAddressClick(item)"
          >
            <address-icon :type="type" :index="getIndex(item)" />
            <span class="ml-2" :class="addressColumnClass">{{
              getStreetAddress(item.address)
            }}</span>
          </div>
        </div>
      </template>
      <template
        v-if="isSuggested && isAuthenticated && isProUser"
        v-slot:[`item.baths`]="{ item }"
      >
        <v-select
          :items="bathsItems"
          v-model="item.baths"
          @change="onBathChange(item)"
        />
      </template>
      <template
        v-if="isSuggested && isAuthenticated && isProUser"
        v-slot:[`item.sqFt`]="{ item }"
      >
        <v-text-field
          v-model="item.sqFt"
          @input="onSubjectSqftChanged(item.sqFt)"
        />
      </template>
      <template
        v-if="!isSelected"
        v-slot:[`item.compsCostPerSqftActual`]="{ item }"
      >
        {{ getFlooredValue(item.compsCostPerSqftActual) }}
      </template>
      <template v-slot:[`item.buildMatch`]="{ item }">
        <span class="build-match-column">{{ `${item.buildMatch}` }}</span>
      </template>
      <template v-slot:[`item.arv`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on" class="est-arv-column">
              {{ `$${getFormatedEstARV(item.arv)}` }}
            </span>
          </template>
          <span>
            {{
              `$${getFormatedEstARV(popupEstArv.min) ||
                0} - $${getFormatedEstARV(popupEstArv.max) || 0}`
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.compare`]="{ item }">
        <div class="d-flex align-center justify-between" @click="onActionClick">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-checkbox
                  id="v-step-2"
                  v-if="!isDiscarded && !isPreDiscarded"
                  v-model="item.compare"
                  hide-details
                  @change="onCheckRow(item)"
                  :disabled="
                    (!item.compare && compareComps.length >= 3) ||
                      !isAuthenticated
                  "
                />
              </span>
            </template>
            <span v-if="isAuthenticated && isProUser">Compare</span>
            <div v-else class="text-center">
              Please sign-up for a FREE Pro Edition user account
              <br />
              In order to select properties for side-by-side comparison
            </div>
          </v-tooltip>

          <v-tooltip v-if="isSuggested" left>
            <template v-slot:activator="{ on }">
              <img
                id="v-step-4"
                :src="deleteIcon"
                v-on="on"
                class="delete-icon"
                @click="onDeleteRow(item)"
              />
            </template>
            <span v-if="isAuthenticated && isProUser">Discard Comp</span>
            <div v-else class="text-center">
              Please sign-up for a FREE Pro Edition user account
              <br />
              In order to access the Discard feature
            </div>
          </v-tooltip>
          <v-tooltip v-else left>
            <template v-slot:activator="{ on }">
              <img
                id="v-step-6"
                :disabled="isPreDiscarded"
                v-on="on"
                :src="undoIcon"
                class="undo-icon"
                @click="onReturnRow(item)"
              />
            </template>
            <span v-if="isAuthenticated && isProUser">Undo Discard</span>
            <div v-else class="text-center">
              Please sign-up for a FREE Pro Edition user account
              <br />
              In order to access the Undo Discard feature
            </div>
          </v-tooltip>
        </div>
        <v-menu
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-card class="upgrade-premium-card">
            <h2 class="upgrade-premium-card--title">
              You discovered a premium feature!
            </h2>
            <p class="upgrade-premium-card--description">
              Upgrade your account to add pre-discarded comps to your compare
              list.
            </p>
            <v-btn depressed class="upgrade-premium-card--btn">
              Upgrade Now
            </v-btn>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import _ from "lodash";
import numbersMixin from "@/mixins/numbersMixin";
import { calculateARV, computeNewARV } from "@/utils/arv";
import AddressIcon from "@/components/AddressIcon";
import { mapGetters } from "vuex";

export default {
  name: "PropertiesTable",
  components: {
    AddressIcon
  },
  mixins: [numbersMixin],
  props: {
    type: String,
    title: String,
    headers: Array,
    items: Array,
    popupEstArv: Object,
    hideArv: Boolean
  },
  data: () => ({
    x: 0,
    y: 0,
    showMenu: false,
    calculatedProperties: []
  }),
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isProUser"]),
    ...mapGetters("homesStore", [
      "selectedProperty",
      "comps",
      "compareComps",
      "mlsConstants",
      "priceAdjust"
    ]),
    filteredHeaders() {
      if (this.hideArv) {
        return this.headers.filter(h => h.value !== "arv");
      }
      return this.headers;
    },
    deleteIcon() {
      return "/img/images/delete.svg";
    },
    undoIcon() {
      return "/img/images/undo_discarded.svg";
    },
    addressColumnClass() {
      return `address-column address-column--${this.extraClasses}`;
    },
    extraClasses() {
      return this.type !== "Selected" ? "compare" : "selected";
    },
    isSelected() {
      return this.type === "Selected";
    },
    isSuggested() {
      return !(this.isDiscarded || this.isPreDiscarded);
    },
    isDiscarded() {
      return this.type === "Discarded";
    },
    isPreDiscarded() {
      return this.type === "Pre-Discarded";
    },
    bedsItems() {
      return [1, 2, 3, 4, 5];
    },
    bathsItems() {
      return [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];
    },
    estARVValues() {
      return {};
    }
  },
  watch: {
    items: {
      handler() {
        this.calculatedProperties = _.cloneDeep(this.items);
      }
    }
  },
  mounted() {
    if (this.isSelected) {
      this.updateSelectedPropertyARV();
    }
    this.calculatedProperties = _.cloneDeep(this.items);
  },
  methods: {
    getIndex(item) {
      return _.findIndex(this.items, item) + 1;
    },
    getFlooredValue(value) {
      return `${Math.floor(value)}`;
    },
    getFormatedEstARV(value) {
      return this.$_numbersMixin_getNumberWithCommas(value);
    },
    getStreetAddress(address) {
      return (address && address.split(",")[0]) || "";
    },
    onCheckRow(item) {
      this.$emit("check-row", item);
    },
    updateSelectedPropertyARV(item) {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      if (item) {
        selectedProperty.baths = item.baths;
      }
      selectedProperty.arv = calculateARV(
        this.comps,
        selectedProperty,
        this.mlsConstants,
        this.priceAdjust
      );
      this.$store.dispatch("homesStore/setSelectedProperty", selectedProperty);
    },
    onBathChange(item) {
      if (this.isSelected) {
        this.updateSelectedPropertyARV(item);
        const compareProperties = _.cloneDeep(this.comps);
        compareProperties.map(comp => {
          comp.arv = computeNewARV(
            comp,
            this.selectedProperty,
            this.mlsConstants,
            this.priceAdjust
          );
        });
        this.$store.dispatch("homesStore/setComps", compareProperties);
      } else {
        const compareProperties = _.cloneDeep(this.comps);
        const index = _.findIndex(
          compareProperties,
          cItem => cItem.id === item.id
        );
        if (index >= 0) {
          compareProperties[index].arv = computeNewARV(
            item,
            this.selectedProperty,
            this.mlsConstants,
            this.priceAdjust
          );
          compareProperties[index].baths = item.baths;
          this.$store.dispatch("homesStore/setComps", compareProperties);
          this.updateSelectedPropertyARV();
        }
      }
    },
    onSubjectSqftChanged: _.debounce(function(e) {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.livingArea = isNaN(parseInt(e)) ? 0 : parseInt(e);
      selectedProperty.arv = calculateARV(
        this.comps,
        selectedProperty,
        this.mlsConstants,
        this.priceAdjust
      );
      const compareProperties = _.cloneDeep(this.comps);
      compareProperties.map(comp => {
        comp.arv = computeNewARV(
          comp,
          selectedProperty,
          this.mlsConstants,
          this.priceAdjust
        );
      });
      this.$store.dispatch("homesStore/setSelectedProperty", selectedProperty);
      this.$store.dispatch("homesStore/setComps", compareProperties);
    }, 500),
    async onAddressClick(item) {
      if (!this.isSelected && this.isAuthenticated && this.isProUser) {
        try {
          await this.$store.dispatch(
            "homesStore/setQuickCompareProperty",
            item
          );
          this.$router.push("/quick-compare");
        } catch (e) {
          console.error("-- Set Quick Compare Property Error --", e);
        }
      }
    },
    onActionClick(e) {
      // if (this.isPreDiscarded) {
      //   e.preventDefault();
      //   this.showMenu = false;
      //   this.x = e.clientX;
      //   this.y = e.clientY;
      //   this.$nextTick(() => {
      //     this.showMenu = true;
      //   });
      // }
    },
    onDeleteRow(item) {
      if (!this.isAuthenticated || !this.isProUser) return;
      this.$emit("delete-row", item);
    },
    onReturnRow(item) {
      if (!this.isAuthenticated || !this.isProUser) return;
      this.$emit("return-row", item);
    }
  }
};
</script>

<style lang="scss" scoped>
.compare-table-container {
  width: 100%;
  font-family: "Helvetica Neue Light";
  font-size: 14px;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #5c5e62;

  .table-title {
    font-family: "Helvetica Neue Bold";
    font-size: 16px;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #9b9b9b;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .address {
    cursor: pointer;
  }

  .address-column {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;

    &--selected {
      color: #5c5e62;
    }

    &--compare {
      color: #04b6da;
    }
  }

  .est-arv-column {
    font-family: "Helvetica Neue Bold";
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #04b6da;
  }

  .build-match-column {
    font-family: "Helvetica Neue Bold";
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #5c5e62;
  }

  .delete-icon {
    width: 18px;
  }

  .undo-icon {
    width: 18px;
  }
}

::v-deep {
  .v-data-table {
    th {
      font-family: "Helvetica Neue Bold";
      font-size: 16px;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #5c5e62;
    }
  }

  .v-select__selections {
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .v-input__slot {
    font-family: "Helvetica Neue Light";
    font-size: 14px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #5c5e62;
    margin-bottom: 0 !important;
  }

  .v-input--checkbox {
    padding-top: 0;
    margin: 0;

    .v-messages {
      display: none;
    }
  }

  .v-menu__content {
    box-shadow: none !important;
  }

  .upgrade-premium-card {
    width: 389px;
    height: 187px;
    background: linear-gradient(296deg, #03b6da, #6348c6);
    padding: 30px 27px !important;

    &--title {
      font-family: "Helvetica Neue Bold";
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #5c5e62;
    }

    &--description {
      font-family: "Helvetica Neue Medium";
      font-size: 14px;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: #5c5e62;
      margin-top: 7px;
    }

    &--btn {
      width: 120px;
      height: 34px;
      background-color: #04b6da !important;
      border-radius: 0;
      font-family: "Helvetica Neue Medium";
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
      color: #ffffff;
    }
  }
}
</style>
