const state = () => ({
  address: null,
  condition: null,
  roofReplaced: null,
  bathroomUpdated: null,
  kitchenUpdated: null,
  stoneCounters: null,
  woodCabinets: null,
  stainlessSteel: null,
  sinkhole: null
});

const mutations = {
  SET_ADDRESS(state, address) {
    state.address = address;
  },
  SET_CONDITION(state, condition) {
    state.condition = condition;
  },
  SET_ROOF_REPLACED(state, condition) {
    state.roofReplaced = condition;
  },
  SET_BATHROOM_UPDATED(state, condition) {
    state.bathroomUpdated = condition;
  },
  SET_KITCHEN_UPDATED(state, condition) {
    state.kitchenUpdated = condition;
  },
  SET_STONE_COUNTERS(state, condition) {
    state.stoneCounters = condition;
  },
  SET_WOOD_CABINETS(state, condition) {
    state.woodCabinets = condition;
  },
  SET_STAINLESS_STEEL(state, condition) {
    state.stainlessSteel = condition;
  },
  SET_SINKHOLE(state, condition) {
    state.sinkhole = condition;
  }
};

const actions = {};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
