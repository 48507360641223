<template>
  <div class="map-compare-popup-get-started-container d-flex">
    <div class="popup-left d-flex flex-column align-center justify-center">
      <map-compare-popup-compare-item
        selected
        :propertyData="selectedPropertyData"
      />
    </div>
    <div class="popup-right d-flex flex-column align-center justify-center">
      <h2 class="popup-title">Let's get started comparing properties!</h2>
      <p class="popup-description">
        Add properties from the table below by selecting the 'Compare' check
        boxes on the right hand side of the page.
      </p>
      <p class="popup-description">You can select up to 3 comps to compare.</p>
      <v-btn depressed class="got-it-btn" @click="onGotItClick"
        >Ok, Got it!</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MapComparePopupCompareItem from "@/components/MapComparePopupCompareItem";

export default {
  name: "MapComparePopupGetStarted",
  components: {
    MapComparePopupCompareItem
  },
  props: {
    //
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("homesStore", [
      "selectedProperty",
      "selectedPropertyImage",
      "compareComps"
    ]),
    selectedImageUrl() {
      return this.selectedPropertyImage.src;
    },
    streetAddress() {
      return this.selectedProperty.address;
    },
    beds() {
      return this.selectedProperty.beds;
    },
    baths() {
      return this.selectedProperty.baths;
    },
    sqFt() {
      return this.selectedProperty.sqFt;
    },
    selectedPropertyData() {
      return {
        ...this.selectedProperty,
        image: this.selectedImageUrl
      };
    }
  },
  mounted() {
    //
  },
  methods: {
    onGotItClick() {
      if (this.compareComps.length <= 3) {
        this.$store
          .dispatch("homesStore/setSelectedProperty", this.selectedPropertyData)
          .then(() => {
            //
          })
          .catch(err => {
            console.error("-- Set Selected Property Error --", err);
          });
        this.$store
          .dispatch("viewsStore/setMapPopupViewMode", 2)
          .then(() => {
            //
          })
          .catch(err => {
            console.error("-- Set Map Popup View Mode Error --", err);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.map-compare-popup-get-started-container {
  width: 100%;
  background: #5c5e62;

  .popup-left {
    width: 180px;
  }

  .selected-info {
    width: 100%;
    padding: 0 20px;
  }

  .selected-img {
    width: 140px;
    height: 140px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: solid 2px #74cfe7;
  }

  .selected-property-info {
    font-family: "Helvetica Neue Medium";
    font-size: 12px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
  }

  .popup-right {
    flex: 1;
  }

  .popup-title {
    font-family: "Helvetica Neue Medium";
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
  }

  .popup-description {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 14px;
  }

  .got-it-btn {
    width: 115px;
    height: 34px;
    background: #04b6da !important;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: none;
  }
}
</style>
