<template>
  <div class="how-it-works-container d-flex align-center justify-center">
    <h2>How It Works Page Coming Soon ...</h2>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  props: {
    //
  },
  data: () => ({
    //
  }),
  computed: {
    //
  },
  methods: {
    //
  }
};
</script>

<style lang="scss" scoped>
.how-it-works-container {
  width: 100%;
  height: 100%;
}
</style>
