<template>
  <v-container>
    <v-row justify="center">
      <v-col>
        <div>
          <v-card>
            <v-card-title>
              Rehab Calculator
            </v-card-title>
            <v-card-text>
              <keep-alive>
                <component
                  :is="currentQuestion.component"
                  @address-changed="getAddress"
                  @condition-changed="getCondition"
                  @answer="getAnswer"
                >
                  <template #question>
                    {{ currentQuestion.question }}
                  </template>
                </component>
              </keep-alive>
            </v-card-text>

            <v-card-actions>
              <v-btn @click="back">{{ prevBtnText }}</v-btn>

              <v-spacer></v-spacer>

              <v-btn @click="next" :disabled="disabled">{{
                nextBtnText
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RehabCalculatorAddress from "@/components/RehabCalculator/RehabCalculatorAddress";
import RehabCalculatorConditionStep from "@/components/RehabCalculator/RehabCalculatorConditionStep";
import RehabCalculatorEstimateStep from "@/components/RehabCalculator/RehabCalculatorEstimateStep";
import RehabCalculatorYesOrNoQuestion from "@/components/RehabCalculator/RehabCalculatorYesOrNoQuestion";

export default {
  name: "RehabCalculator",
  components: {
    RehabCalculatorAddress,
    RehabCalculatorConditionStep,
    RehabCalculatorEstimateStep,
    RehabCalculatorYesOrNoQuestion
  },
  props: {
    //
  },
  data: () => ({
    currentPosition: 0,
    address: null,
    condition: null,
    questions: [
      {
        name: "address",
        component: "RehabCalculatorAddress"
      },
      {
        name: "condition",
        component: "RehabCalculatorConditionStep"
      }
    ],
    initQuestions: [
      {
        name: "address",
        component: "RehabCalculatorAddress"
      },
      {
        name: "condition",
        component: "RehabCalculatorConditionStep"
      }
    ],
    excellentQuestions: [
      {
        name: "estimate",
        component: "RehabCalculatorEstimateStep"
      }
    ],
    goodQuestions: [
      {
        name: "roof",
        question:
          "Has your roof been replaced and/or completely reshingles in the last 10 years?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "bath",
        question: "Have you updated any of your bathrooms in the last 5 years?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "kitchen",
        question:
          "Has your roof been replaced and/or completely reshingles in the last 10 years?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "granite",
        question:
          "Are your counter tops made of granite or another polished stone?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "cabinet",
        question: "Are your cabinets made of wood adn/or plywood?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "steel",
        question: "Are your kitchen appliances stainless steel?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "sinkhole",
        question:
          "Does your house have any present signs of settlement or sinkhole activity?",
        component: "RehabCalculatorYesOrNoQuestion"
      },
      {
        name: "estimate",
        component: "RehabCalculatorEstimateStep"
      }
    ],
    fairQuestions: [],
    badQuestions: []
  }),
  computed: {
    currentQuestion() {
      return this.questions[this.currentPosition];
    },
    disabled() {
      switch (this.currentQuestion.name) {
        case "address":
          return !this.address;
          break;
        case "condition":
          return !this.condition;
          break;
      }
      return false;
    },
    nextBtnText() {
      if (this.currentPosition === 0) {
        return "Get Started";
      }

      if (
        (this.currentPosition > 0 &&
          this.currentPosition < this.questions.length - 1) ||
        this.currentPosition === 1
      ) {
        return "Next";
      }

      return "Done";
    },
    prevBtnText() {
      if (this.currentPosition === 0) {
        return "Cancel";
      }

      return "Back";
    }
  },
  methods: {
    getAnswer(answer) {
      console.log(this.currentQuestion.name, answer);
    },
    back() {
      if (this.currentPosition > 0) {
        this.currentPosition -= 1;
      }
    },
    next() {
      if (this.currentPosition < this.questions.length - 1) {
        this.currentPosition += 1;
      }
    },
    getCondition(condition) {
      this.condition = condition;
      switch (condition) {
        case "excellent":
          this.questions = [...this.initQuestions, ...this.excellentQuestions];
          break;
        case "good":
          this.questions = [...this.initQuestions, ...this.goodQuestions];
          break;
        case "fair":
          this.questions = [...this.initQuestions, ...this.fairQuestions];
          break;
        case "bad":
          this.questions = [...this.initQuestions, ...this.badQuestions];
          break;
      }
    },
    getAddress(address) {
      this.address = address;
    }
  }
};
</script>

<style lang="scss" scoped></style>
