// Pages
import Home from "@/pages/Home";
import Compare from "@/pages/Compare";
import QuickCompare from "@/pages/QuickCompare";
import Properties from "@/pages/Properties";
import RehabCalculator from "@/pages/RehabCalculator";
import HowItWorks from "@/pages/HowItWorks";
import FAQ from "@/pages/FAQ";
import TermsOfService from "@/pages/TermsOfService";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import SimpleSearch from "@/pages/SimpleSearch";
import ProLanding from "@/pages/ProLanding";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/pro",
    name: "ProLanding",
    component: ProLanding
  },
  {
    path: "/qr/:id",
    name: "Simple",
    component: SimpleSearch
  },
  {
    path: "/email/:token/qr",
    name: "QrToken",
    component: SimpleSearch
  },
  {
    path: "/email/:token",
    name: "VerifyEmail",
    component: Home
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/pages/SignUp")
  },
  {
    path: "/password",
    name: "PasswordReset",
    component: () => import("@/pages/PasswordReset")
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/quick-compare",
    name: "QuickCompare",
    component: QuickCompare,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/properties",
    name: "Properties",
    component: Properties,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/rehab-calculator",
    name: "RehabCalculator",
    component: RehabCalculator
  },
  {
    path: "/how-it-works",
    name: "HowItWorks",
    component: HowItWorks
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ
  },
  {
    path: "/tos",
    name: "TermsOfService",
    component: TermsOfService
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy
  },
  {
    path: "*",
    redirect: "/"
  }
];

export default routes;
