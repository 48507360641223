<template>
  <v-card v-if="!accountCreated">
    <v-card-title
      class="headline white--text"
      style="background-color: #6348c6"
    >
      Free Seller Account Sign Up
    </v-card-title>
    <v-form v-model="valid" ref="freeForm" autocomplete="off">
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="firstname"
              :rules="nameRules"
              label="First name"
              name="firstname"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="lastname"
              :rules="nameRules"
              label="Last name"
              name="lastname"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              name="email"
              :error-messages="emailError"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="street"
              :rules="streetRules"
              label="Street address"
              name="street"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="city"
              :rules="cityRules"
              label="City"
              name="city"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="state"
              :rules="stateRules"
              label="State"
              name="state"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="zip"
              :rules="zipRules"
              label="Postal Code"
              name="zip"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="isOwner"
              label="Are You the Sole Owner of the Above Property?"
            ></v-checkbox>
          </v-col>

          <v-col cols="12">
            <p>
              If "No" Please List the Full Names of the All Owner(s) Listed on
              the Deed
            </p>
            <v-textarea outlined name="owners" v-model="owners"></v-textarea>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              :rules="passwordRules"
              label="Password"
              name="password"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="password2"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              :rules="[...passwordRules, passwordMatch]"
              label="Confirm Password"
              name="password2"
              :error-messages="passwordError"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-checkbox v-model="tos" :rules="tosRules">
              <template #label>
                <div class="caption">
                  By checking the box you acknowledge that you have read and
                  agree to the
                  <a @click.stop href="terms" target="_blank"
                    >Terms of Service</a
                  >
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-actions>
      <v-btn text @click="$emit('close')">cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="validate">Create Account</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-title class="headline blue white--text">
      Confirm your email
    </v-card-title>

    <v-card-text class="mt-4">
      <h1 class="mb-4">Verify your email to continue</h1>
      <h3>Please check your email for a verification link</h3>
      <h3>You will be able to login after your email has been verified.</h3>
      <br />
      <h3>
        Once you have confirmed your email you can sign in with your email as
        your username
      </h3>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" tile depressed @click="close">
        close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    error: {},
    accountCreated: false,
    valid: true,
    firstname: "",
    lastname: "",
    nameRules: [v => !!v || "Name is required"],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    street: "",
    city: "",
    zip: "",
    state: "",
    isOwner: false,
    owners: "",
    tos: false,
    tosRules: [v => !!v || "You must accept the tos"],
    show: false,
    password: "",
    password2: "",
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length >= 8 || "Password must be greater than 7 characters"
    ]
  }),
  computed: {
    streetRules() {
      return [v => !!v || "Address is required"];
    },
    cityRules() {
      return [v => !!v || "City is required"];
    },
    zipRules() {
      return [v => !!v || "Zip is required"];
    },
    stateRules() {
      return [v => !!v || "State is required"];
    },
    passwordMatch() {
      return this.password === this.password2 || "Password must match";
    },
    passwordError() {
      return this.error.password || "";
    },
    usernameError() {
      return this.error.username || "";
    },
    emailError() {
      return this.error.email || "";
    },
    addressError() {
      return this.error.address || [];
    }
  },
  methods: {
    ...mapActions("auth", ["register"]),
    setPlace(place) {
      this.address = place;
    },
    async validate() {
      this.error = {};
      if (!this.$refs.freeForm.validate()) return;

      try {
        const data = {
          username: this.email,
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          password: this.password,
          password2: this.password2,
          street: this.street,
          city: this.city,
          zip: this.zip,
          state: this.state,
          is_pro: false,
          is_seller: true,
          is_owner: this.isOwner,
          owners: this.owners
        };

        await this.register(data);

        this.accountCreated = true;
      } catch (error) {
        console.error("Error creating free account:", error.response.data);
        this.error = error.response.data;
      }
    },
    close() {
      this.$emit("close");
      setTimeout(() => {
        this.accountCreated = false;
      }, 1000);
    }
  }
};
</script>

<style></style>
