<template>
  <v-app id="v-app">
    <NavBar @toggleDrawer="drawer = !drawer" />
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.mobile"
      disable-resize-watcher
      temporary
      v-model="drawer"
      app
    >
      <Navdrawer @close="drawer = false" />
    </v-navigation-drawer>
    <v-main id="main">
      <slot />
    </v-main>

    <Snackbar />
    <TosDialog v-if="$route.name !== 'TermsOfService'" />
    <v-footer app class="d-flex justify-center">
      <router-link :to="{ name: 'TermsOfService' }"
        >Terms of Service</router-link
      >
      <span class="mx-2">|</span>
      <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Snackbar from "@/components/Shared/Snackbar";
import TosDialog from "@/components/TosDialog";
import Navdrawer from "@/components/Navdrawer";

export default {
  name: "MainLayout",
  components: {
    NavBar,
    Snackbar,
    TosDialog,
    Navdrawer
  },
  data() {
    return {
      drawer: false
    };
  }
};
</script>

<style lang="scss">
#v-app {
  height: 100%;
}

#main {
  height: 100%;
}
</style>
