<template>
  <div class="compare-table-container">
    <h2 class="table-title text-left">{{ tableName }}</h2>
    <table style="width:100%">
      <tr v-for="(item, index) in tableData" :key="index">
        <th>{{ item.title }}</th>
        <td
          v-for="(lItem, lIndex) in item.list"
          :key="`${item.title}-${lIndex}`"
          :style="{ color: item.cellColor }"
        >
          {{
            getCellValue(
              item,
              lItem,
              item.unit,
              item.prefix ? "prefix" : "suffix"
            )
          }}
        </td>
        <td
          v-for="(e, eIndex) in getEmptyColumnNumber(item.list)"
          :key="`${item.title}-empty-${eIndex}`"
        />
      </tr>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "CompareTable",
  props: {
    tableName: String,
    tableData: Array,
    tableColumnNumber: {
      type: Number,
      default: 4
    }
  },
  data: () => ({
    //
  }),
  mounted() {
    //
  },
  computed: {
    //
  },
  methods: {
    getEmptyColumnNumber(list) {
      return this.tableColumnNumber - list.length;
    },
    getCellValue(item, value, unit, mode) {
      // if (value) {
      //   console.log(item.type, item.value, value);
      //   if (mode === "prefix") {
      //     return `${unit}${value}`;
      //   } else {
      //     return `${value} ${unit}`;
      //   }
      // } else {
      let emptyFieldValue = "";
      switch (item.title) {
        case "% Diff. Living Area":
          emptyFieldValue = "N/A";
          break;
        case "% Diff. Lot Size":
          emptyFieldValue = "N/A";
          break;
        case "Distance from selected":
          emptyFieldValue = "N/A";
          break;
        case "Build Match %":
          emptyFieldValue = "N/A";
          break;
        default:
          emptyFieldValue = value;
          break;
      }

      switch (item.type) {
        case "waterFront":
        case "pool":
          emptyFieldValue = value ? "Yes" : "No";
          break;
        case "parkingType":
          emptyFieldValue = value || "N/A";
          break;
        default:
          emptyFieldValue = value;
          break;
      }

      return emptyFieldValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.compare-table-container {
  table {
    border-top: solid 2px #5c5e62;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
  }

  tr {
    display: flex;
    flex: 1;
  }

  th,
  td {
    flex: 1;
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  td {
    border-left: solid 1px #cecece;

    &:nth-child(2) {
      background: #f5fdff;
    }
  }

  .table-title {
    color: #5c5e62;
    margin-bottom: 10px;
  }
}
</style>
