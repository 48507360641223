<template>
  <div class="home-address-info-container d-flex">
    <div class="icon-section">
      <img :src="addressIcon" class="address-icon" />
    </div>
    <div class="info-section d-flex flex-column">
      <span class="street-address">{{ address }}</span>
      <span class="city-info">{{ cityInfo }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeAddressInfo",
  props: {
    selected: Boolean,
    addressInfo: Object
  },
  computed: {
    addressIcon() {
      return this.selected
        ? "/img/images/marker.svg"
        : "/img/images/home_green.svg";
    },
    address() {
      return this.addressInfo.address.split(",")[0];
    },
    cityInfo() {
      const addressInfoArray = this.addressInfo.address.split(",");
      console.log(addressInfoArray);
      const city = addressInfoArray[1];
      const state = addressInfoArray[2].trim().split(" ")[0];
      const zipCode = addressInfoArray[2]
        .trim()
        .split(" ")[1]
        .split("-")[0];
      const { subdivision } = this.addressInfo;
      const cityAddress = `${city}, ${state} ${zipCode}`;
      return `${cityAddress} | ${subdivision}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-address-info-container {
  .address-icon {
    width: 24px;
  }

  .street-address {
    font-family: "Helvetica Neue Bold";
    font-size: 20px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .city-info {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #5c5e62;
  }
}
</style>
