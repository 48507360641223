<template>
  <v-app-bar app flat height="80" class="nav-bar-container">
    <v-app-bar-nav-icon
      dark
      v-if="$vuetify.breakpoint.mobile"
      @click="$emit('toggleDrawer')"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>
      <logo dark />
    </v-toolbar-title>

    <v-spacer />

    <div v-if="!$vuetify.breakpoint.mobile">
      <v-btn
        v-if="showHomeButton"
        dark
        text
        exact
        :to="{ name: 'Home' }"
        class="menu-item"
      >
        <span class="menu-btn-text">Home</span>
      </v-btn>

      <v-btn
        v-if="!isPropertiesPage"
        dark
        text
        class="menu-item"
        @click="$vuetify.goTo('#how-it-works')"
      >
        <span class="menu-btn-text">How it works</span>
      </v-btn>

      <v-btn
        v-if="!isPropertiesPage"
        dark
        text
        class="menu-item"
        @click="$vuetify.goTo('#about')"
      >
        <span class="menu-btn-text">About Us</span>
      </v-btn>

      <v-btn
        v-if="!isPropertiesPage"
        dark
        text
        class="menu-item"
        href="https://blog.ehomefacts.com/"
        target="_blank"
      >
        <span class="menu-btn-text">Blog</span>
      </v-btn>

      <v-btn
        v-if="!isPropertiesPage"
        dark
        text
        class="menu-item"
        @click="$vuetify.goTo('#contact')"
      >
        <span class="menu-btn-text">Contact Us</span>
      </v-btn>
      <v-btn
        v-if="isAuthenticated && isPropertiesPage && !isSeller"
        dark
        text
        class="menu-item"
        @click="rehabCalculatorOpen = true"
      >
        <span class="menu-btn-text">Rehab Calculator</span>
      </v-btn>
      <v-btn
        v-if="
          isAuthenticated && isPropertiesPage && isSeller && !cashOfferSubmitted
        "
        dark
        text
        class="menu-item"
        @click="setCashOfferState(true)"
      >
        <span class="menu-btn-text">Cash Offer</span>
      </v-btn>
      <v-dialog
        v-model="proDialog"
        width="500"
        v-if="!isAuthenticated && !hideNavButtons"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="action-btn" dark text>
            <span class="menu-btn-text">Sign Up</span>
          </v-btn>
        </template>
      </v-dialog>
      <v-btn
        v-if="!isAuthenticated && !hideNavButtons"
        dark
        depressed
        class="action-btn signin-btn"
        @click="signInOpen = true"
      >
        <span class="action-btn-text">Sign In</span>
      </v-btn>
      <v-menu v-if="isAuthenticated" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark v-bind="attrs" v-on="on" class="user-menu-btn">
            <span class="username">{{ userName }}</span>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="startTour" v-if="isPropertiesPage">
            <v-list-item-title>Launch Tutorial</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'TermsOfService' }">
            <v-list-item-title> Terms of Service </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div v-if="!$vuetify.breakpoint.mobile">
        <SignUpReminder v-model="proDialog" />
        <SignInModal v-model="signInOpen" />
        <RehabCalculatorModal
          v-model="rehabCalculatorOpen"
          :address="selectedAddress.address"
        />
        <CashOfferModal
          v-model="showCashOffer"
          :address="selectedAddress.address"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import Logo from "@/components/Shared/Logo";
import SignInModal from "@/components/SignInModal";
import RehabCalculatorModal from "@/components/RehabCalculator/RehabCalculatorModal";
import CashOfferModal from "@/components/CashOffer/CashOfferModal";
import SignUpReminder from "@/components/SignUpReminder";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavBar",
  props: {
    scrolled: Boolean
  },
  components: {
    Logo,
    SignInModal,
    RehabCalculatorModal,
    CashOfferModal,
    SignUpReminder
  },
  data: () => ({
    actionButtons: [
      {
        title: "Sign In",
        url: "/auth/signin"
      }
    ],
    userMenuItems: [
      {
        title: "Account Settings"
      },
      {
        title: "Log Out"
      }
    ],
    signupDialog: false,
    signInOpen: false,
    signUpOpen: false,
    rehabCalculatorOpen: false,
    proDialog: false
  }),
  computed: {
    ...mapGetters("auth", [
      "userName",
      "isAuthenticated",
      "isSeller",
      "cashOfferSubmitted"
    ]),
    ...mapGetters("homesStore", ["selectedAddress"]),
    ...mapGetters("cashoffer", ["showCashOffer"]),
    isPropertiesPage() {
      return this.$route.path === "/properties";
    },
    showHomeButton() {
      switch (this.$route.name) {
        case "Home":
        case "Simple":
        case "ProLanding":
          return false;
        default:
          return true;
      }
    },
    hideNavButtons() {
      return this.$route.name === "Simple";
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["setTutorial"]),
    ...mapActions("cashoffer", ["setCashOfferState"]),
    startTour() {
      this.setTutorial(true);
    }
  },
  watch: {
    isSeller(newValue) {
      if (!newValue && this.isPropertiesPage) {
        this.rehabCalculatorOpen = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
  background-image: linear-gradient(273deg, #6348c6, #6348c6);
}

.username {
  text-transform: capitalize;
}

.menu-item {
  margin: 0 10px;
}

.menu-btn-text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  color: #ffffff;
  text-transform: none;
}

.action-btn {
  height: 40px !important;
  margin-left: 20px;
  background: #03b6da !important;
  border: 2px solid #fff;
  border-radius: 20px;
}

.signin-btn {
  background: #6348c6 !important;
}

.user-menu-btn {
  height: 40px !important;
  margin-left: 20px;
  background: #03b6da !important;
  border: 2px solid #fff;
  border-radius: 20px;
  text-transform: capitalize;
}

.action-btn-text {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0;
  text-transform: capitalize;
}
</style>
