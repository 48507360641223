const state = () => ({
  tutorial: true,
  cashOfferSubmitted: false
});

const mutations = {
  SET_TUTORIAL(state, payload) {
    state.tutorial = payload;
  },
  SET_CASH_OFFER_SUBMITTED(state, payload) {
    state.cashOfferSubmitted = payload;
  }
};

const actions = {
  setTutorial(context, payload) {
    context.commit("SET_TUTORIAL", payload);
  },
  async checkForCashOffer({ commit }) {
    try {
      const res = await this._vm.$http.get("/check_offer");
      commit("SET_CASH_OFFER_SUBMITTED", res.data);
    } catch (error) {
      console.error("Error checking cash offer:", error);
    }
  }
};

const getters = {
  tutorial: state => state.tutorial,
  cashOfferSubmitted: state => state.cashOfferSubmitted
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
