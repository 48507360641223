<template>
  <div
    class="premium-floorplan-view-container d-flex flex-column justify-center align-center"
  >
    <h2 class="premium-title">
      You discovered a pro feature!
    </h2>
    <p class="premium-description">
      Sign up with a pro account to view this floorplan.
    </p>
  </div>
</template>

<script>
export default {
  name: "PremiumFloorplanView",
  props: {
    //
  },
  data: () => ({
    premiumImage: "/img/images/logo.png"
  }),
  methods: {
    upgradePlan() {
      this.$store.commit("auth/SET_USER_PLAN", "PRO");
    }
  }
};
</script>

<style lang="scss" scoped>
.premium-floorplan-view-container {
  width: 100%;
  height: 100%;
  color: white;
  border: solid inside 1px #9b9b9b;
  background-image: linear-gradient(298deg, #03b6da, #6348c6);

  .premium-img {
    width: 120px;
    max-width: 20%;
    height: auto;
  }

  .premium-title {
    font-family: "Helvetica Neue Bold";
    font-size: 30px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .premium-description {
    margin-top: 4px;
    font-family: "Helvetica Neue Medium";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .upgrade-now-btn {
    width: 120px;
    height: 34px;
    background: #04b6da !important;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    text-align: center;
    color: #ffffff;
    border-radius: 0;
  }
}
</style>
