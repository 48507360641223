<template>
  <div class="google-map-container" :style="containerStyle">
    <div class="google-map-innerwrapper">
      <gmap-map
        ref="gmap"
        :center="center"
        :options="mapOptions"
        class="google-map"
      >
        <gmap-custom-marker
          :key="index"
          v-for="(m, index) in properties"
          :marker="{
            lat: m.latitude,
            lng: m.longitude
          }"
          class="custom-map-marker-wrapper"
          @click.native="onMarkerClick(m, index)"
        >
          <img :src="getMarkerUrl(m)" class="custom-map-icon" />
          <div class="custom-icon-text-wrapper">
            <span v-if="index > 0" class="custom-icon-text">{{ index }}</span>
          </div>
        </gmap-custom-marker>

        <gmap-info-window
          :options="infoOptions"
          :position="infoWindowPos"
          :opened="infoWinOpen"
          @closeclick="closeInfoWindow"
        >
          <map-info-window-content
            :property="selectedMarkerProperty"
            @map-marker-compare-click="onCompareClicked"
            @map-marker-discard-click="onDiscardClicked"
            @map-marker-undo-discard-click="onUndoDiscardClicked"
            @map-info-outside-click="onInfoOutsideClicked"
          />
        </gmap-info-window>
      </gmap-map>
    </div>
    <map-compare-popup
      v-if="displayComparePopup && isAuthenticated && isProUser"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoogleMapsAPI } from "gmap-vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import MapComparePopup from "@/components/MapComparePopup";
import MapInfoWindowContent from "@/components/Shared/MapInfoWindowContent";

const greenMarker = require("@/assets/home_green.svg");
const redMarker = require("@/assets/home_red.svg");
const grayMarker = require("@/assets/home_gray.svg");
const selectedMarker = require("@/assets/marker.svg");

export default {
  name: "GoogleMap",
  components: {
    "map-compare-popup": MapComparePopup,
    "map-info-window-content": MapInfoWindowContent,
    "gmap-custom-marker": GmapCustomMarker
  },
  props: {
    properties: Array,
    markers: Array,
    displayComparePopup: {
      type: Boolean,
      default: true
    },
    width: String,
    height: String,
    size: String
  },
  data: () => ({
    //a default center for the map
    center: { lat: 0, lng: 0 },
    map: null,
    mapOptions: {
      mapTypeControl: false,
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }]
        }
      ]
    },
    infoWindowPos: {
      lat: 0,
      lng: 0
    },
    infoWinOpen: false,
    currentMidx: null,
    //optional: offset infowindow so it visually sits nicely on top of our marker
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
    selectedMarkerProperty: {}
  }),
  mounted() {
    this.placeMarkers();
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isProUser"]),
    ...mapGetters("homesStore", ["selectedProperty", "selectedAddress"]),
    google: getGoogleMapsAPI,
    selectedPosition() {
      const { latitude: lat, longitude: lng } = this.selectedProperty || {};
      return {
        lat,
        lng
      };
    },
    containerStyle() {
      let paddingPercent = 66.67;

      if (this.size === "md") {
        paddingPercent = 66.67;
      } else if (this.size === "sm") {
        paddingPercent = 58.5;
      } else if (this.size === "lg") {
        paddingPercent = 80;
      }

      if (this.size === "sm") {
        return {
          paddingTop: `calc(${paddingPercent}% - 48px)`
        };
      }
      return {
        paddingTop: `${paddingPercent}%`
      };
    },
    propertiesWithLocations() {
      return this.properties.map(item => {
        return {
          ...item,
          position: this.getLatlong(item.address)
        };
      });
    }
  },
  methods: {
    placeMarkers() {
      //set bounds of the map
      this.$refs.gmap.$mapPromise.then(map => {
        const bounds = new this.google.maps.LatLngBounds();
        for (let m of this.markers) {
          if (m.position.lat && m.position.lng) {
            bounds.extend(m.position);
          }
        }
        map.fitBounds(bounds);
      });

      this.addMarker(this.selectedAddress);
      // this.geolocate();
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    },
    getMarkerOptions(m) {
      let markerUrl = selectedMarker;

      if (m.type === "selected") {
        markerUrl = selectedMarker;
      } else if (m.type === "suggested") {
        markerUrl = greenMarker;
      } else {
        markerUrl = redMarker;
      }

      return {
        url: markerUrl,
        size: { width: 40, height: 40, f: "px", b: "px" },
        scaledSize: { width: 40, height: 40, f: "px", b: "px" }
      };
    },
    getMarkerUrl(m) {
      let markerUrl;

      switch (m.type) {
        case "selected":
          markerUrl = selectedMarker;
          break;
        case "suggested":
          markerUrl = greenMarker;
          break;
        case "prediscarded":
          markerUrl = grayMarker;
          break;
        case "discarded":
          markerUrl = redMarker;
          break;
        default:
          markerUrl = greenMarker;
      }

      return markerUrl;
    },
    addMarker(place) {
      if (place.geometry) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        };
        this.center = marker;
      }
    },
    getLatlong(address) {
      const geocoder = new this.google.maps.Geocoder();
      let latitude = 0;
      let longitude = 0;
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          latitude = results[0].geometry.location.lat();
          longitude = results[0].geometry.location.lng();
        }
      });
      return {
        lat: latitude,
        lng: longitude
      };
    },
    onMarkerClick(marker, index) {
      this.selectedMarkerProperty = marker;
      // this.center = google && new google.maps.LatLng(marker.position);
      if (!marker.serverDiscard) {
        this.toggleInfoWindow(marker, index);
      }
    },
    toggleInfoWindow(marker, idx) {
      const { latitude: lat, longitude: lng } = marker;
      this.infoWindowPos = {
        lat,
        lng
      };

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    onInfoOutsideClicked() {
      // this.infoWinOpen = false;
    },
    closeInfoWindow() {
      this.infoWinOpen = false;
    },
    onCompareClicked(property) {
      this.$emit("marker-compare", property);
      this.closeInfoWindow();
    },
    onDiscardClicked(property) {
      this.$emit("marker-discard", property);
      this.closeInfoWindow();
    },
    onUndoDiscardClicked(property) {
      this.$emit("marker-undo-discard", property);
      this.closeInfoWindow();
    }
  },
  watch: {
    markers: {
      handler() {
        this.placeMarkers();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.google-map-container {
  position: relative;
  width: 100%;

  .google-map-innerwrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .google-map {
    width: 100%;
    height: 100%;
  }

  .custom-map-marker-wrapper {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-map-icon {
    width: 40px;
  }

  .custom-icon-text-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-icon-text {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }
}

::v-deep {
  .gm-style-iw {
    max-height: 300px !important;
    padding: 0;
    border-radius: 0;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .gm-style-iw-d {
    max-height: 300px !important;
  }

  .gm-ui-hover-effect {
    top: 0 !important;
    right: 6px !important;

    img {
      width: 24px !important;
      height: 24px !important;
    }
  }
}
</style>
