<template>
  <v-dialog v-model="showTOS" width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Our Terms of Service Has Changed
      </v-card-title>
      <v-card-text>
        Our terms of service have been recently amended. By clicking "I Accept"
        you acknowledge that you have reviewed and agree to our updated
        <router-link :to="{ name: 'TermsOfService' }" target="_blank"
          >Terms of Service</router-link
        >.
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="updateTOSAgreement">
          I accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["showTOS"])
  },
  methods: {
    ...mapActions("auth", ["updateTOSAgreement"])
  }
};
</script>

<style></style>
