<template>
  <v-dialog
    v-model="dialog"
    @click:outside="close"
    scrollable
    width="550px"
    class="select-comp-modal-container pa-0"
  >
    <v-card>
      <v-card-title>
        Select Comp
      </v-card-title>
      <v-divider />
      <v-card-text height="450px">
        <p class="sub-title">
          Click to select another property to add to your compare list.
        </p>
        <v-list three-line>
          <template v-for="(item, index) in addCompareItems">
            <v-divider :key="`divider-${index}`"></v-divider>
            <v-list-item
              :key="item.address"
              @mouseover="onItemMouseOver(index)"
            >
              <v-list-item-avatar class="item-img">
                <v-img :src="item.mlsImageUrls[0]"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="d-flex align-center justify-space-between">
                  <div class="item-content-left">
                    <div class="d-flex align-center">
                      <!-- <img :src="homeIcon" class="home-icon" /> -->
                      <h2 class="item-title text-left mb-0">
                        {{ item.address }}
                      </h2>
                    </div>
                    <p class="item-text text-left">
                      {{ cityInfo }}
                    </p>
                    <p class="item-text text-left">
                      <strong>{{ item.beds }}</strong> Beds |
                      <strong>{{ item.baths }}</strong> Baths |
                      <strong>{{ item.livingArea }}</strong> sqft
                    </p>
                  </div>
                  <div
                    class="item-content-right d-flex align-center justify-end"
                  >
                    <v-btn
                      outlined
                      class="compare-btn"
                      @click="onCompareBtnClick(item)"
                    >
                      Compare
                    </v-btn>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "SelectCompModal",
  components: {
    //
  },
  props: {
    dialog: Boolean
  },
  data: () => ({
    hovered: []
  }),
  computed: {
    ...mapGetters("homesStore", [
      "selectedProperty",
      "selectedAddress",
      "suggestedComps",
      "compareComps",
      "comps"
    ]),
    addCompareItems() {
      return this.suggestedComps.filter(comp => {
        return !this.compareComps.some(compareComp => {
          return compareComp.id === comp.id;
        });
      });
    },
    homeIcon() {
      return "/img/images/home_green.svg";
    },
    cityInfo() {
      const { city, state, zipCode, neighborhood } = this.selectedAddress;
      const { subdivision } = this.selectedProperty;
      const cityAddress = `${city}, ${state} ${zipCode}`;

      return `${cityAddress} | ${subdivision}`;
    }
  },
  mounted() {
    this.hovered = _.fill(Array(this.addCompareItems.length), false);
  },
  methods: {
    onClickOutside() {
      this.close();
    },
    close() {
      this.$emit("select-comp-modal-close");
    },
    getIconColor(type) {
      if (type === "SUGGESTED") {
        return "#68b81d";
      }
      return "#68b81d";
    },
    onItemMouseOver(hoveredIndex) {
      this.hovered = this.hovered.map((_, index) => {
        if (index === hoveredIndex) {
          return true;
        }
        return false;
      });
    },
    onCompareBtnClick(item) {
      const updatedCompareComps = [...this.compareComps, item];
      if (updatedCompareComps.length <= 3) {
        const compareProperties = _.cloneDeep(this.comps);
        const index = _.findIndex(compareProperties, cItem =>
          _.isEqual(cItem, item)
        );
        compareProperties[index].compare = true;
        this.$store.dispatch("homesStore/setComps", compareProperties);
        this.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.select-comp-modal-container {
}

::v-deep {
  .v-avatar {
    width: 110px !important;
    height: 80px !important;
    border-radius: 0;
  }

  .v-dialog {
    box-shadow: none;
    overflow: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .v-window {
    background: none;
  }

  .v-list-item__content {
    .item-title {
      font-family: "Helvetica Neue Bold";
      font-size: 14px;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #5c5e62;
    }

    .item-text {
      font-family: "Helvetica Neue Medium";
      font-size: 14px;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #5c5e62;
    }

    .home-icon {
      height: 24px;
    }
    .compare-btn {
      width: 90px;
      height: 34px;
      background: none !important;
      border: solid 1px #04b6da !important;
      font-family: "Helvetica Neue Medium";
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      text-transform: none;
      color: #04b6da !important;
    }
  }
}
</style>
