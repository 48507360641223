<template>
  <v-card class="map-info-window-content-card" v-click-outside="onClickOutside">
    <img :src="propertyImage" alt="property image" class="property-image" />
    <span class="sold-date">
      {{ soldDate }}
    </span>
    <v-card-text
      class="property-content d-flex flex-column align-start justify-start"
    >
      <h2 class="card-title">{{ `$${salePrice}` }}</h2>
      <p class="card-content-text">{{ roomInfo }}</p>
      <p class="card-content-text street-info">{{ streetAddress }}</p>
      <p class="card-content-text">{{ cityAddress }}</p>
      <p class="card-content-text text-light">({{ neighborhood }})</p>
      <div v-if="isSelected">
        <p class="card-content-text est-arv">{{ `$${estARV}` }}</p>
        <p class="card-content-text">Est. ARV</p>
      </div>
      <div
        v-if="isDiscarded || isSuggested"
        class="discarded-section d-flex flex-column align-start"
      >
        <p class="card-content-text build-match">
          <strong>{{ buildMatch }}</strong>
          <span class="text-light">Build Match</span>
        </p>
        <div
          v-if="isSuggested"
          class="action-section d-flex justify-space-between"
        >
          <v-btn
            depressed
            class="compare-btn btn-text"
            @click.prevent="onCompareClick"
          >
            Compare
          </v-btn>
          <v-btn
            depressed
            class="discard-btn btn-text"
            @click.prevent="onDiscardClick"
          >
            Discard
          </v-btn>
        </div>
        <div
          v-if="isDiscarded"
          class="action-section d-flex justify-space-between"
        >
          <v-spacer></v-spacer>
          <v-btn
            outlined
            class="discard-btn btn-text"
            @click.prevent="onUndoDiscardClick"
          >
            Undo Discard
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import addressMixin from "@/mixins/addressMixin";

export default {
  name: "MapInfoWindowContent",
  mixins: [addressMixin],
  props: {
    property: Object
  },
  computed: {
    isSelected() {
      if (!this.property) return false;
      return this.property.type === "selected";
    },
    isSuggested() {
      if (!this.property) return false;
      return this.property.type === "suggested";
    },
    isDiscarded() {
      if (!this.property) return false;
      return this.property.type === "discarded";
    },
    isPreDiscarded() {
      if (!this.property) return false;
      return !!this.property.preDiscarded;
    },
    salePrice() {
      return (this.property && this.property.price) || 0;
    },
    soldDate() {
      if (!this.property) return "";
      const { saleDateStr: lastSoldDate } = this.property;
      return `Sold ${lastSoldDate}`;
    },
    propertyImage() {
      return (
        (this.property &&
          this.property.mlsImageUrls &&
          this.property.mlsImageUrls[0]) ||
        ""
      );
    },
    roomInfo() {
      if (!this.property) {
        return "";
      }

      const { beds, baths, livingArea: sqFt } = this.property;

      return `${beds} bd | ${baths} ba | ${sqFt} sqft`;
    },
    streetAddress() {
      const separatedAddress = this.$_addressMixin_separateAddress(
        (this.property && this.property.address) || ""
      );
      if (separatedAddress) {
        return separatedAddress.streetAddress || "";
      } else {
        return "";
      }
    },
    cityAddress() {
      if (!this.property) {
        return "";
      }

      const separatedAddress = this.$_addressMixin_separateAddress(
        (this.property && this.property.address) || ""
      );
      if (separatedAddress) {
        const { city, state, zipCode } = separatedAddress;
        return `${city} ${state} ${zipCode}`;
      } else {
        return "";
      }
    },
    neighborhood() {
      const { subdivision: neighborhood } = this.property || {};
      return neighborhood || "";
    },
    estARV() {
      const { arv } = this.property || {};
      return arv || 0;
    },
    buildMatch() {
      if (!this.property) return "";
      const { buildMatch } = this.property;
      return `${buildMatch}%`;
    }
  },
  mounted() {
    //
  },
  methods: {
    onCompareClick() {
      this.$emit("map-marker-compare-click", this.property);
    },
    onDiscardClick() {
      this.$emit("map-marker-discard-click", this.property);
    },
    onUndoDiscardClick() {
      this.$emit("map-marker-undo-discard-click", this.property);
    },
    onClickOutside() {
      this.$emit("map-info-outside-click");
    }
  }
};
</script>

<style lang="scss" scoped>
.map-info-window-content-card {
  .property-image {
    width: 100%;
    height: 100px;
    border-radius: 0 !important;
    object-fit: cover;
  }

  .property-content {
    padding: 10px;
  }

  .card-title {
    font-family: "Helvetica Neue Bold";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .card-content-text {
    font-family: "Helvetica Neue Medium";
    font-size: 12px;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #5c5e62;
    margin-bottom: 0;
    margin-top: 3px;
  }

  .sold-date {
    position: absolute;
    top: 6px;
    left: 6px;
    border-radius: 8.5px;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: "Helvetica Neue Medium";
    font-size: 10px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding: 2px 6px;
  }

  .street-info {
    margin-top: 7px;
  }

  .text-light {
    color: #9b9b9b;
  }

  .est-arv {
    margin-top: 10px;
  }

  .build-match {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .action-section {
    width: 100%;
  }

  .compare-btn {
    width: 85px;
    max-width: 50%;
    height: 25px;
    border: solid 1px #04b6da;
    background: none !important;
  }

  .discard-btn {
    width: 85px;
    max-width: 50%;
    height: 25px;
    background: none !important;
  }

  .btn-text {
    font-family: "Helvetica Neue Medium";
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #04b6da;
  }

  .discarded-section {
    width: 100%;
  }
}

::v-deep {
}
</style>
