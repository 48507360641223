<template>
  <v-card v-if="!accountCreated">
    <v-card-title class="headline primary white--text">
      Pro User Sign Up
    </v-card-title>

    <v-form v-model="valid" ref="proForm">
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="firstname"
              :rules="nameRules"
              label="First name"
              name="firstname"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="lastname"
              :rules="nameRules"
              label="Last name"
              name="lastname"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              name="email"
              :error-messages="emailError"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              :rules="passwordRules"
              label="Password"
              name="password"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              v-model="password2"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              @click:append="show = !show"
              :rules="[...passwordRules, passwordMatch]"
              label="Confirm Password"
              name="password2"
              :error-messages="passwordError"
              required
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-checkbox v-model="tos" :rules="tosRules">
              <template #label>
                <div class="caption">
                  By checking the box you acknowledge that you have read and
                  agree to the
                  <a @click.stop href="terms" target="_blank"
                    >Terms of Service</a
                  >
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-actions>
      <v-btn text @click="$emit('close')">
        cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="validate">
        Sign Up
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-title class="headline blue white--text">
      Confirm your email
    </v-card-title>

    <v-card-text class="mt-4">
      <h1 class="mb-4">Verify your email to continue</h1>
      <p>Please check your email for a verification link</p>
      <p>You will be able to login after your email has been verified.</p>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" tile depressed @click="close">
        close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    error: {},
    valid: false,
    username: "",
    firstname: "",
    lastname: "",
    accountCreated: false,
    nameRules: [v => !!v || "Name is required"],
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    tos: false,
    tosRules: [v => !!v || "You must accept the tos"],
    show: false,
    password: "",
    password2: "",
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length >= 8 || "Password must be greater than 7 characters"
    ]
  }),
  computed: {
    passwordMatch() {
      return this.password === this.password2 || "Password must match";
    },
    passwordError() {
      return this.error.password || "";
    },
    usernameError() {
      return this.error.username || "";
    },
    emailError() {
      return this.error.email || "";
    }
  },
  methods: {
    ...mapActions("auth", ["register"]),
    async validate() {
      this.error = {};

      if (!this.$refs.proForm.validate()) return;

      try {
        await this.register({
          username: this.email,
          first_name: this.firstname,
          last_name: this.lastname,
          email: this.email,
          password: this.password,
          password2: this.password2,
          is_pro: true
        });

        this.accountCreated = true;
      } catch (error) {
        console.error("Error creating pro account:", error.response.data);
        this.error = error.response.data;
      }
    },
    close() {
      this.$emit("close");
      setTimeout(() => {
        this.accountCreated = false;
      }, 1000);
    }
  }
};
</script>

<style></style>
