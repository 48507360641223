<template>
  <div class="faq-container d-flex flex-column align-center justify-center">
    <h2>FAQ Page Coming Soon ...</h2>
    <div style="width: 100px; height 200px;">
      <SliderLoader />
    </div>
    <Loader />
  </div>
</template>

<script>
import Loader from "@/components/Shared/Loader";
import SliderLoader from "@/components/Shared/SliderLoader";
export default {
  name: "FAQ",
  components: {
    SliderLoader,
    Loader
  },
  props: {
    //
  },
  data: () => ({
    //
  }),
  computed: {
    //
  },
  methods: {
    //
  }
};
</script>

<style lang="scss" scoped>
.faq-container {
  width: 100%;
  height: 100%;
}
</style>
