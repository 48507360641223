const state = () => ({
  mapPopupViewMode: 0,
  snack: ""
});

const mutations = {
  SET_MAP_POPUP_VIEW_MODE(state, mode) {
    state.mapPopupViewMode = mode;
  },
  SET_SNACK(state, mode) {
    state.snack = mode;
  }
};

const actions = {
  setMapPopupViewMode(context, payload) {
    context.commit("SET_MAP_POPUP_VIEW_MODE", payload);
  },
  setSnack(context, payload) {
    context.commit("SET_SNACK", payload);
  }
};

const getters = {
  //
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
