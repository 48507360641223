<template>
  <v-container fluid class="quick-compare-container d-flex flex-column">
    <div class="navigation-section d-flex justify-start">
      <v-btn depressed class="back-btn" @click="onBack">
        <v-icon class="mr-1">mdi-arrow-left</v-icon>Back to Search
      </v-btn>
    </div>
    <quick-compare-image-row
      :compareData="selectedQuickCompareProperty"
      @discard-quick-compare="onQuickCompareDiscarded"
      @undiscard-quick-compare="onQuickCompareUnDiscard"
      @prev-quick-compare="onQuickComparePrevNavigated"
      @next-quick-compare="onQuickCompareNextNavigated"
    />
    <div class="scroll-content">
      <compare-table
        tableName="Compare"
        :tableData="compareList"
        :tableColumnNumber="2"
      />
      <compare-table
        tableName="Overview"
        :tableData="overviewList"
        :tableColumnNumber="2"
      />
      <compare-table
        tableName="Location"
        :tableData="locationList"
        :tableColumnNumber="2"
      />
      <compare-table
        tableName="Amenities"
        :tableData="amenitiesList"
        :tableColumnNumber="2"
      />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { calculateARV } from "@/utils/arv";
import QuickCompareImageRow from "@/components/QuickCompareImageRow";
import CompareTable from "@/components/CompareTable";

export default {
  name: "QuickCompare",
  components: {
    QuickCompareImageRow,
    CompareTable
  },
  props: {
    //
  },
  data: () => ({
    selectedQuickCompareProperty: {}
  }),
  computed: {
    ...mapGetters("homesStore", [
      "selectedProperty",
      "quickCompareProperty",
      "suggestedComps",
      "discardedComps",
      "mlsConstants",
      "comps",
      "priceAdjust"
    ]),
    compareData() {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.percentDiffLiv = null;
      selectedProperty.drivingDistance = null;
      return [selectedProperty, this.selectedQuickCompareProperty];
    },
    compareList() {
      const estARVs = _.map(this.compareData, item =>
        this.getFormatedEstARV(Math.round((item && item.arv) || 0))
      );
      const buildMatches = _.map(this.compareData, "buildMatch");
      const list = [
        {
          title: "Est. ARV",
          unit: "$",
          prefix: true,
          list: estARVs,
          cellColor: "#04b6da"
        },
        {
          title: "Build Match %",
          unit: "",
          list: buildMatches
        }
      ];
      return list;
    },
    overviewList() {
      const bedRooms = _.map(this.compareData, "beds");
      const bathRooms = _.map(this.compareData, "baths");
      const livingAreas = _.map(this.compareData, "livingArea");
      const diffLivingAreas = _.map(this.compareData, "percentDiffLiv");
      const lotSizes = _.map(this.compareData, "lotSizeArea");
      const diffLotSizes = _.map(this.compareData, "lotSizeDiff");
      const extWalls = _.map(this.compareData, "extWall");
      const stories = _.map(this.compareData, "stories");
      const yearBuilts = _.map(this.compareData, "yearBuilt");
      const lastSolds = _.map(this.compareData, "saleDateStr");
      const daysOnMarkets = _.map(this.compareData, "numDaysOn");
      const listPrices = _.map(this.compareData, "origList");
      const salePrices = _.map(this.compareData, "salePriceStr");
      const askingPrices = [];
      const dollarPerSqfts = _.map(this.compareData, item => {
        return Math.floor(item.compsCostPerSqftActual || 0);
      });
      const list = [
        {
          title: "Bedrooms",
          unit: "",
          list: bedRooms
        },
        {
          title: "Bathrooms",
          unit: "",
          list: bathRooms
        },
        {
          title: "Living Area",
          unit: "sqft",
          list: livingAreas
        },
        {
          title: "% Diff. Living Area",
          unit: "",
          list: diffLivingAreas
        },
        {
          title: "Lot Size",
          unit: "sqft",
          list: lotSizes
        },
        {
          title: "% Diff. Lot Size",
          unit: "",
          list: diffLotSizes
        },
        {
          title: "Exterior Wall Type",
          unit: "",
          list: extWalls
        },
        {
          title: "Stories",
          unit: "",
          list: stories
        },
        {
          title: "Year Built",
          unit: "",
          list: yearBuilts
        },
        {
          title: "Last Sold",
          unit: "",
          list: lastSolds
        },
        {
          title: "Days on Market",
          unit: "",
          list: daysOnMarkets
        },
        {
          title: "List Price",
          unit: "",
          prefix: true,
          list: listPrices
        },
        {
          title: "Sale Price",
          unit: "",
          prefix: true,
          list: salePrices
        },
        {
          title: "% (+/-) Asking Price",
          unit: "%",
          list: askingPrices
        },
        {
          title: "$ / Sqft",
          unit: "$",
          prefix: true,
          list: dollarPerSqfts
        }
      ];
      return list;
    },
    locationList() {
      const distanceFromSelecteds = _.map(this.compareData, "drivingDistance");
      const neighborhoods = _.map(this.compareData, "subdivision");
      const list = [
        {
          title: "Distance from selected",
          unit: "miles",
          list: distanceFromSelecteds
        },
        {
          title: "Neighborhood",
          unit: "",
          list: neighborhoods
        }
      ];
      return list;
    },
    amenitiesList() {
      const waterfrontYNs = _.map(this.compareData, "waterfrontYN");
      const pools = _.map(this.compareData, "pool");
      const parkingTypes = _.map(this.compareData, "parkingType");
      const parkingSpaces = _.map(this.compareData, "parkingArea");
      const airConditionings = _.map(this.compareData, "airCond");
      const list = [
        {
          type: "waterFront",
          title: "Water Front (Y/N)",
          unit: "",
          list: waterfrontYNs
        },
        {
          type: "pool",
          title: "Pool (Y/N)",
          unit: "",
          list: pools
        },
        {
          type: "parkingType",
          title: "Parking Type",
          unit: "",
          list: parkingTypes
        },
        {
          type: "parkingSpaces",
          title: "Parking Space",
          unit: "",
          list: parkingSpaces
        },
        {
          type: "ac",
          title: "Air Conditioning",
          unit: "",
          list: airConditionings
        }
      ];
      return list;
    }
  },
  created() {
    this.selectedQuickCompareProperty = this.quickCompareProperty;
  },
  methods: {
    onBack() {
      this.$router.replace("/properties");
    },
    getNumberWithCommas(numberValue) {
      return Math.floor(numberValue)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getCompNeigh(neighborhood) {
      return (neighborhood && neighborhood.split("/")[1]) || "";
    },
    getFormatedEstARV(value) {
      return this.getNumberWithCommas(value);
    },
    onQuickCompareDiscarded(item) {
      const suggestedCompareProperties = _.cloneDeep(this.suggestedComps);
      const index = _.findIndex(suggestedCompareProperties, cItem =>
        _.isEqual(cItem.id, item.id)
      );
      const suggestedItems = _.cloneDeep(this.suggestedComps);
      if (index >= 0) {
        if (suggestedItems.length > 1) {
          const compareProperties = _.cloneDeep(this.comps);
          const compareIndex = _.findIndex(compareProperties, cItem =>
            _.isEqual(cItem.id, item.id)
          );
          compareProperties[compareIndex].compare = false;
          compareProperties[compareIndex].suggestedDiscard = true;
          this.selectedQuickCompareProperty =
            suggestedItems[index + 1] || suggestedItems[0];
          this.$store.dispatch("homesStore/setComps", compareProperties);
          this.updateSelectedPropertyARV();
        }
      }
    },
    onQuickCompareUnDiscard(item) {
      const discardedProperties = _.cloneDeep(this.discardedComps);
      const index = _.findIndex(discardedProperties, cItem =>
        _.isEqual(cItem.id, item.id)
      );
      const discardedItems = _.cloneDeep(this.discardedComps);
      const suggestedItems = _.cloneDeep(this.suggestedComps);
      if (index >= 0) {
        if (discardedItems.length > 1) {
          const compareProperties = _.cloneDeep(this.comps);
          const compareIndex = _.findIndex(compareProperties, cItem =>
            _.isEqual(cItem.id, item.id)
          );
          compareProperties[compareIndex].compare = false;
          compareProperties[compareIndex].suggestedDiscard = false;
          this.selectedQuickCompareProperty =
            suggestedItems[index + 1] || suggestedItems[0];
          this.$store.dispatch("homesStore/setComps", compareProperties);
          this.updateSelectedPropertyARV();
        }
      }
    },
    onQuickComparePrevNavigated(item) {
      const suggestedItems = _.cloneDeep(this.suggestedComps);
      const index = _.findIndex(suggestedItems, cItem =>
        _.isEqual(cItem, item)
      );
      if (index > 0) {
        this.selectedQuickCompareProperty = suggestedItems[index - 1] || {};
      } else {
        this.selectedQuickCompareProperty =
          suggestedItems[suggestedItems.length - 1] || {};
      }
    },
    onQuickCompareNextNavigated(item) {
      const suggestedItems = _.cloneDeep(this.suggestedComps);
      const index = _.findIndex(suggestedItems, cItem =>
        _.isEqual(cItem, item)
      );

      if (index !== -1 && index < suggestedItems.length - 1) {
        this.selectedQuickCompareProperty = suggestedItems[index + 1] || {};
      } else {
        this.selectedQuickCompareProperty = suggestedItems[0] || {};
      }
    },
    updateSelectedPropertyARV() {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.arv = calculateARV(
        this.comps,
        selectedProperty,
        this.mlsConstants,
        this.priceAdjust
      );
      this.$store.dispatch("homesStore/setSelectedProperty", selectedProperty);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-content {
  margin-top: 12px;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 800px;
}

.sticky-property {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.quick-compare-container {
  width: 100%;
  height: 100%;
  padding: 50px 40px 40px 40px;
}

@media screen and (max-width: 600px) {
  .quick-compare-container {
    padding: 0 20px 0 10px;
  }
}

::v-deep {
  .back-btn {
    background: none !important;
    text-transform: none;
    font-family: "Helvetica Neue Medium";
    font-size: 16px;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #5c5e62;
    margin-bottom: 37px;
  }
}
</style>
