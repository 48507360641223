<template>
  <div class="d-flex flex-column">
    <p>
      Lets begin estimating the rehab costs for the property listed below.
    </p>
    <div class="mt-12">
      <h3 class="text-center">{{ selectedAddress.address }}</h3>
      <!-- <GmapAutocomplete
        :value="selectedAddress.address"
        :options="{
          fields: ['geometry', 'formatted_address', 'address_components']
        }"
        class="gmap-autocomplete"
        @place_changed="getAddress"
      /> -->
    </div>
    <p class="text-caption" style="margin-top: auto">
      This beta version of the Rehab Calculator was based on our actual
      renovation costs and is intended to serve as a rough guide. Please keep in
      mind that your construction costs can vary significantly depending on the
      contractor that you use. Material prices have been rising substantially as
      well. In the future we aim to make the Rehab Calculator tool customizable
      to the individual user in order to more accurately predict their actual
      construction costs. You should always consult with your contractor
      regarding renovation costs before making any decisions.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("homesStore", ["selectedAddress"])
  },
  methods: {
    getAddress(address) {
      this.$emit("answer", address.formatted_address);
    }
  }
};
</script>

<style lang="scss" scoped>
.gmap-autocomplete {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 2px solid #a0c3d2;
  border-radius: 3px;
  padding-left: 15px;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
}
</style>
