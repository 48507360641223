<template>
  <div
    class="add-comp-button-container d-flex flex-column align-center justify-center"
  >
    <v-icon class="plus-btn-icon">mdi-plus-circle-outline</v-icon>
    <span @click="addNextComp" class="btn-text">View Next Comp</span>
    <span class="or-text">or</span>
    <span @click="selectComp" class="btn-text">
      Select Specific Comp
    </span>
  </div>
</template>

<script>
export default {
  name: "AddCompButton",
  props: {
    //
  },
  methods: {
    selectComp() {
      this.$emit("select-comp-modal-open");
    },
    addNextComp() {
      this.$emit("add-next-comp");
    }
  }
};
</script>

<style lang="scss" scoped>
.add-comp-button-container {
  width: 250px;
  height: 180px;
  border: solid 3px #04b6da;
  border-style: dashed;
  cursor: pointer;

  .plus-btn-icon {
    width: 30px;
    height: 30px;
    color: #04b6da;
    font-size: 30px;
    margin-bottom: 10px;
  }

  .btn-text {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #04b6da;

    &:hover {
      text-decoration: underline;
    }
  }

  .or-text {
    color: #9b9b9b;
  }
}
</style>
