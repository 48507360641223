<template>
  <div class="question-container">
    <v-container>
      <v-row v-if="formmatedQuestionData.condition === 'excellent'">
        <v-col class=" estimate">
          <p>It appears that your home is already in retail ready condition.</p>
          <p>
            Listing the property is the best bet to ensure that you receive the
            highest price for your home.
          </p>
          <p>Currently our software places your home at a value of:</p>
          <h1 class="text-center">
            ${{ $_numbersMixin_getNumberWithCommas(this.selectedProperty.arv) }}
          </h1>
          <p>
            However a manual valuation by one of our agents may indicate a
            higher potential list price.
          </p>
          <p>
            Would you like to speak with a licensed agent to discuss the
            details?
          </p>
        </v-col>
      </v-row>
      <div v-else>
        <v-row class="mb-4">
          <v-col>
            Est. After Repair Value:
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div style="max-width: 200px">
                The After Repair Value is the amount your home should be worth
                once it is in top, retail ready conditon.
              </div>
            </v-tooltip>
          </v-col>
          <v-col class="text-right font-weight-bold">
            ${{ $_numbersMixin_getNumberWithCommas(this.selectedProperty.arv) }}
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col>
            Est. Rehab Costs:
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div style="max-width: 200px">
                The estimation of costs required to achieve your home's max
                value, is calculated using all of the answers you have provided
                us. If you don't agree with the rehab cost estimate we have
                arrived at, please do not despair, one of our agents will be
                happy to do a manual assessment if you are interested?
              </div>
            </v-tooltip>
          </v-col>
          <v-col class="text-right font-weight-bold">
            - ${{ $_numbersMixin_getNumberWithCommas(rehab) }}
          </v-col>
        </v-row>

        <v-row class="mb-4">
          <v-col>
            Fees:
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div style="max-width: 200px">
                If you choose to sell us your home, these are the fees that
                eHomefacts would collect for purchasing, renovating, and
                reselling your home. They are a flat 10% of the ARV.
              </div>
            </v-tooltip>
          </v-col>
          <v-col class="text-right font-weight-bold">
            - ${{ $_numbersMixin_getNumberWithCommas(fees) }}
          </v-col>
        </v-row>

        <v-divider class="mb-4"></v-divider>

        <v-row>
          <v-col class="d-flex align-center font-weight-black">
            <div>Cash Offer:</div>
          </v-col>
          <v-col class="text-right font-weight-bold">
            <h1 class="cash--offer">
              ${{ $_numbersMixin_getNumberWithCommas(cashOffer) }}
            </h1>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="d-flex align-center text-subtitle-2">
              This offer is valid for 5 days. Would you like one of our agents
              to contact you within 24hrs to discuss further?
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import numbersMixin from "@/mixins/numbersMixin";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "CashOfferEstimateStep",
  props: ["questionData"],
  mixins: [numbersMixin],
  data: () => ({
    cashOffer: 0,
    fees: 0,
    rehab: 0,
    loading: false
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedProperty"]),
    formmatedQuestionData() {
      const data = {};
      this.questionData.forEach(q => {
        data[q.name] = q.answer;
      });

      data.est_arv = this.selectedProperty.arv;
      data.living_area = this.selectedProperty.livingArea;
      data.baths = this.selectedProperty.baths;

      return data;
    }
  },
  methods: {
    ...mapMutations("cashoffer", ["SET_OFFER_DETAILS"]),
    async calculateRehabCostsAPI() {
      this.loading = true;

      try {
        const res = await this.$http.post("/offer", this.formmatedQuestionData);
        this.cashOffer = res.data.cashOffer;
        this.fees = res.data.fees;
        this.rehab = res.data.rehab;

        this.SET_OFFER_DETAILS({
          cashOffer: this.cashOffer,
          arv: this.selectedProperty.arv,
          rehab: this.rehab,
          fees: this.fees
        });
      } catch (error) {
        console.error("Error getting rehab calculation:", error);
      }

      this.loading = false;
    }
  },
  mounted() {
    if (this.formmatedQuestionData.condition !== "excellent") {
      this.calculateRehabCostsAPI();
    } else {
      this.SET_OFFER_DETAILS({
        cashOffer: this.cashOffer,
        arv: this.selectedProperty.arv,
        rehab: this.rehab,
        fees: this.fees
      });
    }
  },
  watch: {
    questionData(newVal, oldVal) {
      if (this.formmatedQuestionData.condition !== "excellent") {
        this.calculateRehabCostsAPI();
      } else {
        this.SET_OFFER_DETAILS({
          cashOffer: this.cashOffer,
          arv: this.selectedProperty.arv,
          rehab: this.rehab,
          fees: this.fees
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.question-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 15px;
}

.btn {
  border: 1px solid #a0c3d2;
  border-radius: 3px;
  padding: 10px 20px;
  width: 100px;

  &:focus {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 3px #74cfe7;
    background-color: #e8f4fc;
    outline: none;
  }
}

.estimate {
  p {
    font-size: 1.1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #5c5e62;
  }

  h1 {
    padding: 1rem 0;
    color: #04b6da;
    margin: 15px 0;
    font-size: 5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    color: #04b6da;
  }
}

.cash--offer {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  color: #358297;
}
</style>
