<template>
  <div class="condition-question">
    <p>
      Overall, how would you rate the condition of your home?
    </p>
    <v-row class="mt-4">
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(condition, i) in conditions"
        :key="condition.name"
      >
        <ConditionCard
          @click.native="setCondition(condition.type, i)"
          :imageSrc="condition.imgSrc"
          :title="condition.name"
          :color="condition.color"
          :active="i === activeIndex"
        >
          <template #description>
            {{ condition.description }}
          </template>
        </ConditionCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ConditionCard from "./CashOfferConditionCard";
export default {
  name: "CashOfferConditionStep",
  components: {
    ConditionCard
  },
  data() {
    return {
      activeIndex: null,
      condition: null,
      conditions: [
        {
          name: "Excellent",
          type: "excellent",
          imgSrc: "img/images/rehab/excellent-house.png",
          color: "#74cfe7",
          description:
            "The house is like new. It's in tip top shape with modern ammenities."
        },
        {
          name: "Good",
          type: "good",
          imgSrc: "img/images/rehab/good-house.png",
          color: "#a5ba52",
          description:
            "The house has no major issues but it is old and needs to be updated."
        },
        {
          name: "Fair",
          type: "fair",
          imgSrc: "img/images/rehab/fair-house.png",
          color: "#f6a724",
          description:
            "The house has some issues and needs a moderate amount of repairs."
        },
        {
          name: "Bad",
          type: "bad",
          imgSrc: "img/images/rehab/bad-house.png",
          color: "#f37777",
          description: "The house needs extensive repairs. It's in rough shape."
        }
      ]
    };
  },
  methods: {
    ...mapMutations("rehab", ["SET_CONDITION"]),
    setCondition(condition, index) {
      this.activeIndex = index;
      this.SET_CONDITION(condition);
      this.$emit("answer", condition);
    }
  }
};
</script>

<style lang="scss" scoped>
// .condition-question {
//   color: #5c5e62;
//   p {
//     font-size: 1.5rem;
//   }
// }
</style>
