<template>
  <v-dialog
    v-model="dialogOpen"
    @click:outside="onClickOutside"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <div class="d-flex justify-end pt-2 pr-2">
        <v-btn text class="close-btn" @click="onClose">
          <v-icon class="close-icon">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-container>
        <v-row>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="12">
                <v-img
                  :src="mainImage"
                  class="main-image"
                  @click="onMainImageClick"
                />
              </v-col>
              <v-col cols="6" v-for="(item, index) in restImages" :key="index">
                <v-img :src="item" class="rest-image" />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <div
              class="property-info-section d-flex justify-space-between align-start"
            >
              <div
                class="property-left-info-section d-flex flex-column align-start"
              >
                <h2 class="address">{{ streetAddress }}</h2>
                <p class="city-info text-left">{{ cityInfo }}</p>
                <p class="home-info">
                  <strong>{{ beds }}</strong> Beds |
                  <strong>{{ baths }}</strong> Baths |
                  <strong>{{ sqFt }}</strong> sqft
                </p>
              </div>
              <div
                class="property-right-info-section d-flex flex-column align-end"
              >
                <h2 class="est-arv-column">
                  {{ `$${$_numbersMixin_getNumberWithCommas(salePrice)}` }}
                </h2>
                <p class="sold-info text-right">
                  Last Sold: {{ data.saleDateStr }}
                </p>
                <p class="text-right">
                  {{ `${salePercent}%` }} below list
                  {{ `$${$_numbersMixin_getNumberWithCommas(listPrice)}` }}
                </p>
              </div>
            </div>
            <property-detail-table
              tableName="Description"
              :headers="descriptionHeaders"
              :properties="propertyDescription"
            />
            <property-detail-table
              tableName="Overview"
              :headers="detailTableHeaders"
              :properties="overviewProperties"
            />
            <property-detail-table
              tableName="Location"
              :headers="detailTableHeaders"
              :properties="locationProperties"
            />
            <property-detail-table
              tableName="Amenities"
              :headers="detailTableHeaders"
              :properties="amenitiesProperties"
            />
          </v-col>
        </v-row>
        <property-image-view-overlay
          v-if="propertyImageViewOverlayOpen"
          :data="data"
          :overlay="propertyImageViewOverlayOpen"
          @close-overlay="onOverlayClosed"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import numbersMixin from "@/mixins/numbersMixin";
import { mapGetters } from "vuex";
import addressMixin from "@/mixins/addressMixin";
import PropertyDetailTable from "@/components/PropertyDetailTable";
import PropertyImageViewOverlay from "@/components/PropertyImageViewOverlay";

export default {
  name: "PropertyDetailModal",
  components: {
    PropertyDetailTable,
    PropertyImageViewOverlay
  },
  mixins: [addressMixin, numbersMixin],
  props: {
    dialog: Boolean,
    data: Object,
    selectedImage: Number
  },
  data: () => ({
    dialogOpen: false,
    imageList: [],
    propertyImageViewOverlayOpen: false
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedAddress"]),
    ...mapGetters("auth", ["isAuthenticated", "isProUser"]),
    googleStreetViewStatic() {
      return `https://maps.googleapis.com/maps/api/streetview?size=600x600&location=${this.data.latitude},${this.data.longitude}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
    },
    mainImage() {
      const { mlsImageUrls } = this.data;
      return (
        (mlsImageUrls.length && mlsImageUrls[this.selectedImage]) ||
        this.googleStreetViewStatic
      );
    },
    restImages() {
      const { mlsImageUrls, floorplanURL } = this.data;
      if (mlsImageUrls.length && floorplanURL) {
        return this.isAuthenticated && this.isProUser
          ? [...mlsImageUrls, floorplanURL]
          : mlsImageUrls;
      } else if (mlsImageUrls.length && !floorplanURL) {
        return mlsImageUrls;
      } else if (!mlsImageUrls.length && floorplanURL) {
        return this.isAuthenticated && this.isProUser
          ? [this.googleStreetViewStatic, floorplanURL]
          : [this.googleStreetViewStatic];
      } else {
        return [this.googleStreetViewStatic];
      }
    },
    streetAddress() {
      const { address } = this.data;
      const separatedAddress = this.$_addressMixin_separateAddress(address);
      return (separatedAddress && separatedAddress.streetAddress) || "";
    },
    cityInfo() {
      const { city, state, zipCode } = this.selectedAddress;
      const { subdivision } = this.data;
      const cityAddress = `${city}, ${state} ${zipCode}`;

      return `${cityAddress} | ${subdivision}`;
    },
    beds() {
      return this.data.beds;
    },
    baths() {
      return this.data.baths;
    },
    sqFt() {
      return this.data.livingArea;
    },
    estARV() {
      return this.data.estARV;
    },
    salePrice() {
      return this.data.price;
    },
    listPrice() {
      return parseFloat(
        this.data.origList.replace("$", "").replace(",", "") || "0"
      );
    },
    salePercent() {
      return (
        (this.listPrice &&
          Math.round((1 - this.salePrice / this.listPrice) * 100)) ||
        0
      );
    },
    dollarPerSqft() {
      return this.data.dollarPerSqft;
    },
    detailTableHeaders() {
      return [
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        { text: "Value", value: "value" }
      ];
    },
    descriptionHeaders() {
      return [{ text: "Value", value: "value" }];
    },
    propertyDescription() {
      return [
        {
          name: "Description",
          value: this.data.propDescription
        }
      ];
    },
    overviewProperties() {
      const {
        beds,
        baths,
        livingArea,
        lotSizeArea,
        extWall: style,
        stories,
        yearBuilt,
        saleDateStr: lastSoldDate,
        numDaysOn: daysOnMarket,
        origList: listPrice
      } = this.data;

      return [
        {
          name: "Bedrooms",
          value: beds
        },
        {
          name: "Bathrooms",
          value: baths
        },
        {
          name: "Living Area",
          value: livingArea
        },
        {
          name: "Lot Size",
          value: lotSizeArea
        },
        {
          name: "Style",
          value: style
        },
        {
          name: "Stories",
          value: stories
        },
        {
          name: "Year Built",
          value: yearBuilt
        },
        {
          name: "Last Sold",
          value: lastSoldDate
        },
        {
          name: "Days on Market",
          value: daysOnMarket
        },
        {
          name: "List Price",
          value: listPrice
        }
      ];
    },
    locationProperties() {
      const { distance, neighStr: neighborhood } = this.data;

      return [
        {
          name: "Distance from Selected",
          value: distance < 1000000000 ? distance : "N/A"
        },
        {
          name: "Neighborhood",
          value: neighborhood
        }
      ];
    },
    amenitiesProperties() {
      const {
        waterfrontYN,
        pool,
        parkingType,
        parkingArea: parkingSpaces,
        airCond
      } = this.data;

      return [
        {
          type: "waterFront",
          name: "Water Front (Y/N)",
          value: waterfrontYN
        },
        {
          type: "pool",
          name: "Pool (Y/N)",
          value: pool
        },
        {
          type: "parkingType",
          name: "Parking Type",
          value: parkingType
        },
        {
          type: "parkingSpaces",
          name: "Parking Spaces",
          value: parkingSpaces
        },
        {
          type: "ac",
          name: "Air Conditioning",
          value: airCond
        }
      ];
    }
  },
  mounted() {
    this.dialogOpen = this.dialog;
  },
  methods: {
    onClickOutside() {
      this.$emit("modal-close");
    },
    onMainImageClick() {
      this.propertyImageViewOverlayOpen = true;
    },
    onOverlayClosed() {
      this.propertyImageViewOverlayOpen = false;
    },
    onClose() {
      this.$emit("modal-close");
    }
  }
};
</script>

<style lang="scss" scoped>
// .dialog-content {
//   background: none;
//   position: relative;
// }

// .close-btn {
//   position: absolute;
//   top: 0;
//   right: -60px;
//   width: 23px !important;
//   height: 23px !important;
//   background: none !important;
//   color: #fff;
// }

// .close-icon {
//   font-size: 32px;
// }

// ::v-deep {
//   .v-dialog {
//     padding: 40px;
//     box-shadow: none;
//     overflow: scroll;
//     -ms-overflow-style: none;

//     &::-webkit-scrollbar {
//       display: none;
//     }
//   }

//   .v-window {
//     background: none;
//   }
// }
</style>
