<template>
  <div class="compare-image-row-container d-flex">
    <div class="item-wrapper" />
    <v-row>
      <v-col v-for="(item, index) in imageData" :key="index" cols="12" sm="3">
        <div>
          <compare-image-row-image-item
            :images="item.images"
            :selected="item.selected"
            :order="index"
            @remove-item="onItemRemoved(index)"
          />
          <div class="address-info d-flex align-center justify-start">
            <address-icon :type="getType(index)" />
            <span class="address text-left">
              {{ getStreetAddress(item.address) }}
            </span>
          </div>
          <v-btn
            v-if="index !== 0"
            depressed
            class="discard-btn"
            @click="onDiscardClick(index, item)"
          >
            Discard
          </v-btn>
        </div>
      </v-col>

      <v-col cols="12" sm="3">
        <div
          v-for="rIndex in emptyCompsLength"
          :key="`empty-${rIndex}`"
          class="item-wrapper"
        >
          <add-comp-button
            @add-next-comp="onAddNextComp"
            @select-comp-modal-open="onSelectCompModalOpened"
          />
        </div>
      </v-col>
    </v-row>

    <discard-comp-modal
      v-if="showDiscardCompModal"
      :dialog="showDiscardCompModal"
      :address="selectedAdress"
      @cancel-discard-comp="onDiscardCompCancelled"
      @confirm-discard-comp="onDiscardCompConfirmed"
    />
    <select-comp-modal
      :dialog="showSelectCompModal"
      @select-comp-modal-close="onSelectCompModalClosed"
    />
    <loader :loading="loading" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import Loader from "@/components/Shared/Loader";
import AddCompButton from "@/components/AddCompButton";
import AddressIcon from "@/components/AddressIcon";
import CompareImageRowImageItem from "@/components/CompareImageRowImageItem";
import DiscardCompModal from "@/components/DiscardCompModal";
import SelectCompModal from "@/components/SelectCompModal";

export default {
  name: "CompareImageRow",
  components: {
    Loader,
    AddCompButton,
    AddressIcon,
    CompareImageRowImageItem,
    DiscardCompModal,
    SelectCompModal
  },
  props: {
    imageData: Array
  },
  data: () => ({
    showSelectCompModal: false,
    showDiscardCompModal: false,
    selectedCompIndex: 0,
    selectedAdress: "",
    loading: false
  }),
  mounted() {
    //
  },
  computed: {
    ...mapGetters("homesStore", ["suggestedComps", "compareComps", "comps"]),
    emptyCompsLength() {
      if (this.imageData.length >= 4) {
        return 0;
      }
      return 4 - this.imageData.length;
    }
  },
  methods: {
    getType(index) {
      return index === 0 ? "Selected" : "Suggested";
    },
    getClasses(selected) {
      let classes = "compare-image";
      if (selected) {
        classes += " compare-image--selected";
      }
      return classes;
    },
    getStreetAddress(address) {
      return (address && address.split(",")[0]) || "";
    },
    removeCompareCompItem(compareIndex) {
      const compareProperties = _.cloneDeep(this.comps);
      const index = _.findIndex(compareProperties, cItem =>
        _.isEqual(cItem, this.compareComps[compareIndex - 1])
      );
      if (index >= 0) {
        compareProperties[index].compare = false;
        this.$store.dispatch("homesStore/setComps", compareProperties);
      }
    },
    onDiscardClick(index, item) {
      this.selectedCompIndex = index;
      this.selectedAdress = item.address || "";
      this.showDiscardCompModal = true;
    },
    onItemRemoved(index) {
      this.removeCompareCompItem(index);
    },
    onDiscardCompCancelled() {
      this.showDiscardCompModal = false;
    },
    async onDiscardCompConfirmed() {
      this.showDiscardCompModal = false;

      const compareProperties = _.cloneDeep(this.comps);
      const index = _.findIndex(compareProperties, cItem =>
        _.isEqual(cItem, this.compareComps[this.selectedCompIndex - 1])
      );
      if (index >= 0) {
        compareProperties[index].compare = false;
        compareProperties[index].suggestedDiscard = true;
        this.$store.dispatch("homesStore/setComps", compareProperties);
        this.$emit("comp-remove");
      }
    },
    onSelectCompModalOpened() {
      this.showSelectCompModal = true;
    },
    onSelectCompModalClosed() {
      this.showSelectCompModal = false;
    },
    onAddNextComp() {
      // Get next comp that has not been viewed already
      // and is further down the list than the furthest comp selected for comparison
      const compareCompIds = this.compareComps.map(comp => comp.iD);
      const highestId = Math.max(...compareCompIds);
      let uncomparedComps = this.suggestedComps.filter(
        comp => !comp.compare && !comp.alreadyViewed && comp.iD > highestId
      );

      // If no comps exist that have not been viewed and are further down the list
      // than the furthest comp selected for comparison, look for comp earler in the list
      if (!uncomparedComps.length) {
        uncomparedComps = this.suggestedComps.filter(
          comp => !comp.compare && !comp.alreadyViewed
        );
      }

      // If true, all comps have been viewed for comparison
      if (!uncomparedComps.length) return;

      const nextComp = uncomparedComps[0];
      const updatedCompareComps = [...this.compareComps, nextComp];
      if (updatedCompareComps.length <= 3) {
        const compareProperties = _.cloneDeep(this.comps);
        const index = _.findIndex(compareProperties, cItem =>
          _.isEqual(cItem, nextComp)
        );
        compareProperties[index].compare = true;
        compareProperties[index].alreadyViewed = true;
        this.$store.dispatch("homesStore/setComps", compareProperties);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.compare-image-row-container {
  width: 100%;

  .item-wrapper {
    width: 20%;
  }

  .address {
    font-family: "Helvetica Neue Bold";
    font-size: 16px;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .address-info {
    margin-top: 15px;
  }

  .discard-btn {
    width: 90px;
    height: 34px;
    border: solid 1px #04b6da;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #04b6da;
    text-transform: none;
    margin-top: 20px;
  }
}
</style>
