<template>
  <section class="hero-section">
    <v-container style="max-width: 1200px">
      <v-row>
        <v-col>
          <div>
            <v-img :src="logoImage" max-width="900px" />
            <div class="pl-6 my-4 text-h6 text-md-h4 white--text">
              Find out how much that home is really worth.
            </div>
          </div>
          <div>
            <HomeSearch height="60" />
            <div class="pl-6 mt-4 text-h7 text-md-h5 white--text">
              Get the most accurate property valuation available in less than a
              minute.
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-if="!isAuthenticated"
      v-model="emailDialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Please enter your email to continue.
        </v-card-title>

        <v-form ref="qrEmailForm">
          <v-card-text>
            It only takes a moment to go through the free, no obligations, cash
            offer process.
            <v-text-field
              label="Email"
              solo
              v-model="email"
              :rules="emailRules"
              :error-messages="emailError"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="submitQrCodeUserEmail">
              submit
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmEmailDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Please confirm your email to continue.
        </v-card-title>
        <v-card-text>
          An email has been sent to the account that you provided. Please click
          the verification link to proceed.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="searchPromptDialog" max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Continue to search
        </v-card-title>
        <v-card-text>
          Please enter the address of the single family home you wish to receive
          your no obligations instant cash offer for.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="searchPromptDialog = false"
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HomeSearch from "@/components/Shared/HomeSearch";
export default {
  components: {
    HomeSearch
  },
  data() {
    return {
      error: {},
      emailDialog: true,
      confirmEmailDialog: false,
      searchPromptDialog: false,
      email: "",
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ]
    };
  },
  computed: {
    logoImage() {
      return "/img/images/ehomefacts-logo.svg";
    },
    emailError() {
      return this.error.email || "";
    },
    ...mapGetters("auth", ["isAuthenticated"])
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.$http.post("checkqrcode", {
        id: this.$route.params.id
      });
    }

    if (this.$route.params.token) {
      const emailVerified = await this.$http.post("verify_email", {
        token: this.$route.params.token
      });

      await this.login({
        username: emailVerified.data.username,
        password: "NEVV6M&d"
      });

      this.searchPromptDialog = true;
    }
  },
  methods: {
    ...mapActions("auth", ["login"]),
    async submitQrCodeUserEmail() {
      this.error = {};
      if (!this.$refs.qrEmailForm.validate()) return;

      try {
        await this.$http.post("qrcodeuser", {
          email: this.email
        });

        await this.$http.post("checkqrcode", {
          id: this.$route.params.id,
          email: this.email
        });

        this.emailDialog = false;
        this.confirmEmailDialog = true;
      } catch (error) {
        this.error = error.response.data;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-section {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/images/home3.jpg");
  background-size: cover;
  background-position: center;
}

hr {
  background: linear-gradient(
    273deg,
    var(--v-primary-base),
    var(--v-secondary-base)
  );
  height: 5px;
}
</style>
