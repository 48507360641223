<template>
  <v-container class="pb-12">
    <v-row>
      <v-col>
        <h1>Privacy Policy</h1>
        <p>eHome Facts takes your privacy very seriously.</p>
        <p>
          As used in this Privacy Policy, the terms “eHome Facts,” “we” and “us”
          refer to eHome Facts, LLC, and its subsidiaries and affiliates.
        </p>
        <p>
          Please read this privacy policy carefully as it contains important
          information on who we are, how and why we collect, store, use, and
          share your personal information. It also explains your rights in
          relation to your personal information and how to contact us or
          supervisory authorities in the event you have a complaint.
        </p>
        <p>
          We collect, use and are responsible for certain personal information
          about you. When we do so we are subject to various laws in the United
          States and we are responsible as “controller” of that personal
          information for the purposes of those laws.
        </p>

        <p>
          <strong>
            BY ACCESSING OR USING OUR PLATFORM OR COMMUNICATING WITH US OUTSIDE
            OF THE PLATFORM, YOU ARE ACCEPTING AND CONSENTING TO THE PRACTICES
            DESCRIBED IN THIS PRIVACY POLICY, WHICH MAY BE UPDATED AND AMENDED
            FROM TIME TO TIME. IF YOU DO NOT AGREE TO THE TERMS OF THIS PRIVACY
            POLICY, YOU MUST NOT ACCESS OR USE OUR PLATFROM OR COMMUNICATE WITH
            US.
          </strong>
        </p>

        <ol>
          <li>
            <strong>Personal Information We Collect About You.</strong> We may
            collect and use the following personal information that identifies,
            relates to, describes, is reasonable capable of being associated
            with, or could reasonably be linked, directly or indirectly, with a
            particular consumer or household:
          </li>

          <table>
            <tr>
              <th>Categories of Personal Information</th>
              <th>Specific Types of Personal Information Collected</th>
            </tr>
            <tr>
              <td>
                Identifiers (e.g., a real name, alias, postal address, unique
                personal identifier, online identifier, Internet Protocol
                address, email address, account name, social security number,
                driver’s license number, passport number, or other similar
                identifiers)
              </td>
              <td>
                <ul>
                  <li>
                    Contact information, including name, physical address, email
                    address, etc.
                  </li>
                  <li>IP address</li>
                  <li>Account log-in details</li>
                  <li>Social security number</li>
                  <li>Driver’s license number</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Information that identifies, relates to, describes, or is
                capable of being associated with, a particular individual,
                including, but not limited to, his or her name, signature,
                social security number, physical characteristics or description,
                address, telephone number, passport number, driver’s license or
                state identification card number, insurance policy number,
                education, employment, employment history, bank account number,
                credit card number, debit card number, or any other financial
                information, medical information, or health insurance
                information.
              </td>
              <td>
                <ul>
                  <li>
                    Financial information and billing information associated
                    with a transaction
                  </li>
                  <li>Social Security Number</li>
                  <li>Credit information</li>
                  <li>Banking information</li>
                  <li>Physical Address</li>
                  <li>Telephone number</li>
                  <li>Driver’s license or state identification card number</li>
                  <li>Citizen status (for tax calculation purposes);</li>
                  <li>Marital Status (for tax calculation purposes);</li>
                  <li>
                    Identifiers unique to you, your device, or your account(s),
                    such as device IDs, advertising IDs, and other similar
                    static ID numbers such as those from apps or accounts you
                    use .
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Commercial information (e.g., records of personal property,
                products or services purchased, obtained, or considered, or
                other purchasing or consuming histories or tendencies)
              </td>
              <td>
                <ul>
                  <li>Public record data of real property</li>
                  <li>Tax assessments of your property;</li>
                  <li>Numbers of bedrooms and bathrooms in your home;</li>
                  <li>
                    Multiple Listing Service (MLS) information about your home
                    or the home(s) you are interested in buying;
                  </li>
                  <li>Sales prices of homes similar to your home;</li>
                  <li>Prequalification letters from financial institutions;</li>
                  <li>
                    Construction or purchase contracts;
                  </li>
                  <li>
                    Information contained in the deed for your home, such as
                    name, address, liens, encumbrances, and title.
                  </li>
                  <li>
                    Information regarding the condition of the real property and
                    any modifications or repairs to the real property.
                  </li>
                  <li>Permits issued for remodels and additions;</li>
                  <li>The year your home was built.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Geolocation data</td>
              <td>
                <ul>
                  <li>Address of real property</li>
                  <li>
                    Your GPS location if you provide such access through your
                    device settings.
                  </li>
                  <li>
                    The school district and public transportation available near
                    your property;
                  </li>
                  <li>
                    Whether your property has access to features like a beach,
                    boat dock, or parking garage;
                  </li>
                  <li>
                    Whether your home is in an earthquake or flood zone or has
                    any other condition that would effect its market value
                  </li>
                  <li>
                    Date and time that a eHome Fact’s property is unlocked and
                    accessed.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                Internet or other electronic network activity information (e.g.,
                browsing history, search history, and information regarding a
                consumer’s interaction with an Internet Web site, application,
                or advertisement)
              </td>
              <td>
                <ul>
                  <li>
                    Device attributes such as operating system, hardware and
                    software versions, battery level, signal strength,
                    bandwidth, available storage space, browser type, app and
                    file names and types, and plugins;
                  </li>
                  <li>
                    Information about operations and behaviors performed on the
                    devise, such as mouse movements (which can help distinguish
                    humans from bots) or whether a window is in the foreground
                    or background;
                  </li>
                  <li>
                    Network and connection information such as the name of your
                    mobile operator or ISP, language, time zone, mobile phone
                    number, and IP address;
                  </li>
                  <li>
                    Data from cookies, tags, pixels, web beacons, and log files
                    stored on your device, including cookie IDs, page views, how
                    often you visit our website or mobile application, and links
                    you click on.
                  </li>
                  <li>
                    Mobile device data such as a unique identification number
                    associated with your device, your device type and
                    manufacturer, your mobile carrier, subscriber status,
                    payment method, phone number, caller ID data, and depending
                    on your mobile device settings, your location information,
                    including GPS coordinates.
                  </li>
                </ul>
              </td>
            </tr>
          </table>

          <p>
            This personal information is required to provide services to you. If
            you do not provide personal information we ask for, it may delay or
            prevent us from providing services to you.
          </p>

          <li>
            <strong>How Your Personal Information is Collected.</strong> We
            collect most of this personal information directly from you—in
            person, by telephone, text or email and/or via our website and apps.
            However, we may also collect information:
            <ul>
              <li>
                From publicly accessible sources (e.g., property records);
              </li>
              <li>
                Directly from a third party (e.g., sanctions screening
                providers, credit reporting agencies, or customer due diligence
                providers);
              </li>
              <li>From a third party with your consent (e.g., your bank);</li>
              <li>From cookies on our website</li>
              <li>
                Via our IT systems, including:
                <ul>
                  <li>Door entry systems and reception logs</li>
                  <li>
                    Automated monitoring of our websites and other technical
                    systems, such as our computer networks and connections, CCTV
                    and access control systems, communications systems, email
                    and instant messaging systems;
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <strong>How and Why We Use Your Personal Information.</strong> Under
            data protection law, we can only use your personal information if we
            have a proper reason for doing so, e.g.,
            <ul>
              <li>To comply with our legal and regulatory obligations;</li>
              <li>
                For the performance of our contract with you or to take steps at
                your request before entering into a contract;
              </li>
              <li>
                For our legitimate interests or those of a third party; or
              </li>
              <li>Where you have given consent.</li>
            </ul>
            <p>
              A legitimate interest is when we have a business or commercial
              reason to use your information, so long as this is not overridden
              by your own rights and interests.
            </p>
            <p>
              The table below explains what we use (process) your personal
              information for and our reasons for doing so:
            </p>

            <table>
              <tr>
                <th>What we use your personal information for</th>
                <th>Our reasons</th>
              </tr>
              <tr>
                <td>To provide products and services to you</td>
                <td>
                  <ul>
                    <li>
                      For the performance of our contract with you or to take
                      steps at your request before entering into a contract
                    </li>
                    <li>
                      Enable you to submit request for offers, make offers, and
                      use our other related services
                    </li>
                    <li>
                      Process real estate transactions and mortgage loan
                      transactions
                    </li>
                    <li>
                      Operate our website and provide services to our users,
                      including for payment processing, website administration,
                      internal operations, troubleshooting, data analysis,
                      testing, research, statistical and survey purposes
                    </li>
                    <li>Manage access to our website</li>
                    <li>
                      Send you information that helps you to use our website
                    </li>
                    <li>
                      Contact you directly about offer requests, offers, or sale
                      or purchase transactions
                    </li>
                    <li>
                      Notify you about changes to our website or products and
                      services.
                    </li>
                    <li>
                      In any other way that we describe when you provide the
                      information
                    </li>
                    <li>
                      For any other purposed for which you may provide consent
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>To prevent and detect fraud against you or eHome Facts</td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to minimize fraud that could be damaging for us and
                      for you
                    </li>
                    <li>
                      Protect the integrity and maintain the security of our
                      Website, including secured areas of our Website.
                    </li>
                    <li>
                      Protect the integrity and maintain the security of any
                      Properties that are listed through eHome Facts.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Conducting checks to identify our customers and verify their
                    identity
                  </p>
                  <p>
                    Screening for financial and other sanctions or embargoes
                  </p>
                  <p>
                    Other processing necessary to comply with professional,
                    legal and regulatory obligations that apply to our business,
                    e.g. under health and safety regulation or rules issued by
                    our professional regulator
                  </p>
                </td>
                <td>
                  <ul>
                    <li>
                      To comply with our legal and regulatory obligations;
                    </li>
                    <li>
                      For our legitimate interest or those of a third party to
                      minimize fraud that could be damaging for us and for you.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Gathering and providing information required by or relating to
                  audits, enquiries or investigations by regulatory bodies
                </td>
                <td>
                  <ul>
                    <li>
                      To comply with our legal and regulatory obligations
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Ensuring business policies are adhered to, e.g. policies
                  covering security and internet use
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to make sure we are following our own internal
                      procedures so we can deliver the best service to you
                    </li>
                    <li>
                      Create reports for our affiliates, licensors, and service
                      providers that may include aggregate information about use
                      of various aspects of the website
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Operational reasons, such as improving efficiency, training,
                  and quality control
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to be as efficient as we can so we can deliver the
                      best service for you at the best price
                    </li>
                    <li>
                      Operate our website and provide services to our users,
                      including for payment processing, website administration,
                      internal operations, troubleshooting, data analysis,
                      testing, research, statistical and survey purposes
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Ensuring the confidentiality of commercially sensitive
                  information
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to protect trade secrets and other commercially
                      valuable information
                    </li>
                    <li>To comply with our legal and regulatory obligations</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Statistical analysis to help us manage our business, e.g. in
                  relation to our financial performance, customer base, product
                  range or other efficiency measures
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to be as efficient as we can so we can deliver the
                      best service for you at the best price
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Preventing unauthorized access and modifications to systems
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to prevent and detect criminal activity that could be
                      damaging for us and for you
                    </li>
                    <li>To comply with our legal and regulatory obligations</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Updating and enhancing customer records</td>
                <td>
                  <ul>
                    <li>
                      For the performance of our contract with you or to take
                      steps at your request before entering into a contract
                    </li>
                    <li>
                      To comply with our legal and regulatory obligations.
                    </li>
                    <li>
                      For our legitimate interests or those of a third party,
                      e.g. making sure that we can keep in touch with our
                      customers about updates regarding, particular properties
                      and other offers via email and text message; Send you
                      information that helps you to use our website; Contact you
                      directly about offer requests, offers, or sale or purchase
                      transactions; Notify you about changes to our website or
                      products and services.
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Statutory returns</td>
                <td>
                  <ul>
                    <li>
                      To comply with our legal and regulatory obligations
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Ensuring safe working practices, staff administration and
                  assessments
                </td>
                <td>
                  <ul>
                    <li>To comply with our legal and regulatory obligations</li>
                    <li>
                      For our legitimate interests or those of a third party,
                      e.g. to make sure we are following our own internal
                      procedures and working efficiently so we can deliver the
                      best service to you
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Marketing our services and those of selected third parties to:
                  <ul>
                    <li>existing and former customers;</li>
                    <li>
                      third parties who have previously expressed an interest in
                      our services;
                    </li>
                    <li>
                      third parties with whom we have had no previous dealings.
                    </li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or those of a third party,
                      i.e. to promote our business to existing and former
                      customers
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  External audits and quality checks, e.g. for ISO or Investors
                  in People accreditation and the audit of our accounts
                </td>
                <td>
                  <ul>
                    <li>
                      For our legitimate interests or a those of a third party,
                      i.e. to maintain our accreditations so we can demonstrate
                      we operate at the highest standards
                    </li>
                    <li>To comply with our legal and regulatory obligations</li>
                  </ul>
                </td>
              </tr>
            </table>

            <p>
              The above table does not apply to special category personal
              information, which we will only process with your explicit
              consent.
            </p>
          </li>
          <li>
            <p>
              <strong>Promotional Communications.</strong> We may use your
              personal information to send you updates (by email, text message,
              telephone, or post) about our products and/or services, including
              exclusive offers, promotions or new products and/or services.
            </p>
            <p>
              We have a legitimate interest in processing your personal
              information for promotional purposes (see above “How and why we
              use your personal information”). This means we do not usually need
              your consent to send you promotional communications. However,
              where consent is needed, we will ask for this consent separately
              and clearly.
            </p>
            <p>
              We will always treat your personal information with the utmost
              respect and never sell or share it with other organizations
              outside the eHome Facts group for marketing purposes.
            </p>
            <p>
              You have the right to opt out of receiving promotional
              communications at any time by:
            </p>
            <ul>
              <li>Contacting us at info@ehomefacts.com.</li>
              <li>
                Using the “unsubscribe” link in emails or “STOP” number in texts
              </li>
            </ul>
            <p>
              We may ask you to confirm or update your marketing preferences if
              you instruct us to provide further products and/or services in the
              future, or if there are changes in the law, regulation, or the
              structure of our business.
            </p>
          </li>
          <li>
            <strong>Who We Share Your Personal Information With.</strong> We
            routinely share personal information with:
            <ul>
              <li>
                Our affiliates, including companies within the eHome Facts group
              </li>
              <li>
                Service providers we use to help deliver our products and/or
                services to you, such as payment service providers, delivery
                companies, technology and infrastructure providers, digital
                communications services, commercial data and market research
                analysts, consumer report providers, auditors and accountants,
                physical site security providers, IT security and bug-fix
                partners, customer service providers, and other providers who
                perform services for and on our behalf for the sole benefit of
                us and our customers;
              </li>
              <li>
                <strong>
                  Other third parties we use to help us run our business, such
                  as title and closing companies, marketing agencies or website
                  hosts;
                </strong>
              </li>
              <li>
                Third parties approved by you, including social media sites you
                choose to link your account to, third-party payment providers,
                financing providers, potential home buyers, escrow, closing
                attorney, title, homeowners’ insurance, renovation companies;
              </li>
              <li>Credit reporting agencies;</li>
              <li>Our insurers and brokers;</li>
              <li>Our bank[s];</li>
              <li>With Law Enforcement, Courts, or Government Agencies.</li>
            </ul>
            <p>
              We only allow our service providers to handle your personal
              information if we are satisfied they take appropriate measures to
              protect your personal information. We also impose contractual
              obligations on service providers relating to ensure they can only
              use your personal information to provide services to us and to
              you. We may also share personal information with external
              auditors, e.g. in relation to ISO or Investors in People
              accreditation and the audit of our accounts.
            </p>
            <p>
              We may disclose and exchange information with law enforcement
              agencies and regulatory bodies to comply with our legal and
              regulatory obligations.
            </p>
            <p>
              We may also need to share some personal information with other
              parties, such as potential buyers of some or all of our business
              or during a re-structuring. We will typically anonymize
              information, but this may not always be possible. The recipient of
              the information will be bound by confidentiality obligations.
            </p>
            <p>
              We will not share your personal information with any other third
              party.
            </p>
          </li>
          <li>
            <p>
              <strong
                >Personal Information We Sold or Disclosed for a Business
                Purpose.</strong
              >
              In the preceding 12 months, we have sold to one or more third
              parties the following categories of personal information that
              identifies, relates to, describes, is capable of being associated
              with, or could reasonably be linked, directly or indirectly, with
              a particular consumer or household:
            </p>
            <ul>
              <li>
                Identifiers (e.g., a real name, alias, postal address, unique
                personal identifier, online identifier, Internet Protocol
                address, email address, account name, social security number,
                driver’s license number, passport number, or other similar
                identifiers);
              </li>
              <li>
                Information that identifies, relates to, describes, or is
                capable of being associated with, a particular individual,
                including, but not limited to, his or her name, signature,
                social security number, physical characteristics or description,
                address, telephone number, passport number, driver’s license or
                state identification card number, insurance policy number,
                education, employment, employment history, bank account number,
                credit card number, debit card number, or any other financial
                information, medical information, or health insurance
                information;
              </li>
              <li>
                Characteristics of protected classifications under California or
                federal law;
              </li>
              <li>
                Commercial information (e.g., records of personal property,
                products or services purchased, obtained, or considered, or
                other purchasing or consuming histories or tendencies);
              </li>
              <li>
                Internet or other electronic network activity information (e.g.,
                browsing history, search history, and information regarding a
                consumer’s interaction with an Internet Web site, application,
                or advertisement);
              </li>
              <li>Geolocation data;</li>
              <li>
                Inferences drawn from any of the information identified above to
                create a profile about a consumer reflecting the consumer’s
                preferences, characteristics, psychological trends,
                predispositions, behavior, attitudes, intelligence, abilities,
                and aptitudes.
              </li>
            </ul>
            <p>
              In the preceding 12 months, we have disclosed for a business
              purpose to one or more third parties the following categories of
              personal information that identifies, relates to, describes, is
              capable of being associated with, or could reasonably be linked,
              directly or indirectly, with a particular consumer or household:
            </p>
            <ul>
              <li>
                Identifiers (e.g., a real name, alias, postal address, unique
                personal identifier, online identifier, Internet Protocol
                address, email address, account name, social security number,
                driver’s license number, passport number, or other similar
                identifiers);
              </li>
              <li>
                Information that identifies, relates to, describes, or is
                capable of being associated with, a particular individual,
                including, but not limited to, his or her name, signature,
                social security number, physical characteristics or description,
                address, telephone number, passport number, driver’s license or
                state identification card number, insurance policy number,
                education, employment, employment history, bank account number,
                credit card number, debit card number, or any other financial
                information, medical information, or health insurance
                information;
              </li>
              <li>
                Characteristics of protected classifications under California or
                federal law;
              </li>
              <li>
                Commercial information (e.g., records of personal property,
                products or services purchased, obtained, or considered, or
                other purchasing or consuming histories or tendencies);
              </li>
              <li>
                Internet or other electronic network activity information (e.g.,
                browsing history, search history, and information regarding a
                consumer’s interaction with an Internet Web site, application,
                or advertisement);
              </li>
              <li>Geolocation data;</li>
              <li>
                Inferences drawn from any of the information identified above to
                create a profile about a consumer reflecting the consumer’s
                preferences, characteristics, psychological trends,
                predispositions, behavior, attitudes, intelligence, abilities,
                and aptitudes.
              </li>
            </ul>
          </li>
          <li>
            <strong>Where Your Personal Information is Held.</strong>
            Information may be held at our offices and those of our affiliates,
            third party agencies, service providers, representatives and agents
            as described above (see above: “Who We Share Your Personal
            Information with”).
          </li>
          <li>
            <strong>How Long Your Personal Information Will Be Kept.</strong> We
            will keep your personal information while you have an account with
            us or while we are providing products and/or services to you.
            Thereafter, we will keep your personal information for as long as is
            necessary:
            <ul>
              <li>
                To respond to any questions, complaints or claims made by you or
                on your behalf;
              </li>
              <li>To show that we treated you fairly; or</li>
              <li>To keep records required by law.</li>
            </ul>
            <p>
              We will not retain your personal information for longer than
              necessary for the purposes set out in this policy. Different
              retention periods apply for different types of personal
              information.
            </p>
          </li>
          <li>
            <strong>Privacy Rights for California Residents.</strong> You have
            the right under the California Consumer Privacy Act of 2018 (CCPA)
            and certain other privacy and data protection laws, as applicable,
            to exercise free of charge:
          </li>
          <table>
            <tr>
              <td>Disclosure of Personal Information We Collect About You</td>
              <td>
                You have the right to know:
                <ul>
                  <li>
                    The categories of personal information we have collected
                    about you;
                  </li>
                  <li>
                    The categories of sources from which the personal
                    information is collected;
                  </li>
                  <li>
                    Our business or commercial purpose for collecting or selling
                    personal information;
                  </li>
                  <li>
                    The categories of third parties with whom we share personal
                    information, if any; and
                  </li>
                  <li>
                    The specific pieces of personal information we have
                    collected about you.
                  </li>
                  <li>Please note that we are not required to:</li>
                  <li>
                    Retain any personal information about you that was collected
                    for a single one-time transaction if, in the ordinary course
                    of business, that information about you is not retained;
                  </li>
                  <li>
                    Reidentify or otherwise link any data that, in the ordinary
                    course of business, is not maintained in a manner that would
                    be considered personal information; or
                  </li>
                  <li>
                    Provide the personal information to you more than twice in a
                    12-month period.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Personal Information Sold or Used for a Business Purpose</td>
              <td>
                <p>
                  In connection with any personal information we may sell or
                  disclose to a third party for a business purpose, you have the
                  right to know:
                </p>
                <p>
                  The categories of personal information about you that we sold
                  and the categories of third parties to whom the personal
                  information was sold; and
                </p>
                <p>
                  The categories of personal information that we disclosed about
                  you for a business purpose.
                </p>
                <p>
                  You have the right under the California Consumer Privacy Act
                  of 2018 (CCPA) and certain other privacy and data protection
                  laws, as applicable, to opt-out of the sale [or disclosure] of
                  your personal information. If you exercise your right to
                  opt-out of the sale [or disclosure] of your personal
                  information, we will refrain from selling your personal
                  information, unless you subsequently provide express
                  authorization for the sale of your personal information. To
                  opt-out of the sale or disclosure of your personal
                  information, follow the options listed in Section 12. How to
                  Exercise Your Rights.
                </p>
              </td>
            </tr>
            <tr>
              <td>Right to Deletion</td>
              <td>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
                <ul>
                  <li>
                    Delete your personal information from our records; and
                  </li>
                  <li>
                    Direct any service providers to delete your personal
                    information from their records.
                  </li>
                  <li>
                    Please note that we may not delete your personal information
                    if it is necessary to:
                  </li>
                  <li>
                    Complete the transaction for which the personal information
                    was collected, fulfill the terms of a written warranty or
                    product recall conducted in accordance with federal law,
                    provide a good or service requested by you, or reasonably
                    anticipated within the context of our ongoing business
                    relationship with you, or otherwise perform a contract
                    between you and us;
                  </li>
                  <li>
                    Detect security incidents, protect against malicious,
                    deceptive, fraudulent, or illegal activity; or prosecute
                    those responsible for that activity;
                  </li>
                  <li>
                    Debug to identify and repair errors that impair existing
                    intended functionality;
                  </li>
                  <li>
                    Exercise free speech, ensure the right of another consumer
                    to exercise his or her right of free speech, or exercise
                    another right provided for by law;
                  </li>
                  <li>
                    Comply with the California Electronic Communications Privacy
                    Act;
                  </li>
                  <li>
                    Engage in public or peer-reviewed scientific, historical, or
                    statistical research in the public interest that adheres to
                    all other applicable ethics and privacy laws, when our
                    deletion of the information is likely to render impossible
                    or seriously impair the achievement of such research,
                    provided we have obtained your informed consent;
                  </li>
                  <li>
                    Enable solely internal uses that are reasonably aligned with
                    your expectations based on your relationship with us;
                  </li>
                  <li>Comply with an existing legal obligation; or</li>
                  <li>
                    Otherwise use your personal information, internally, in a
                    lawful manner that is compatible with the context in which
                    you provided the information.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Protection Against Discrimination</td>
              <td>
                <p>
                  You have the right to not be discriminated against by us
                  because you exercised any of your rights under the CCPA. This
                  means we cannot, among other things:
                </p>
                <ul>
                  <li>Deny goods or services to you;</li>
                  <li>
                    Charge different prices or rates for goods or services,
                    including through the use of discounts or other benefits or
                    imposing penalties;
                  </li>
                  <li>
                    Provide a different level or quality of goods or services to
                    you; or
                  </li>
                  <li>
                    Suggest that you will receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </li>
                </ul>
                <p>
                  Please note that we may charge a different price or rate or
                  provide a different level or quality of goods and/or services
                  to you, if that difference is reasonably related to the value
                  provided to our business by your personal information.
                </p>
              </td>
            </tr>
          </table>

          <li>
            <p>
              <strong>Privacy Rights for Nevada Residents.</strong> You have the
              right to direct us not to sell certain personally identifiable
              information that we collect about you on our Websites or other
              online services by emailing us at: info@ehomefacts.com. This
              information includes: your first name or first initial and last
              name in combination with your social security number; driver’s
              license number, driver authorization card number or identification
              card number; account number, credit card number or debit card
              number, in combination with any required security code, access
              code or password that would permit access to your financial
              account; medical identification number or a health insurance
              identification number; user name, unique identifier or electronic
              mail address in combination with a password, access code or
              security question and answer that would permit access to an online
              account.
            </p>
            <p>
              Additionally you may review and request that we change the
              personally identifiable information that we collect about you on
              our websites or other online services by emails us at:
              info@ehomefacts.com.
            </p>
            <p>
              Our services providers and other third parties may collect
              personally identifiable information about your online activities
              over time and across different internet websites or online
              services when you use our Website.
            </p>
          </li>
          <li>
            <strong>Keeping Your Personal Information Secure.</strong> We have
            appropriate security measures in place to prevent personal
            information from being accidentally lost or used or accessed in an
            unauthorized way. We limit access to your personal information to
            those who have a genuine business need to access it. Those
            processing your information will do so only in an authorized manner
            and are subject to a duty of confidentiality. We also have
            procedures in place to deal with any suspected data security breach.
            We will notify you and any applicable regulator of a suspected data
            security breach where we are legally required to do so.
          </li>
          <li>
            <strong>How to Exercise Your Rights.</strong> If you would like to
            exercise any of your rights as described in this Privacy Policy,
            please:
            <ul>
              <li>
                Complete a data subject request form and send your request to
                info@ehomefacts.com
              </li>
              <li>Call us, toll-free, at 888-369-1893</li>
              <li>Email us at info@ehomefacts.com</li>
            </ul>
            <p>
              Please note that you may only make a CCPA-related data access or
              data portability disclosure request twice within a 12-month
              period.
            </p>
            <p>
              If you choose to contact directly by email and/or phone, you will
              need to provide us with:
            </p>
            <ul>
              <li>
                Enough information to identify you (e.g., your full name,
                address and customer or matter reference number);
              </li>
              <li>
                Proof of your identity and address (e.g., a copy of your driving
                license or passport and a recent utility or credit card bill);
                and
              </li>
              <li>
                A description of what right you want to exercise and the
                information to which your request relates.
              </li>
            </ul>
            <p>
              We are not obligated to make a data access or data portability
              disclosure if we cannot verify that the person making the request
              is the person about whom we collected information, or is someone
              authorized to act on such person’s behalf.
            </p>
            <p>
              Any personal information we collect from you to verify your
              identity in connection with you request will be used solely for
              the purposes of verification.
            </p>
          </li>
          <li>
            <strong>Changes to This Privacy Notice.</strong> This privacy notice
            was published on December 13, 2021 and last updated on December13,
            2021. We may change this privacy notice from time to time–when we
            do, we will inform you via our website.
          </li>
          <li>
            <strong>How to Contact Us.</strong> Please contact us by post, email
            or telephone if you have any questions about this privacy policy or
            the information we hold about you. Our contact details are shown
            below:
          </li>
          <table>
            <tr>
              <th>
                Our contact details
              </th>
            </tr>
            <tr>
              <td>238 E Davis Blvd, Suite 206 Tampa, FL 33606</td>
            </tr>
            <tr>
              <td>info@ehomefacts.com</td>
            </tr>
            <tr>
              <td>888-369-1893</td>
            </tr>
          </table>

          <li>
            <strong>Do You Need Extra Help?</strong> If you would like this
            notice in another format (for example: audio, large print, braille)
            please contact us (see “How to contact us” above).
          </li>
        </ol>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy"
};
</script>

<style scoped>
table,
td,
th {
  border: 1px solid black;
}

td,
th {
  padding: 1rem;
}

table {
  border-collapse: collapse;
}
</style>
