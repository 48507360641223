import router from "../../routes/router";

const state = () => ({
  user: null,
  token: localStorage.getItem("token") || "",
  tosDate: new Date("2021-09-14T22:39:41.648Z").getTime()
});

const mutations = {
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_USER(state, payload) {
    state.user = payload;
  },
  SET_USER_PLAN(state, payload) {
    state.user = {
      ...state.user,
      plan: payload
    };
  },
  LOGOUT(state) {
    state.user = {};
    state.token = "";
  },
  SET_CASH_OFFER_SUBMITTED(state, payload) {
    state.user.cash_offer_submitted = payload;
  },
  SET_USER_TOS_DATE(state, payload) {
    state.user.tos_date = payload;
  }
};

const actions = {
  async login({ commit }, payload) {
    const { data } = await this._vm.$http.post("login", payload);
    localStorage.setItem("token", data.token);
    this._vm.$http.defaults.headers.common[
      "Authorization"
    ] = `Token ${data.token}`;

    commit("SET_TOKEN", data.token);
    commit("SET_USER", {
      name: data.user,
      is_pro: data.is_pro,
      is_seller: data.is_seller,
      is_qr: data.qr_user,
      cash_offer_submitted: data.cash_offer_submitted,
      tos_date: data.tos_date
    });

    if (
      router.currentRoute.name === "Properties" &&
      data.is_seller &&
      !data.cash_offer_submitted
    ) {
      commit("cashoffer/SET_CASH_OFFER_STATE", true, { root: true });
    }
  },
  async logout({ commit }) {
    localStorage.removeItem("token");
    delete this._vm.$http.defaults.headers.common["Authorization"];
    commit("SET_TOKEN", "");
    commit("SET_USER", null);
  },
  async register(context, payload) {
    const {
      username,
      password,
      password2,
      address,
      email,
      first_name,
      last_name,
      is_pro,
      is_seller,
      is_owner,
      owners,
      street,
      city,
      zip,
      state
    } = payload;

    await this._vm.$http.post("signup", {
      username,
      password,
      password2,
      address,
      email,
      first_name,
      last_name,
      is_pro,
      is_seller,
      is_owner,
      owners,
      street,
      city,
      zip,
      state
    });
  },
  async passwordReset(context, payload) {
    const { password, token } = payload;
    return await this._vm.$http.post("password/confirm/", {
      password,
      token
    });
  },
  async requestPasswordResetEmail(context, email) {
    return await this._vm.$http.post("password/reset_password", {
      email
    });
  },
  async updateTOSAgreement({ commit }) {
    commit("SET_USER_TOS_DATE", new Date().getTime());
    await this._vm.$http.post("update_tos");
  }
};

const getters = {
  userName: state => state.user.name,
  isAuthenticated: state => !!state.token,
  isProUser: state => !!state.user?.is_pro,
  isSeller: state => !!state.user?.is_seller,
  isQrUser: state => !!state.user?.qr_user,
  cashOfferSubmitted: state => state.user.cash_offer_submitted,
  showTOS: state => {
    return (
      !!state.user &&
      Object.keys(state.user).length &&
      (!state.user.tos_date ||
        new Date(state.user.tos_date).getTime() < state.tosDate)
    );
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
