<template>
  <v-dialog
    v-model="dialogOpen"
    width="550px"
    class="discard-comp-modal-container pa-0"
    @click:outside="onClickOutside"
  >
    <v-card>
      <v-card-title>
        Discard Comp
      </v-card-title>
      <v-divider />
      <v-card-text height="200px">
        <p class="sub-title text-left">
          {{ `Are you sure you want to discard \"${address}\"?` }}
        </p>
        <div class="action-row d-flex align-end justify-end">
          <v-btn depressed class="cancel-btn" @click="onCancel">Cancel</v-btn>
          <v-btn depressed class="discard-comp-btn" @click="onDiscard"
            >Discard Comp</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DiscardCompModal",
  components: {
    //
  },
  props: {
    dialog: Boolean,
    address: String
  },
  data: () => ({
    dialogOpen: false
  }),
  mounted() {
    this.dialogOpen = this.dialog;
  },
  methods: {
    onClickOutside() {
      this.$emit("cancel-discard-comp");
    },
    onCancel() {
      this.$emit("cancel-discard-comp");
    },
    onDiscard() {
      this.$emit("confirm-discard-comp");
    }
  }
};
</script>

<style lang="scss" scoped>
.discard-comp-modal-container {
}

::v-deep {
  .cancel-btn {
    width: 80px;
    height: 34px;
    background: none !important;
    border: solid 1px #04b6da;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #04b6da;
    margin-right: 10px;
  }

  .discard-comp-btn {
    width: 130px;
    height: 34px;
    background-color: #04b6da !important;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #ffffff;
  }
}
</style>
