<template>
  <v-dialog v-model="loading" persistent max-width="500">
    <v-card>
      <v-card-text
        v-if="!simpleSearch"
        class="text-center pt-4 d-flex flex-column align-center"
      >
        <p class="loading-text">
          Calculating New ARV...
        </p>
        <SliderLoader />
      </v-card-text>

      <v-card-text
        v-else
        class="text-center pt-4 d-flex flex-column align-center"
      >
        <p class="loading-text mb-0">
          We are compiling the most recent sale price data in your area in order
          to establish your home's After Repair Value.
        </p>
        <SliderLoader />
        <p class="loading-text mb-0">
          In a moment you will be prompted to answer a few short questions
          regarding your home's current condition.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SliderLoader from "@/components/Shared/SliderLoader";

export default {
  name: "Loader",
  components: {
    SliderLoader
  },
  props: {
    loading: Boolean
  },
  computed: {
    loaderImage() {
      return "/img/images/calculating.png";
    },
    simpleSearch() {
      return this.$route.name === "QrToken" || this.$route.name === "Simple";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-card__text {
    .loading-text {
      font-family: "Helvetica Neue Medium";
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: normal;
      color: #5c5e62;
    }

    .loader-image {
      width: 98px;
      margin-top: 24px;
    }
  }
}
</style>
