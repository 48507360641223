<template>
  <v-dialog v-model="show" width="500">
    <v-card class="searchErrorDialog">
      <v-card-title class="error white--text">
        <v-icon color="white" large left>mdi-alert-circle-outline</v-icon>
        Unsupported Property
      </v-card-title>

      <div class="pa-4">
        {{ errorMessage }}
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click.stop="show = false">
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    errorMessage: [String, Error],
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss">
.searchErrorDialog {
  font-family: "Helvetica Neue Medium";
}
</style>
