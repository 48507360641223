<template>
  <div class="logo-container" @click="onLogoClicked">
    <img :src="logoImage" class="logo-img" />
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    dark: Boolean
  },
  computed: {
    whiteLogo() {
      return require("@/assets/logo.png");
    },
    blackLogo() {
      return require("@/assets/logo.png");
    },
    logoImage() {
      return this.dark ? this.whiteLogo : this.blackLogo;
    }
  },
  methods: {
    onLogoClicked() {
      if (this.$route.path !== "/") {
        this.$router.replace("/").catch(err => {
          console.error(err);
        });
      }

      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style lang="scss" scoped>
.logo-container {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo-img {
  height: 45px;
}
</style>
