<template>
  <div class="home-view-container">
    <div class="home-view-inner-wrapper">
      <div class="home-info-section">
        <div class="icon-section">
          <v-icon :color="iconColor">mdi-map-marker</v-icon>
        </div>
        <div class="left-info-section d-flex flex-column align-start">
          <h2 class="address">{{ streetAddress }}</h2>
          <p class="city-info">{{ cityInfo }}</p>
          <p class="home-info">
            <strong>{{ beds }}</strong> Beds |
            <strong>{{ baths }}</strong> Baths |
            <strong>{{ sqFt }}</strong> sqft
          </p>
        </div>
        <div class="right-info-section">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <h2 v-on="on" class="est-arv-column" id="v-step-0">
                {{ `$${estARV}` }}
              </h2>
            </template>
            <span>{{ estRange }}</span>
          </v-tooltip>
          <p class="est-description">
            Est. After Repair Value
          </p>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-switch
                  v-if="isProUser"
                  v-model="priceAdjust"
                  label="Trend Adjust"
                ></v-switch>
              </span>
            </template>
            <div style="max-width: 200px;">
              This new feature was added in order to better adjust the older
              comparable sale prices so as to more accurately reflect the recent
              rapid rises in housing prices. This feature can be disabled by
              toggling the switch to the "off" position.
            </div>
          </v-tooltip>
        </div>
      </div>
      <div class="home-picture-section">
        <home-picture-view
          :propertyDetails="selectedPropertyData"
          :images="images"
          :floorplanImage="floorplanImage"
          :location="location"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import numbersMixin from "@/mixins/numbersMixin";
import HomePictureView from "@/components/HomePictureView";

export default {
  name: "HomeView",
  components: {
    HomePictureView
  },
  mixins: [numbersMixin],
  props: {
    //
  },
  data: () => ({
    selectedPropertyData: {}
  }),
  computed: {
    ...mapGetters("homesStore", [
      "selectedProperty",
      "selectedAddress",
      "suggestedComps"
    ]),
    ...mapGetters("auth", ["isProUser"]),
    priceAdjust: {
      get() {
        return this.$store.state.homesStore.priceAdjust;
      },
      set(value) {
        this.$store.commit("homesStore/SET_PRICE_ADJUST", value);
      }
    },
    iconColor() {
      return "#0086ff";
    },
    location() {
      const { latitude, longitude } = this.selectedPropertyData;
      return {
        lat: latitude,
        lng: longitude
      };
    },
    streetAddress() {
      return (this.selectedAddress && this.selectedAddress.streetAddress) || "";
    },
    cityInfo() {
      const { city, state, zipCode } = this.selectedAddress;
      const { subdivision } = this.selectedProperty;
      const cityAddress = `${city}, ${state} ${zipCode}`;

      return `${cityAddress} | ${subdivision}`;
    },
    beds() {
      return this.selectedPropertyData.beds;
    },
    baths() {
      return this.selectedPropertyData.baths;
    },
    sqFt() {
      return this.selectedPropertyData.livingArea;
    },
    estARV() {
      return `${this.getFormatedEstARV(this.selectedPropertyData.arv) || 0}`;
    },
    estARVValues() {
      return {
        max: (
          _.maxBy(this.suggestedComps, function(property) {
            return property.arv;
          }) || {}
        ).arv,
        min: (
          _.minBy(this.suggestedComps, function(property) {
            return property.arv;
          }) || {}
        ).arv
      };
    },
    estRange() {
      const { min: estMIN, max: estMAX } = this.estARVValues;
      return `$${this.getFormatedEstARV(estMIN) ||
        0} - $${this.getFormatedEstARV(estMAX) || 0}`;
    },
    salePrice() {
      return this.selectedPropertyData.price;
    },
    saleDateStr() {
      return this.selectedPropertyData.saleDateStr;
    },
    images() {
      const { mlsImageUrls } = this.selectedProperty || {};
      return mlsImageUrls || [];
    },
    floorplanImage() {
      const { floorplanURL } = this.selectedProperty || {};
      return floorplanURL || "";
    }
  },
  watch: {
    selectedProperty: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.updateData(newVal);
      },
      deep: true
    }
  },
  mounted() {
    this.updateData(this.selectedProperty);
  },
  methods: {
    updateData(selectedProperty) {
      this.selectedPropertyData = _.cloneDeep(selectedProperty);
    },
    getFormatedEstARV(value) {
      return this.$_numbersMixin_getNumberWithCommas(value);
    }
  }
};
</script>

<style lang="scss">
.home-view-container {
  position: relative;
  width: 100%;
  background: #fff;
  padding-top: 80%;

  .home-view-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px 10px 0 10px;
  }

  .home-info-section {
    display: flex;
  }

  .address {
    font-family: "Helvetica Neue Bold";
    font-size: 20px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #5c5e62;
    text-transform: camelcase;
  }

  .city-info {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .home-info {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #5c5e62;
  }

  .right-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
    padding-right: 10px;
  }

  .est-arv-column {
    font-family: "Helvetica Neue Bold";
    font-size: 20px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #04b6da;
  }

  .est-description {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: right;
    color: #9b9b9b;
  }

  .sold-info {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #5c5e62;
  }

  .home-picture-section {
    height: calc(100% - 95px);
    flex: 1;
  }
}
</style>
