<template>
  <v-container>
    <v-form ref="contactForm" v-model="valid">
      <v-alert type="error" v-if="error">
        Error submitting info, please try again.
      </v-alert>
      <v-row>
        <v-col cols="12">
          <h3>Thank You for Choosing eHomefacts!</h3>
          <p class="subtext" v-if="condition === 'excellent'">
            One of our licensed agents will contact you to discuss the details
            of listing your home.
          </p>
          <p class="subtext" v-else>
            Our agents are putting together your contract as we speak and they
            will email it to the email account you provided when you created
            your account.
          </p>
        </v-col>
        <p class="subtext pl-3">
          For verification purposes, please enter your name and telephone number
          below:
        </p>
        <v-col cols="6">
          <v-text-field
            v-model="firstname"
            :rules="nameRules"
            label="First Name"
            name="firstname"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="lastname"
            :rules="nameRules"
            label="Last Name"
            name="lastname"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <vue-tel-input-vuetify
            :rules="phoneRules"
            defaultCountry="us"
            v-model="phone"
          ></vue-tel-input-vuetify>
        </v-col>
        <v-col cols="12">
          <p class="subtext">
            Please enter the best time of day to for our agents to reach out to
            you in the fields below:
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-center">
          <span class="subtext">Between</span>
          <v-select
            class="d-inline-block mx-2"
            :items="startTimes"
            v-model="startTime"
            :rules="timeRules"
            dense
          ></v-select>
          <span class="subtext">and</span>
          <v-select
            class="d-inline-block mx-2"
            :items="endTimes"
            v-model="endTime"
            :rules="timeRules"
            dense
          ></v-select>
        </v-col>

        <v-col class="d-flex justify-center">
          <v-btn
            v-if="!submitted"
            x-large
            :loading="loading"
            style="background-color: #04b6da"
            dark
            @click="submitContact"
            >Submit Contact Info</v-btn
          >
          <v-alert type="success" v-if="submitted">
            Contact Info Submitted!
          </v-alert>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  props: ["questionData"],
  data: () => ({
    valid: false,
    error: false,
    submitted: false,
    firstname: null,
    lastname: null,
    phone: null,
    startTime: null,
    endTime: null,
    times: [
      "8 AM",
      "9 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      "1 PM",
      "2 PM",
      "3 PM",
      "4 PM",
      "5 PM",
      "6 PM",
      "7 PM",
      "8 PM"
    ],
    nameRules: [v => !!v || "required"],
    phoneRules: [v => !!v || "Phone number is required"],
    timeRules: [v => !!v || "Time is required"],
    loading: false
  }),
  computed: {
    ...mapState("cashoffer", ["cashOffer", "arv", "rehab", "fees"]),
    ...mapGetters("homesStore", ["selectedAddress"]),
    startTimes() {
      if (!this.endTime) return this.times;
      const index = this.times.indexOf(this.endTime);
      return this.times.slice(0, index + 1);
    },
    endTimes() {
      if (!this.startTime) return this.times;
      const index = this.times.indexOf(this.startTime);
      return this.times.slice(index);
    },
    condition() {
      return this.questionData.find(q => q.name === "condition").answer;
    }
  },
  methods: {
    ...mapMutations("auth", ["SET_CASH_OFFER_SUBMITTED"]),
    async submitContact() {
      if (!this.$refs.contactForm.validate()) return;
      this.loading = true;

      try {
        const res = await this.$http.post("/contact", {
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phone,
          availabilityStart: this.startTime,
          availabilityEnd: this.endTime,
          cashOffer: this.cashOffer,
          arv: this.arv,
          rehab: this.rehab,
          fees: this.fees,
          address: this.selectedAddress.address
        });
      } catch (error) {
        console.error("Error creating contact:", error);
        this.error = true;
        this.submitted = false;
      }

      this.loading = false;
      this.submitted = true;
      this.error = false;
      this.SET_CASH_OFFER_SUBMITTED(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.subtext {
  font-size: 1.1rem;
}

.subtext-1 {
  font-size: 1rem;
}
</style>
