<template>
  <div class="property-detail-table-container">
    <h2 class="table-title text-left">{{ tableName }}</h2>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="properties"
      :items-per-page="10"
      item-key="name"
      hide-default-footer
      hide-default-header
      class="property-detail-table"
    >
      <template v-slot:[`item.value`]="{ item }">
        {{ propertyAttributeStatus(item) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "PropertyDetailTable",
  props: {
    tableName: String,
    headers: Array,
    properties: Array
  },
  methods: {
    propertyAttributeStatus(item) {
      switch (item.type) {
        case "waterFront":
        case "pool":
          return item.value ? "Yes" : "No";
        case "parkingType":
          return item.value || "N/A";
        default:
          return item.value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.property-detail-table-container {
  .table-title {
    font-family: "Helvetica Neue Bold";
    font-size: 20px;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #5c5e62;
    margin-bottom: 10px;
    padding-left: 14px;
  }

  .property-detail-table {
    border-top: solid 2px #5c5e62;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 30px;
  }
}

::v-deep {
  .v-data-table__wrapper {
    td {
      width: 50%;
    }
  }
}
</style>
