<template>
  <v-container fluid class="compare-container d-flex flex-column">
    <div class="navigation-section d-flex justify-start">
      <v-btn depressed class="back-btn" @click="onBack">
        <v-icon class="mr-1">mdi-arrow-left</v-icon>Back to Search
      </v-btn>
    </div>
    <compare-image-row :imageData="imageList" @comp-remove="onCompRemoved()" />
    <div class="scroll-content">
      <compare-table tableName="Compare" :tableData="compareList" />
      <compare-table tableName="Overview" :tableData="overviewList" />
      <compare-table tableName="Location" :tableData="locationList" />
      <compare-table tableName="Amenities" :tableData="amenitiesList" />
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import numbersMixin from "@/mixins/numbersMixin";
import { mapActions, mapGetters } from "vuex";
import { calculateARV } from "@/utils/arv";
import CompareImageRow from "@/components/CompareImageRow";
import CompareTable from "@/components/CompareTable";

export default {
  name: "Compare",
  components: {
    CompareImageRow,
    CompareTable
  },
  mixins: [numbersMixin],
  props: {
    //
  },
  mounted() {
    const comps = _.cloneDeep(this.comps);
    comps.forEach(comp => {
      if (comp.compare) {
        comp.alreadyViewed = true;
      }
    });

    this.setComps(comps);
  },
  destroyed() {
    const comps = _.cloneDeep(this.comps);
    comps.forEach(comp => {
      comp.alreadyViewed = false;
    });

    this.setComps(comps);
  },
  computed: {
    ...mapGetters("homesStore", [
      "selectedProperty",
      "compareComps",
      "comps",
      "mlsConstants",
      "priceAdjust"
    ]),
    compareData() {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.percentDiffLiv = null;
      selectedProperty.drivingDistance = null;

      return [selectedProperty, ...this.compareComps];
    },
    imageList() {
      const images = _.map(this.compareData, (item, index) => {
        const staticStreetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=300x300&location=${item.latitude},${item.longitude}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
        const images = item.mlsImageUrls.length
          ? item.mlsImageUrls
          : [staticStreetViewUrl];
        return {
          selected: index === 0,
          images: images,
          address: item.address
        };
      });
      return images;
    },
    compareList() {
      const estARVs = _.map(this.compareData, item =>
        this.getFormatedEstARV(Math.round(item.arv))
      );
      const buildMatches = _.map(this.compareData, "buildMatch");
      const list = [
        {
          title: "Est. ARV",
          unit: "$",
          prefix: true,
          list: estARVs,
          cellColor: "#04b6da"
        },
        {
          title: "Build Match %",
          unit: "",
          list: buildMatches
        }
      ];
      return list;
    },
    overviewList() {
      const bedRooms = _.map(this.compareData, "beds");
      const bathRooms = _.map(this.compareData, "baths");
      const livingAreas = _.map(this.compareData, "livingArea");
      const diffLivingAreas = _.map(this.compareData, "percentDiffLiv");
      const lotSizes = _.map(this.compareData, "lotSizeArea");
      const diffLotSizes = _.map(this.compareData, "lotSizeDiff");
      const extWalls = _.map(this.compareData, "extWall");
      const stories = _.map(this.compareData, "stories");
      const yearBuilts = _.map(this.compareData, "yearBuilt");
      const lastSolds = _.map(this.compareData, "saleDateStr");
      const daysOnMarkets = _.map(this.compareData, "numDaysOn");
      const listPrices = _.map(this.compareData, "origList");
      const salePrices = _.map(this.compareData, "salePriceStr");
      const askingPrices = [];
      const dollarPerSqfts = _.map(this.compareData, item => {
        return Math.floor(item.compsCostPerSqftActual || 0);
      });
      const list = [
        {
          title: "Bedrooms",
          unit: "",
          list: bedRooms
        },
        {
          title: "Bathrooms",
          unit: "",
          list: bathRooms
        },
        {
          title: "Living Area",
          unit: "sqft",
          list: livingAreas
        },
        {
          title: "% Diff. Living Area",
          unit: "",
          list: diffLivingAreas
        },
        {
          title: "Lot Size",
          unit: "sqft",
          list: lotSizes
        },
        {
          title: "% Diff. Lot Size",
          unit: "",
          list: diffLotSizes
        },
        {
          title: "Exterior Wall Type",
          unit: "",
          list: extWalls
        },
        {
          title: "Stories",
          unit: "",
          list: stories
        },
        {
          title: "Year Built",
          unit: "",
          list: yearBuilts
        },
        {
          title: "Last Sold",
          unit: "",
          list: lastSolds
        },
        {
          title: "Days on Market",
          unit: "",
          list: daysOnMarkets
        },
        {
          title: "List Price",
          unit: "",
          prefix: true,
          list: listPrices
        },
        {
          title: "Sale Price",
          unit: "",
          prefix: true,
          list: salePrices
        },
        {
          title: "% (+/-) Asking Price",
          unit: "%",
          list: askingPrices
        },
        {
          title: "$ / Sqft",
          unit: "$",
          prefix: true,
          list: dollarPerSqfts
        }
      ];
      return list;
    },
    locationList() {
      const distanceFromSelecteds = _.map(this.compareData, "drivingDistance");
      const neighborhoods = _.map(this.compareData, "subdivision");
      const list = [
        {
          title: "Distance from Selected",
          unit: "Miles",
          list: distanceFromSelecteds
        },
        {
          title: "Neighborhood",
          unit: "",
          list: neighborhoods
        }
      ];
      return list;
    },
    amenitiesList() {
      const waterfrontYNs = _.map(this.compareData, "waterfrontYN");
      const pools = _.map(this.compareData, "pool");
      const parkingTypes = _.map(this.compareData, "parkingType");
      const parkingSpaces = _.map(this.compareData, "parkingArea");
      const airConditionings = _.map(this.compareData, "airCond");
      const list = [
        {
          type: "waterFront",
          title: "Water Front (Y/N)",
          unit: "",
          list: waterfrontYNs
        },
        {
          type: "pool",
          title: "Pool (Y/N)",
          unit: "",
          list: pools
        },
        {
          type: "parkingType",
          title: "Parking Type",
          unit: "",
          list: parkingTypes
        },
        {
          type: "parkingSpace",
          title: "Parking Space",
          unit: "",
          list: parkingSpaces
        },
        {
          type: "ac",
          title: "Air Conditioning",
          unit: "",
          list: airConditionings
        }
      ];
      return list;
    }
  },
  methods: {
    ...mapActions("homesStore", ["setComps"]),
    onBack() {
      this.$router.replace("/properties");
    },
    getCompNeigh(neighborhood) {
      return (neighborhood && neighborhood.split("/")[1]) || "";
    },
    getFormatedEstARV(value) {
      return this.$_numbersMixin_getNumberWithCommas(value);
    },
    onCompRemoved() {
      this.updateSelectedPropertyARV();
    },
    updateSelectedPropertyARV() {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.arv = calculateARV(
        this.comps,
        selectedProperty,
        this.mlsConstants,
        this.priceAdjust
      );
      this.$store.dispatch("homesStore/setSelectedProperty", selectedProperty);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-content {
  margin-top: 12px;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 800px;
}

.sticky-property {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.compare-container {
  width: 100%;
  height: 100%;
  padding: 50px 40px 40px 40px;
}

@media screen and (max-width: 600px) {
  .compare-container {
    padding: 0 20px 0 10px;
  }
}

::v-deep {
  .back-btn {
    background: none !important;
    text-transform: none;
    font-family: "Helvetica Neue Medium";
    font-size: 16px;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #5c5e62;
  }
}
</style>
