<template>
  <v-snackbar v-model="show" top right color="error">
    <span class="alert-message">{{ message }}</span>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      show: false,
      message: ""
    };
  },
  computed: {
    ...mapState("viewsStore", {
      viewsState: state => state,
      snack: state => state.snack
    }),
    snackbarOpen() {
      return this.snack;
    }
  },
  watch: {
    snackbarOpen: {
      handler(newVal, oldVal) {
        if (newVal !== "") {
          this.message = newVal;
          this.show = true;
          this.setSnack("");
        }
      }
    }
  },
  methods: {
    ...mapActions("viewsStore", ["setSnack"])
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-snack__content {
    .alert-message {
      font-size: 20px;
    }
  }
}
</style>
