<template>
  <v-overlay
    :value="overlayOpen"
    :opacity="opacity"
    class="property-image-view-overlay-container"
  >
    <v-container fluid class="overlay-content">
      <v-btn icon @click="onCloseClick" class="close-btn">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-tabs
        v-model="selectedTab"
        align-with-title
        background-color="transparent"
        class="tabs-section"
      >
        <v-tab v-for="tab in tabs" :key="tab">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="tab in tabs" :key="tab">
          <images-view v-if="tab === 'Images'" hideSide :images="images" />
          <street-view v-if="tab === 'Street View'" :location="location" />
          <floorplan-view
            v-if="tab === 'Floorplan'"
            :floorplanImage="floorplanImage"
          />
        </v-tab-item>
      </v-tabs-items>
      <p v-if="selectedTab === 0" class="property-info">
        {{ propertyInfo }}
      </p>
    </v-container>
  </v-overlay>
</template>

<script>
import ImagesView from "@/components/ImagesView";
import StreetView from "@/components/StreetView";
import FloorplanView from "@/components/FloorplanView";

export default {
  name: "PropertyImageViewOverlay",
  components: {
    ImagesView,
    StreetView,
    FloorplanView
  },
  props: {
    overlay: Boolean,
    data: Object
  },
  data: () => ({
    opacity: 0.9,
    overlayOpen: false,
    selectedTab: null,
    tabs: ["Images", "Street View", "Floorplan"]
  }),
  computed: {
    propertyInfo() {
      const { beds, baths, livingArea: sqFt } = this.data;
      return `${beds} bd | ${baths} ba | ${sqFt} sqft`;
    },
    images() {
      return this.data.mlsImageUrls;
    },
    location() {
      const { latitude: lat, longitude: lng } = this.data;
      return {
        lat,
        lng
      };
    },
    floorplanImage() {
      return this.data.floorplanURL;
    }
  },
  mounted() {
    this.overlayOpen = this.overlay;
    if (!this.images.length) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 0;
    }
  },
  methods: {
    onCloseClick() {
      this.overlayOpen = false;
      this.$emit("close-overlay");
    }
  }
};
</script>

<style lang="scss" scoped>
.property-image-view-overlay-container {
  .overlay-content {
    position: relative;
    height: 75%;
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }

  .tabs-section {
    margin-bottom: 50px;
  }

  .property-info {
    margin-top: 30px;
  }
}

::v-deep {
  .v-overlay__content {
    width: 100%;
    height: 100%;
  }

  .v-window {
    height: 100% !important;
  }

  .v-window-item {
    height: 100%;
  }
}
</style>
