import { calculateARV, computeNewARV } from "@/utils/arv";

const state = () => ({
  selectedAddress: {},
  selectedProperty: {},
  selectedPropertyImage: {},
  quickCompareProperty: {},
  mlsConstants: {},
  comps: [],
  priceAdjust: true
});

const mutations = {
  SET_COMPS(state, data) {
    state.comps = [...data];
  },
  REMOVE_COMPARE_COMP(state, data) {
    state.comps = state.comps.map(comp => {
      if (comp.iD === data.iD) {
        comp.compare = false;
      }
      return comp;
    });
  },
  SET_MLS_CONSTANTS(state, data) {
    state.mlsConstants = { ...data };
  },
  SET_SELECTED_ADDRESS(state, address) {
    state.selectedAddress = { ...address };
  },
  SET_SELECTED_PROPERTY(state, property) {
    state.selectedProperty = { ...property };
  },
  SET_SELECTED_PROPERTY_IMAGE(state, image) {
    state.selectedPropertyImage = { ...image };
  },
  SET_QUICK_COMPARE_PROPERTY(state, property) {
    state.quickCompareProperty = { ...property };
  },
  SET_PRICE_ADJUST(state, value) {
    state.priceAdjust = value;

    const compsCopy = [...state.comps];

    compsCopy.forEach(comp => {
      if (comp.trendAdjust && state.priceAdjust) {
        comp.arv *= comp.priceMultiplier;
      } else if (comp.trendAdjust && !state.priceAdjust) {
        comp.arv /= comp.priceMultiplier;
      }
    });

    state.comps = [...compsCopy];

    let arv = calculateARV(
      state.comps,
      state.selectedProperty,
      state.mlsConstants,
      state.priceAdjust
    );

    state.selectedProperty = { ...state.selectedProperty, arv };
  }
};

const actions = {
  setComps(context, payload) {
    context.commit("SET_COMPS", payload);
  },
  removeCompareComp(context, payload) {
    context.commit("REMOVE_COMPARE_COMP", payload);
  },
  setMlsConstants(context, payload) {
    context.commit("SET_MLS_CONSTANTS", payload);
  },
  setBadComps(context, payload) {
    context.commit("SET_BAD_COMPS", payload);
  },
  setSelectedAddress(context, payload) {
    context.commit("SET_SELECTED_ADDRESS", payload);
  },
  setSelectedProperty(context, payload) {
    context.commit("SET_SELECTED_PROPERTY", payload);
  },
  setSelectedPropertyImage(context, payload) {
    context.commit("SET_SELECTED_PROPERTY_IMAGE", payload);
  },
  setQuickCompareProperty(context, payload) {
    context.commit("SET_QUICK_COMPARE_PROPERTY", payload);
  },
  setAdditionalComps(context, payload) {
    context.commit("SET_ADDITIONAL_COMPS", payload);
  },
  setPreDiscardedComps(context, payload) {
    context.commit("SET_PRE_DISCARDED_COMPS", payload);
  },
  async getArv({ commit, state: vuexState, dispatch, rootGetters }, payload) {
    // reset price adjust to false on new search (Now on by defualt ignore if true)
    // vuexState.priceAdjust = true;
    commit("SET_PRICE_ADJUST", false);
    /**
     * Calculate ARV
     *
     * @param {*} payload
     * latitude: 27.9437634
     * longitude: -82.49505099999999
     * address: "108 S Matanzas Ave"
     * county: "Hillsborough County"
     * city: "Tampa"
     * zip1: "33609"
     * zip2: "3009"
     */
    const { address, formattedAddress } = payload;
    const response = (await this._vm.$http.post("calc_arv", address)).data;

    const { properties, constants } = response;
    const { bad_comps, comps, subject: selectedProperty } = properties;

    if (response.message) {
      throw response.message;
    }

    if (!comps || !selectedProperty) throw "No comps or properties found";

    const { address: streetAddress, city, state, zip1: zipCode } = address;

    await dispatch("setMlsConstants", constants);
    await dispatch("setSelectedAddress", {
      address: formattedAddress,
      streetAddress,
      city,
      state,
      zipCode
    });

    // If price trend adjust on multiply comp arv by provided multiplier
    // This is mostly for display purposes on first page load
    // comps.forEach(comp => {
    //   if (comp.trendAdjust && vuexState.priceAdjust) {
    //     comp.arv *= comp.priceMultiplier;
    //   }
    // });

    selectedProperty.arv = calculateARV(
      comps,
      selectedProperty,
      constants,
      vuexState.priceAdjust
    );

    await dispatch("setSelectedProperty", selectedProperty);

    const allComps = [...comps, ...bad_comps];

    await dispatch(
      "setComps",
      allComps.map((item, index) => {
        return {
          id: index,
          ...item,
          compare: false
        };
      })
    );

    if (
      rootGetters["auth/isSeller"] &&
      !rootGetters["auth/cashOfferSubmitted"]
    ) {
      commit("cashoffer/SET_CASH_OFFER_STATE", true, { root: true });
    }

    commit("SET_PRICE_ADJUST", true);

    // await dispatch(
    //   "setBadComps",
    //   bad_comps.map((item, index) => {
    //     return {
    //       id: index,
    //       ...item
    //     };
    //   })
    // );
  }
};

const getters = {
  selectedProperty: state => state.selectedProperty,
  selectedPropertyImage: state => state.selectedPropertyImage,
  selectedAddress: state => state.selectedAddress,
  comps: state => state.comps,
  quickCompareProperty: state => state.quickCompareProperty,
  suggestedComps: state =>
    state.comps.filter(item => !item.suggestedDiscard && !item.serverDiscard),
  discardedComps: state => state.comps.filter(item => !!item.suggestedDiscard),
  compareComps: state =>
    state.comps.filter(item => !item.suggestedDiscard && !!item.compare),
  badComps: state => state.comps.filter(item => item.serverDiscard),
  mlsConstants: state => state.mlsConstants,
  priceAdjust: state => state.priceAdjust
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
