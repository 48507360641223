<template>
  <v-container class="map-compare-popup-compare-container">
    <v-row no-gutters>
      <v-col>
        <map-compare-popup-compare-item
          selected
          :propertyData="selectedPropertyData"
        />
      </v-col>
      <v-col v-for="(item, index) in compareComps" :key="`compare-${index}`">
        <map-compare-popup-compare-item
          :propertyData="item"
          @remove-compare-item="onComareItemRemoved"
        />
      </v-col>
      <v-col v-for="rIndex in emptyCompsLength" :key="`empty-${rIndex}`">
        <map-compare-popup-compare-item empty />
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-btn depressed class="compare-btn" @click="onCompareBtnClick">
        Compare
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import MapComparePopupCompareItem from "@/components/MapComparePopupCompareItem";

export default {
  name: "MapComparePopupCompare",
  components: {
    MapComparePopupCompareItem
  },
  props: {
    //
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedProperty", "compareComps"]),
    selectedImageUrl() {
      return this.selectedProperty.image;
    },
    selectedPropertyData() {
      return {
        ...this.selectedProperty,
        sqFt: this.selectedProperty.livingArea
      };
    },
    emptyCompsLength() {
      if (this.compareComps.length >= 3) {
        return 0;
      }
      return 3 - this.compareComps.length;
    }
  },
  mounted() {
    //
  },
  methods: {
    onComareItemRemoved(item) {
      this.$store.dispatch("homesStore/removeCompareComp", item);
      // const compareProperties = _.cloneDeep(this.comps);
      // const index = _.findIndex(compareProperties, cItem =>
      //   _.isEqual(cItem, item)
      // );
      // console.log(index, item.iD);
      // if (index >= 0) {
      //   compareProperties[index].compare = false;
      //   this.$store.dispatch("homesStore/setComps", compareProperties);
      // }
    },
    onCompareBtnClick() {
      this.$router.replace("/compare");
    }
  }
};
</script>

<style lang="scss" scoped>
.map-compare-popup-compare-container {
  // width: 100%;
  background: #5c5e62;

  .selected-img {
    width: 140px;
    height: 140px;
    margin-top: 20px;
    margin-bottom: 10px;
    border: solid 2px #74cfe7;
  }

  .compare-btn {
    width: 115px;
    height: 34px;
    background: #04b6da !important;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: none;
    margin-bottom: 20px;
  }
}
</style>
