<template>
  <div style="height: 100%" class="d-flex flex-column">
    <v-container fluid>
      <v-row justify="space-around">
        <v-col cols="12" md="6" xl="4" style="position: relative;">
          <div class="home-search-section">
            <home-search height="35" :display-button-text="false" />
          </div>
          <google-map
            :properties="properties"
            :markers="markers"
            width="100%"
            height="100%"
            size="lg"
            @marker-compare="onMarkerCompared"
            @marker-discard="onMarkerDiscarded"
            @marker-undo-discard="onMarkerDiscardUndone"
          />
        </v-col>
        <v-col cols="12" md="6" xl="4">
          <home-view />
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid pa-0 v-scroll.self="onScroll" class="scroll-content">
      <v-row>
        <v-col
          cols="12"
          class="sticky-property"
          :class="{ 'elevation-2': elevation }"
        >
          <properties-table
            type="Selected"
            title="Selected Property"
            :headers="selectedPropertyHeaders"
            :items="selectedProperties"
            :popup-est-arv="estARVValues"
          />
        </v-col>

        <v-col cols="12">
          <properties-table
            id="v-step-1"
            type="Suggested"
            title="Suggested Comps"
            :hide-arv="true"
            :headers="comparePropertyHeaders"
            :items="suggestedComps"
            :popup-est-arv="estARVValues"
            @check-row="onRowChecked"
            @delete-row="onRowDeleted"
          />
        </v-col>

        <v-col cols="12">
          <properties-table
            v-if="additionalProperties.length > 0"
            type="Suggested"
            title="Additional Comps"
            :hide-arv="true"
            :headers="comparePropertyHeaders"
            :items="additionalProperties"
            :popup-est-arv="estARVValues"
            @check-row="onRowChecked"
            @delete-row="onRowDeleted"
          />
        </v-col>

        <v-col cols="12">
          <properties-table
            id="v-step-5"
            type="Discarded"
            title="Discarded Comps"
            :hide-arv="true"
            :headers="comparePropertyHeaders"
            :items="discardedComps"
            :popup-est-arv="estARVValues"
            @check-row="onRowChecked"
            @return-row="onRowReturned"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <properties-table
            v-if="showPreDiscardedProperties"
            type="Pre-Discarded"
            title="Pre-Discarded Comps"
            :hide-arv="true"
            :headers="comparePropertyHeaders"
            :items="badComps"
            :popup-est-arv="estARVValues"
            @check-row="onRowChecked"
            @return-row="onRowReturned"
          />
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-btn
          depressed
          class="toggle-pre-discarded-comps-btn mb-8"
          @click="onTogglePreDiscardedComps"
        >
          {{ preDiscardedCompsButtonLabel }}
        </v-btn>
      </v-row>
      <SignUpReminder v-model="showSignUpReminder" @close="tourStart" />
    </v-container>
    <v-tour name="myTour" :steps="steps" :callbacks="tourCallbacks"></v-tour>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
/* eslint-disable vue/no-unused-components */
import _ from "lodash";
import numbersMixin from "@/mixins/numbersMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { calculateARV } from "@/utils/arv";
import { ADDITIONAL_COMPS } from "@/mocks/comps";
import PropertiesTable from "@/components/PropertiesTable";
import GoogleMap from "@/components/Shared/GoogleMap";
import HomeSearch from "@/components/Shared/HomeSearch";
import HomeView from "@/components/HomeView";
import SignUpReminder from "@/components/SignUpReminder";

export default {
  name: "Properties",
  components: {
    PropertiesTable,
    GoogleMap,
    HomeSearch,
    HomeView,
    SignUpReminder,
    VueHtml2pdf
  },
  mixins: [numbersMixin],
  data() {
    return {
      showSignUpReminder: false,
      signupPopup: null,
      places: [],
      currentPlace: null,
      formattedAddress: "",
      preDiscardedProperties: [],
      additionalProperties: [],
      menu: false,
      showPreDiscardedProperties: false,
      additionalPropertiesList: [],
      selectedAdditionalPropertyAddress: "",
      tourCallbacks: {
        onSkip: this.tourSkip,
        onFinish: this.tourFinish
      },
      steps: [
        {
          target: "#v-step-0",
          content: `Here is the Est. After Repair Value (ARV) of the subject property. Hover your cursor over the value to see a range of potential values.`,
          header: {
            title: "Estimated ARV"
          },
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-1",
          header: {
            title: "Suggested Comps"
          },
          content:
            "Here you can find a list of suggested comparable properties to the subject property. These are used to calculate the Est. ARV of the subject property.  You can get a more accurate ARV by discarding any properties in this list that have not been recently renovated.",
          params: {
            placement: "top",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-2-1",
          header: {
            title: "Quick Compare"
          },
          content:
            "Click on any of the Suggested Comps addresses to quickly view that property's pictures & information side-by-side with the subject property.",
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-2",
          header: {
            title: "Compare Properties"
          },
          content:
            "Select as many as 3 properties to be compared using the checkboxes.",
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-3",
          header: {
            title: "Compare Properties (Continued)"
          },
          content:
            "After selecting up to 3 properties from the Suggested Comps table below, click the Compare button to see them all side-by-side with the Subject Property for easy comparison.",
          params: {
            placement: "right",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-4",
          header: {
            title: "Remove Comparable Property"
          },
          content:
            "Click the trash icon to remove that property from the Est. ARV calculation.",
          params: {
            placement: "left",
            enableScrolling: false
          }
        },
        {
          target: "#v-step-5",
          header: {
            title: "Discarded Properties"
          },
          content:
            "Here is a list of properties deemed unsuitable for comparison to the subject property.  If you feel any of these properties were discarded in error, you can always add them back to the Suggested Comps table.",
          params: {
            placement: "top",
            enableScrolling: true
          }
        },
        {
          target: "#v-step-6",
          header: {
            title: "Included Discarded Properties"
          },
          content:
            "Clicking this icon will add previously discarded properties back to the Suggested Comps table to be included in the Est. ARV calculation.",
          params: {
            placement: "left",
            enableScrolling: false
          }
        }
      ]
    };
  },
  async mounted() {
    this.updateTableData(this.comps);
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("user", ["tutorial"]),
    ...mapGetters("homesStore", [
      "selectedProperty",
      "suggestedComps",
      "discardedComps",
      "mlsConstants",
      "compareComps",
      "badComps",
      "comps",
      "priceAdjust"
    ]),
    elevation() {
      return this.offsetTop > 1;
    },
    markers() {
      return _.map(this.comps, item => {
        return {
          position: {
            lat: item.latitude,
            lng: item.longitude
          }
        };
      });
    },
    selectedPropertyHeaders() {
      return [
        {
          text: "Address",
          align: "left",
          sortable: true,
          value: "address",
          width: "15%"
        },
        {
          text: "Neighborhood",
          value: "neighborhood",
          width: "16%"
        },
        {
          text: "Beds",
          value: "beds",
          width: "6%"
        },
        {
          text: "Baths",
          value: "baths",
          width: "6%"
        },
        {
          text: "Sq.Ft.",
          value: "sqFt",
          width: "6%"
        },
        {
          text: "Lot Size",
          value: "lotSize",
          width: "7%"
        },
        {
          text: "Last Sold",
          value: "lastSoldDate",
          width: "8%"
        },
        {
          text: "Sale Price",
          value: "salePrice",
          width: "8%"
        },
        {
          text: "$ / Sqft",
          value: "dollarPerSqft",
          width: "15%"
        },
        {
          text: "Est. ARV",
          value: "arv",
          width: "13%"
        }
      ];
    },
    comparePropertyHeaders() {
      return [
        {
          text: "Address",
          align: "left",
          sortable: true,
          value: "address",
          width: "15%"
        },
        {
          text: "Neighborhood",
          value: "subdivision",
          width: "9%"
        },
        {
          text: "Distance",
          value: "distance",
          width: "7%"
        },
        {
          text: "Beds",
          value: "beds",
          width: "6%"
        },
        {
          text: "Baths",
          value: "baths",
          width: "6%"
        },
        {
          text: "Sq.Ft.",
          value: "livingArea",
          width: "6%"
        },
        {
          text: "Lot Size",
          value: "lotSizeArea",
          width: "7%"
        },
        {
          text: "Last Sold",
          value: "saleDateStr",
          width: "8%"
        },
        {
          text: "Sale Price",
          value: "salePriceStr",
          width: "8%"
        },
        {
          text: "$ / Sqft",
          value: "compsCostPerSqftActual",
          width: "7%"
        },
        {
          text: "Build Match",
          value: "buildMatch",
          width: "8%"
        },
        {
          text: "Est. ARV",
          value: "arv",
          width: "6.5%"
        },
        {
          text: "Compare",
          value: "compare",
          width: "6.5%"
        }
      ];
    },
    selectedProperties() {
      if (
        !this.selectedProperty ||
        (this.selectedProperty && !this.selectedProperty.address)
      ) {
        return [];
      }

      const {
        address,
        state,
        city,
        zipCode,
        subdivision: neighborhood,
        beds,
        baths,
        livingArea: sqFt,
        lotSizeArea: lotSize,
        saleDateStr: lastSoldDate,
        salePriceStr,
        arv,
        estMIN,
        estMAX
      } = this.selectedProperty;

      let dollarPerSqft = "N/A";

      if (this.selectedProperty.price && sqFt) {
        dollarPerSqft = Math.round(this.selectedProperty.price / sqFt);
      }

      const salePrice =
        salePriceStr ||
        "$" +
          this.$_numbersMixin_getNumberWithCommas(this.selectedProperty.price);

      return [
        {
          address,
          state,
          city,
          zipCode,
          neighborhood,
          beds,
          baths,
          sqFt,
          lotSize,
          lastSoldDate,
          salePrice,
          dollarPerSqft,
          arv,
          estMIN: estMIN || 0,
          estMAX: estMAX || 0
        }
      ];
    },
    preDiscardedCompsButtonLabel() {
      if (this.showPreDiscardedProperties) {
        return "Hide Pre-Discarded Comps";
      }
      return "Show Pre-Discarded Comps";
    },
    additionalPropertyAddresses() {
      return this.additionalPropertiesList.map(item => item.address);
    },
    properties() {
      let combinedProperties = [
        {
          type: "selected",
          ...this.selectedProperty
        },
        ..._.map(this.suggestedComps, item => {
          return {
            type: "suggested",
            ...item
          };
        }),
        ..._.map(this.discardedComps, item => {
          return {
            type: "discarded",
            ...item
          };
        })
      ];
      if (this.showPreDiscardedProperties) {
        combinedProperties = [
          ...combinedProperties,
          ..._.map(this.badComps, item => {
            return {
              type: "prediscarded",
              ...item
            };
          })
        ];
      }
      return _.cloneDeep(combinedProperties);
    },
    estARVValues() {
      return {
        max: (
          _.maxBy(this.suggestedComps, function(property) {
            return property.arv;
          }) || {}
        ).arv,
        min: (
          _.minBy(this.suggestedComps, function(property) {
            return property.arv;
          }) || {}
        ).arv
      };
    }
  },
  watch: {
    comps: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.updateTableData(newVal);
      },
      deep: true
    },
    tutorial: function() {
      this.tourStart();
    }
  },
  async created() {
    if (!this.isAuthenticated) return;
    try {
      const res = await this.$http.get("/check_offer");
      this.SET_CASH_OFFER_SUBMITTED(res.data);
    } catch (error) {
      console.error("Error getting cash offer contact:", error);
    }
  },
  mounted() {
    // Value to edit for sign up pop up time
    // The value is in seconds
    const signUpPopUpTime = 1;

    if (!this.isAuthenticated) {
      this.signupPopup = setTimeout(() => {
        this.showSignUpReminder = true;
      }, signUpPopUpTime * 1000);
    } else if (this.tutorial && !this.$vuetify.breakpoint.mobile) {
      this.$tours["myTour"].start();
    }
  },
  destroyed() {
    clearTimeout(this.signupPopup);
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    ...mapActions("user", ["setTutorial"]),
    ...mapMutations("auth", ["SET_CASH_OFFER_SUBMITTED"]),
    tourStart() {
      if (this.tutorial && !this.$vuetify.breakpoint.mobile) {
        this.$tours["myTour"].start();
      }
    },
    tourSkip(currentStep) {
      this.setTutorial(false);
    },
    tourFinish(currentStep) {
      this.setTutorial(false);
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    async updateTableData(comps) {
      this.additionalPropertiesList = ADDITIONAL_COMPS;

      this.checkedProperties = this.suggestedComps.filter(item => item.compare);
    },
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    updateSelectedPropertyARV() {
      const selectedProperty = _.cloneDeep(this.selectedProperty);
      selectedProperty.arv = calculateARV(
        this.comps,
        selectedProperty,
        this.mlsConstants,
        this.priceAdjust
      );
      this.$store.dispatch("homesStore/setSelectedProperty", selectedProperty);
    },
    onRowChecked(item) {
      const isSuggested = !item.suggestedDiscard;
      const isDiscarded = !!item.suggestedDiscard;
      const isPreDiscarded = !!item.serverDiscard;
      let compareItems = _.cloneDeep(this.compareComps);

      if (item.compare && compareItems.length >= 3) return;

      if (isSuggested) {
        const compareProperties = _.cloneDeep(this.comps);
        const index = _.findIndex(
          compareProperties,
          cItem => cItem.id === item.id
        );
        if (index >= 0) {
          if (item.compare) {
            compareProperties[index].compare = true;
          } else {
            compareProperties[index].compare = item.type ? true : false;
          }
          this.$store.dispatch("homesStore/setComps", compareProperties);
        }
      }
    },
    onRowDeleted(item) {
      let compareProperties = _.cloneDeep(this.comps);
      const index = _.findIndex(
        compareProperties,
        fItem => fItem.id === item.id
      );
      if (index >= 0) {
        compareProperties[index].compare = false;
        compareProperties[index].suggestedDiscard = true;
        this.$store.dispatch("homesStore/setComps", compareProperties);
        this.updateSelectedPropertyARV();
      }
    },
    onRowReturned(item) {
      const compareProperties = _.cloneDeep(this.comps);

      const index = _.findIndex(
        compareProperties,
        dItem => dItem.id === item.id
      );

      if (index >= 0) {
        compareProperties[index].suggestedDiscard = false;
        compareProperties[index].serverDiscard = false;
        this.$store.dispatch("homesStore/setComps", compareProperties);
        this.updateSelectedPropertyARV();
      }
    },
    onTogglePreDiscardedComps() {
      this.showPreDiscardedProperties = !this.showPreDiscardedProperties;
    },
    async onGoPremium() {
      try {
        await this.$store.commit("auth/SET_USER_PLAN", "PRO");
      } catch (e) {
        console.error("-- Set User Plan Error --", e);
      }
    },
    async onAddAdditionalComp() {
      const selectedAdditionalComp = this.additionalPropertiesList.find(
        item => (item.address = this.selectedAdditionalPropertyAddress)
      );
      const additionalItems = this.homesState.additionalComps;
      const addedItems = [...additionalItems, selectedAdditionalComp];

      try {
        await this.$store.dispatch("homesStore/setAdditionalComps", addedItems);
        this.additionalProperties = addedItems;
      } catch (e) {
        console.error("-- Add Additional Comp Error --", e);
      }
    },
    onMarkerCompared(item) {
      this.onRowChecked(item);
    },
    onMarkerDiscarded(item) {
      this.onRowDeleted(item);
    },
    onMarkerDiscardUndone(item) {
      this.onRowReturned(item);
    }
  }
};
</script>

<style lang="scss" scoped>
.scroll-content {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 800px;
}

.sticky-property {
  position: sticky;
  top: -1px;
  background: white;
  z-index: 2;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.home-search-section {
  position: absolute;
  width: 80%;
  margin: 10px;
  z-index: 1;
}

.go-premium-card-menu {
  width: 389px;
  height: 171px;
}

.go-premium-card {
  width: 100%;
  height: 171px;
  background: linear-gradient(294deg, #03b6da, #6348c6) !important;
  padding: 30px 27px !important;

  &--title {
    font-family: "Helvetica Neue Bold";
    font-size: 20px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5c5e62;
  }

  &--description {
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #5c5e62;
    margin-top: 7px;
  }

  &--btn {
    width: 120px;
    height: 34px;
    background-color: #04b6da !important;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #ffffff;
  }
}

::v-deep {
  .toggle-pre-discarded-comps-btn {
    background: none !important;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: none;
    color: #04b6da;
  }

  .additional-property-text {
    .v-input__control {
      width: 400px;
    }
  }

  .additional-property-autocomplete {
    .v-select__slot {
      width: 400px;
    }
  }

  .add-btn {
    width: 46px;
    height: 34px;
    background-color: #04b6da !important;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    color: #ffffff;
  }
}
</style>
