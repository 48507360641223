<template>
  <div class="street-view-container">
    <gmap-street-view-panorama
      class="pano"
      style="width: 100%; height: 100%;"
      :position="location"
      :pov="pov"
    />
  </div>
</template>

<script>
import { getGoogleMapsAPI } from "gmap-vue";

export default {
  name: "StreetView",
  props: {
    location: Object
  },
  data: () => ({
    pov: {
      heading: 0,
      pitch: 0
    }
  }),
  computed: {
    google: getGoogleMapsAPI
  },
  async mounted() {
    await this.$gmapApiPromiseLazy();
    this.calculateHeading();
  },
  methods: {
    calculateHeading() {
      const sv = new this.google.maps.StreetViewService();

      sv.getPanorama({ location: this.location }, (data, status) => {
        const heading = this.google.maps.geometry.spherical.computeHeading(
          data.location.latLng,
          new this.google.maps.LatLng(this.location.lat, this.location.lng)
        );
        this.pov.heading = heading;
      }).catch(error => {
        console.error(error);
      });
    }
  },
  watch: {
    location() {
      this.calculateHeading();
    }
  }
};
</script>

<style lang="scss" scoped>
.street-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
</style>
