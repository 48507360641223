<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ isAuthenticated ? userName : "eHomeFacts" }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list v-if="isAuthenticated" dense nav>
      <v-list-item link :to="{ name: 'Home' }">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        v-if="isPropertiesPage && !isSeller"
        @click="rehabCalculator"
      >
        <v-list-item-icon>
          <v-icon>mdi-calculator</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Rehab Calculator</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        v-if="isPropertiesPage && isSeller && !cashOfferSubmitted"
        @click="cashOffer"
      >
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cash Offer</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :to="{ name: 'TermsOfService' }">
        <v-list-item-icon>
          <v-icon>mdi-scale-balance</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Terms of Service</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="logoutUser">
        <v-list-item-icon>
          <v-icon>mdi-exit-to-app</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list v-else dense nav>
      <v-list-item link :to="{ name: 'Home' }">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="signIn">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link @click="signUp">
        <v-list-item-icon>
          <v-icon>mdi-account-plus</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Sign Up</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <SignInModal v-model="signInOpen" />
    <SignUpReminder v-model="signUpOpen" />
    <RehabCalculatorModal
      v-model="rehabCalculatorOpen"
      :address="selectedAddress.address"
    />
    <CashOfferModal
      v-model="showCashOffer"
      :address="selectedAddress.address"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignInModal from "@/components/SignInModal";
import SignUpReminder from "@/components/SignUpReminder";
import RehabCalculatorModal from "@/components/RehabCalculator/RehabCalculatorModal";
import CashOfferModal from "@/components/CashOffer/CashOfferModal";

export default {
  components: {
    SignInModal,
    SignUpReminder,
    RehabCalculatorModal,
    CashOfferModal
  },
  data() {
    return {
      rehabCalculatorOpen: false,
      signInOpen: false,
      signUpOpen: false
    };
  },
  computed: {
    ...mapGetters("auth", [
      "userName",
      "isAuthenticated",
      "isSeller",
      "cashOfferSubmitted"
    ]),
    ...mapGetters("cashoffer", ["showCashOffer"]),
    ...mapGetters("homesStore", ["selectedAddress"]),
    isPropertiesPage() {
      return this.$route.path === "/properties";
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["setTutorial"]),
    ...mapActions("cashoffer", ["setCashOfferState"]),
    startTour() {
      this.setTutorial(true);
      this.$emit("close");
    },
    cashOffer() {
      this.setCashOfferState(true);
      this.$emit("close");
    },
    rehabCalculator() {
      this.rehabCalculatorOpen = true;
      this.$emit("close");
    },
    signIn() {
      this.signInOpen = true;
      this.$emit("close");
    },
    signUp() {
      this.signUpOpen = true;
      this.$emit("close");
    },
    logoutUser() {
      this.logout();
      this.$emit("close");
    }
  },
  watch: {
    isSeller(newValue) {
      if (this.isAuthenticated && !newValue && this.isPropertiesPage) {
        this.rehabCalculatorOpen = true;
      }
    }
  }
};
</script>

<style></style>
