import Vue from "vue";
import GmapVue from "gmap-vue";
// import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places"
  },
  installComponents: true
});

// Vue.use(VuetifyGoogleAutocomplete, {
//   /*
//     not used as loaded with component
//     apiKey: key,
//   */
//   vueGoogleMapsCompatibility: true
// });
