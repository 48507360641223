<template>
  <div class="compare-image-row-image-item-container">
    <v-btn v-if="!selected" fab small class="close-btn" @click="onRemove">
      x
    </v-btn>
    <v-carousel
      hide-delimiters
      show-arrows-on-hover
      v-model="selectedImage"
      class="item-carousel"
    >
      <v-carousel-item v-for="(item, i) in images" :key="i">
        <v-img
          :src="item"
          @click="onCarouselClick"
          :class="getClasses(selected)"
        />
      </v-carousel-item>
    </v-carousel>
    <property-detail-modal
      v-if="propertyModalOpen"
      :dialog="propertyModalOpen"
      :data="propertyData"
      :selectedImage="selectedImage"
      @modal-close="onModalClosed"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import PropertyDetailModal from "@/components/PropertyDetailModal";

export default {
  name: "CompareImageRowImageItem",
  components: {
    PropertyDetailModal
  },
  props: {
    order: Number,
    images: Array,
    selected: Boolean
  },
  data: () => ({
    detailImages: [],
    selectedImage: 0,
    propertyModalOpen: false
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedProperty", "compareComps"]),
    propertyData() {
      const comps = [this.selectedProperty, ...this.compareComps];
      return comps[this.order];
    }
  },
  methods: {
    getClasses(selected) {
      let classes = "compare-image";
      if (selected) {
        classes += " compare-image--selected";
      }
      return classes;
    },
    onRemove() {
      this.$emit("remove-item");
    },
    onCarouselClick() {
      this.propertyModalOpen = true;
    },
    onModalClosed() {
      this.propertyModalOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.compare-image-row-image-item-container {
  position: relative;
  width: 250px;
  height: 180px;

  .item-carousel {
    width: 100%;
    height: 100% !important;
  }

  .compare-image {
    height: 100%;
    width: 100%;

    &--selected {
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
      border: solid 3px #74cfe7;
    }
  }

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px !important;
    height: 16px !important;
    min-width: 16px;
    border-radius: 50%;
    z-index: 1;
  }
}

::v-deep {
  .v-carousel__item {
    height: 100% !important;
  }
}
</style>
