<template>
  <div>
    <section
      class="hero-section"
      :style="{ height: heroHeight }"
      v-scroll="onScroll"
    >
      <v-container fill-height style="max-width: 1200px" ref="container">
        <v-row justify="center" v-if="maintenanceMode">
          <v-col>
            <v-card color="error" dark>
              <v-container class="text-center">
                <h1 class="text-h2 mb-4">Down For Maintenance</h1>
                <p class="text-h6">
                  The site is temporarily down for routine maintenance.
                  <br />
                  We'll be back soon, so please try agian later.
                </p>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col>
            <div>
              <div
                class="tagline pl-6 text-outline"
                v-resize-text="{
                  ratio: 2.3
                }"
              >
                Find Out How Much That House Is Really Worth
              </div>
            </div>
            <div>
              <HomeSearch
                height="60"
                :disabled="maintenanceMode"
                :class="{ fixed: fixedHomeSearch }"
              />
              <div class="pl-6 mt-4 text-h7 text-md-h5 white--text">
                Get the most accurate property valuation available in less than
                a minute
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="faq-section" id="faq">
      <v-container style="max-width: 1200px">
        <v-row>
          <v-col>
            <article>
              <div class="mb-8">
                <h1 class="text-center text-h2">What Is eHomeFacts?</h1>
              </div>
              <div class="px-4">
                <p>
                  eHomeFacts is a website that was designed specifically for
                  real estate investors. It utilizes up-to-date M.L.S. data,
                  property appraiser data, G.I.S. & other relevant data sources,
                  in order to provide rapid, accurate resale analysis of single
                  family residences in the Tampa Bay Area.
                </p>
              </div>
            </article>
            <hr class="my-8 mx-8" />
            <article>
              <div class="mb-8">
                <h1 class="text-center text-h4">
                  What Makes Us Different?
                </h1>
              </div>
              <div class="px-4">
                <p>
                  Unlike other real estate valuation websites, eHomeFacts allows
                  users to not only inspect the comparable properties used in
                  the valuation process, but it also enables users to easily
                  fine tune their resale price estimates in order to deliver the
                  BEST possible estimations of After Repair Values (A.R.V.).
                </p>
                <p>
                  In addition to compiling all of the best real estate data
                  sources into one simple search on one simple website,
                  eHomeFacts is striving to provide an ever growing list of
                  tools to assist in the home flipping process. Our 'Rehab
                  Calculator' is set up to quickly and easily provide cost
                  estimations for investors who are evaluating the investment
                  potential of a property.
                </p>
              </div>
            </article>
            <hr class="my-8 mx-8" />
            <article>
              <div class="mb-8">
                <h1 class="text-center text-h4">
                  Where Does eHomeFacts Work?
                </h1>
              </div>
              <div class="px-4">
                <p>
                  Currently eHomeFacts covers the Hillsborough, Pinellas, Pasco,
                  and Hernando County areas. At the present time it is ONLY set
                  up to function with Single Family Residences, with ONE
                  dwelling per land parcel. It is NOT yet eqquipped to handle
                  mutli-family homes (duplexes, triplex, quadplexes, etc) or
                  homes with detatched inlaw suites. While SFH's make up the
                  overwhelming majority of properties in any given region, we
                  are nevertheless working to expand our capabilities to include
                  all of the aforementioned property types as well as to expand
                  our geographical coverage area.
                </p>
                <p>
                  To get started, simply enter the address of a single family
                  residence in the Greater Tampa Bay Area, and hit the 'Search'
                  key.
                </p>
              </div>
            </article>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="how-it-works-section" id="how-it-works">
      <v-container style="max-width: 1200px">
        <v-row>
          <v-col>
            <h1 class="text-center text-h3 mb-4">How It Works</h1>
            <ol class="text-h6">
              <li>
                Sign Up for a <strong>FREE</strong> no obligation Pro User
                Account and Sign In.
              </li>
              <li>
                Use the Search Bar to locate the single-family home that you
                want to evaluate
              </li>
              <li>
                Sit tight as we compile property data on the recent sales in
                that particular area.
              </li>
              <li>
                Review the results! Our algorithm aims to identify the
                properties that are most comparable to the subject property and
                use them to extrapolate an estimated After Repair Value (ARV).
                Each of the “Suggested Comps” will be factored into the overall
                ARV estimate.
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="about-section" id="about">
      <v-container style="max-width: 1200px">
        <v-row>
          <v-col>
            <h1 class="text-center text-h3 mb-4">About Us</h1>
            <p>
              The eHomeFacts journey initially began in the years following the
              foreclosure crisis. Cofounders Nick Colamarino and Joe Kuersteiner
              started out buying distressed properties and reselling them to
              local investors. Over the years to come their business evolved to
              include a retail brokerage, a growing rental property portfolio
              and a large a large volume of renovation/resale projects as well
              as new construction projects.
            </p>
            <p>
              They realized over the years that manually evaluating a large
              number of houses each day was frustrating, time consuming, and
              reliant on maintaining a number of costly property data
              subscription services. The need to find a cheaper/easier way to
              instantly and accurately value real estate was the driving force
              behind eHomeFacts. In 2019 Nick and Joe teamed up with Geoff Wall,
              a software engineer, to bring the current version to life.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="team-section" id="team">
      <v-container style="max-width: 1200px">
        <v-row>
          <v-col>
            <h1 class="text-h3 text-center">Meet the Team</h1>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-card class="profile-card">
              <v-card-text>
                <v-avatar size="125">
                  <img
                    class="profile-avatar"
                    alt=""
                    src="@/assets/images/profiles/nick.jpg"
                  />
                </v-avatar>

                <div class="name mt-4">Nick</div>

                <div class="profile-bio">
                  Nick grew up in Orlando before attending Florida State
                  University. After graduation, he moved to New York to pursue a
                  career as an analyst at a Fortune 500 company in Manhattan.
                  His interest in real estate brought him back to Florida just
                  before the market crashed in 2010. He’s been involved in over
                  1,000 transactions since then, and he’s still just as
                  passionate about learning and sharing knowledge about the real
                  estate industry as he was on day 1.
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="profile-card">
              <v-card-text>
                <v-avatar size="125">
                  <img
                    class="profile-avatar"
                    alt=""
                    src="@/assets/images/profiles/joe.jpg"
                  />
                </v-avatar>

                <div class="name mt-4">Joseph</div>

                <div class="profile-bio">
                  Joseph, grew up in the Florida Keys and earned his
                  undergraduate degree from Florida State University. After
                  college he moved overseas to work with a mineral exploration
                  company in the Philippines before returning to Florida to
                  pursue his interest in real estate investing. During the past
                  decade he has bought and sold hundreds of properties and has
                  continued to stay active in the market.
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card class="profile-card">
              <v-card-text>
                <v-avatar size="125">
                  <img
                    class="profile-avatar"
                    alt=""
                    src="@/assets/images/profiles/geoff.jpg"
                  />
                </v-avatar>

                <div class="name mt-4">Geoff</div>

                <div class="profile-bio">
                  Geoff grew up in the Florida Keys before attending Florida
                  State University where he received a degree in electrical
                  engineering. He then worked for a number of years on various
                  defense contracting projects and spent his free time
                  developing his skills as a software engineer. He is currently
                  managing a team of engineers in the San Francisco Bay area who
                  are currently working on complex image processing projects.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section class="contact-section" id="contact">
      <v-container style="max-width: 1200px">
        <v-row>
          <v-col>
            <h1 class="text-h3 my-4 text-center">Contact Us</h1>
            <v-form
              lazy-validation
              v-model="valid"
              @submit.prevent="submit"
              ref="contactForm"
            >
              <v-text-field
                v-model="contactName"
                label="Enter your name"
                placeholder="Enter your name"
                :rules="nameRules"
                solo
              ></v-text-field>

              <v-text-field
                v-model="contactEmail"
                label="Enter your email"
                placeholder="Enter your email"
                :rules="emailRules"
                solo
              ></v-text-field>

              <v-textarea
                v-model="contactSubject"
                label="Enter your message"
                placeholder="Enter your message"
                :rules="messageRules"
                solo
              ></v-textarea>

              <v-btn
                style="float: right; color: white"
                color="primary"
                :disabled="!valid"
                :href="mailto"
              >
                submit
              </v-btn>

              <v-btn @click="clear">
                clear
              </v-btn>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <footer class="footer" id="footer">
      <v-container style="max-width: 1200px">
        <v-row align="center">
          <v-col cols="6" md="4">
            <div>
              <ul>
                <li><a href="#home">home</a></li>
                <li><a href="#how-it-works">how it works</a></li>
                <li><a href="#about">about</a></li>
                <li><a href="#team">team</a></li>
                <li><a href="#contact">contact</a></li>
              </ul>
            </div>
          </v-col>
          <v-col cols="6" md="4">
            <div>
              <ul>
                <li>
                  <a target="_blank" href="https://www.facebook.com/eHomeFacts"
                    ><v-icon>mdi-facebook</v-icon></a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/ehomefacts/"
                    ><v-icon>mdi-instagram</v-icon></a
                  >
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/ehomefacts/"
                    ><v-icon>mdi-linkedin</v-icon></a
                  >
                </li>
              </ul>
            </div>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              802 E Whiting St, Ste#144
              <br />
              Tampa, FL 33602
            </div>
          </v-col>
        </v-row>
      </v-container>
    </footer>

    <v-dialog v-model="emailConfirmed" width="500">
      <v-card>
        <v-card-title class="text-h5 primary">
          Email has been verified
        </v-card-title>
        <v-card-text>
          <v-container>
            <h2>Your email has been verified and you can now login</h2>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="emailConfirmed = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HomeSearch from "@/components/Shared/HomeSearch";

export default {
  name: "Home",
  components: {
    HomeSearch
  },
  data() {
    return {
      fixedHomeSearch: false,
      emailConfirmed: false,
      valid: false,
      contactName: "",
      contactEmail: "",
      contactSubject: "",
      nameRules: [v => !!v || "Name is required"],
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || "Email must be valid"
      ],
      messageRules: [v => !!v || "Message is required"],
      maintenanceMode: false
    };
  },
  computed: {
    logoImage() {
      return "/img/images/ehomefacts-logo.svg";
    },
    heroHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "350px";
        case "md":
          return "500px";
        case "lg":
          return "700px";
        case "xl":
          return "800px";
        default:
          return "500px";
      }
    },
    taglineFontSize() {
      console.log(this.$refs.container);
      return {
        fontSize: "50px"
      };
    },
    mailto() {
      return `mailto:info@ehomefacts.com?subject=${this.contactName}&body=${this.contactSubject}`;
    }
  },
  async mounted() {
    if (this.$route.params.token) {
      const emailVerified = await this.$http.post("verify_email", {
        token: this.$route.params.token
      });
      this.emailConfirmed = emailVerified.data.success;
    }
  },
  methods: {
    submit() {
      this.$refs.contactForm.validate();
    },
    clear() {
      this.$refs.contactForm.reset();
    },
    onScroll(e) {
      const scrollPercent = Math.ceil(
        (window.scrollY / window.innerHeight) * 100
      );

      this.fixedHomeSearch = scrollPercent >= 25;
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-section {
  height: 50vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/images/home3.jpg");
  background-size: cover;
  background-position: center;
}

.fixed {
  position: fixed;
  top: 80px;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 500px;
  // border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

hr {
  background: linear-gradient(
    273deg,
    var(--v-primary-base),
    var(--v-secondary-base)
  );
  height: 5px;
}

.tagline {
  color: white;
  font-size: calc(50px);
  font-weight: 900;
}

.tagline_subtitle {
  color: white;
  font-weight: 900;
  font-size: 2.5vw;
}

.text-outline {
  text-shadow: 0.01em 0 black, 0 0.01em black, -0.01em 0 black, 0 -0.01em black,
    -0.01em -0.01em black, -0.01em 0.01em black, 0.01em -0.01em black,
    0.01em 0.01em black;
}

.faq-section {
  padding-top: 1rem;
}

section:nth-child(odd):not(:first-of-type) {
  color: #fff;
  background: var(--v-primary-base);
}

.how-it-works-section {
  padding: 1rem 0;

  ol {
    li {
      margin: 1rem;
    }
  }
}

.about-section {
  padding: 1rem 0;

  p {
    font-size: 18px;
  }

  h1 {
    font-size: 2rem;
  }
}

.team-section {
  padding: 1rem 0;

  .profile-card {
    text-align: center;
    height: 100%;

    .name {
      color: black;
      font-weight: bolder;
      font-size: 18px;
      margin-bottom: 6px;
    }

    .job-title {
      color: black;
      font-size: 14px;
      margin-bottom: 6px;
    }

    .profile-bio {
      padding: 0 1rem;
    }
  }
}

.contact-section {
  height: 85vh;
  h1 {
    font-size: 2rem;
  }
}

.footer {
  background: #f5f5f5;
  padding: 1rem 0 2rem;
  color: #aaa;
  text-align: center;

  a {
    font-size: 14px;
    color: #aaa;
    text-decoration: none;
    text-transform: capitalize;

    &:hover {
      color: black;
    }
  }

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }

    li {
      padding-bottom: 8px;
    }
  }
}
</style>
