<template>
  <div class="images-view-container">
    <div v-if="hasImages" :class="mainImageSectionClasses">
      <v-carousel hide-delimiters v-model="selectedImageIndex">
        <v-carousel-item
          v-for="(item, i) in selectedPropertyImages"
          :key="i"
          :src="item"
          class="main-carousel"
          @click="propertyModalOpen = true"
        />
      </v-carousel>
      <div class="image-index-label">
        {{ indexLabel }}
      </div>
    </div>
    <div v-if="!hideSide && hasImages" class="detail-images-section">
      <v-img
        v-for="(item, index) in selectedPropertyImages"
        :key="index"
        :src="item"
        :class="getDetailImageItemClass(index)"
        aspect-ratio="1"
        @click.prevent="onItemSelect(index)"
      />
    </div>
    <div v-if="!hasImages" class="no-image-wrapper">
      <h2 class="no-realtor-text">
        No Realtor Photos Available For This Property
      </h2>
    </div>

    <property-detail-modal
      v-if="propertyModalOpen"
      :dialog="propertyModalOpen"
      :data="propertyDetails"
      :selectedImage="selectedImageIndex"
      @modal-close="propertyModalOpen = false"
    />
  </div>
</template>

<script>
export default {
  name: "ImagesView",
  components: {
    PropertyDetailModal: () => import("./PropertyDetailModal")
  },
  props: {
    propertyDetails: Object,
    hideSide: Boolean,
    images: Array
  },
  data: () => ({
    selectedImageIndex: 0,
    propertyModalOpen: false
  }),
  computed: {
    indexLabel() {
      return `${this.selectedImageIndex + 1} of ${
        this.selectedPropertyImages.length
      }`;
    },
    mainImageSectionClasses() {
      return this.hideSide
        ? "main-image-section--hide-side"
        : "main-image-section";
    },
    selectedPropertyImages() {
      return this.images;
    },
    hasImages() {
      return this.selectedPropertyImages.length > 0;
    }
  },
  mounted() {
    if (!this.selectedPropertyImage) {
      this.selectedImageIndex = 0;
      this.setImage(0);
    } else {
      this.selectedImageIndex = this.selectedPropertyImages[
        this.selectedPropertyImage.index
      ];
    }
  },
  methods: {
    getDetailImageItemClass(index) {
      let itemClass = "detail-image-item";
      itemClass =
        index === this.selectedImageIndex
          ? `${itemClass}--selected`
          : itemClass;
      return itemClass;
    },
    onItemSelect(index) {
      this.selectedImageIndex = index;
    },
    setImage(index) {
      this.$store
        .dispatch("homesStore/setSelectedPropertyImage", {
          index: index,
          src: this.selectedPropertyImages[index]
        })
        .then(() => {
          //
        })
        .catch(err => {
          console.error("-- Set Selected Properties Image Error --", err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.images-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;

  .main-image-section {
    width: 75%;
    position: relative;

    &--hide-side {
      width: 100%;
    }
  }

  .image-index-label {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .detail-images-section {
    width: 25%;
    overflow: scroll;
    padding-left: 10px;
    margin-bottom: -20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .detail-image-item {
    width: 100%;
    height: auto;
    margin-bottom: 10px;

    &--selected {
      border: solid 3px #74cfe7;
      box-sizing: border-box;
      margin-bottom: 10px;
    }
  }

  .no-image-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid inside 1px #9b9b9b;
    background-image: linear-gradient(298deg, #03b6da, #6348c6);

    .no-realtor-text {
      font-family: "Helvetica Neue Bold";
      font-size: 30px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #5c5e62;
    }
  }
}

::v-deep {
  .v-carousel {
    height: 100% !important;
  }

  .v-carousel__item {
    height: 100% !important;
  }
}
</style>
