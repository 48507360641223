<template>
  <div class="slider-loader d-flex justify-space-around">
    <div class="vertical-line">
      <div class="slider slider-1"></div>
    </div>
    <div class="vertical-line">
      <div class="slider slider-2"></div>
    </div>
    <div class="vertical-line">
      <div class="slider slider-3"></div>
    </div>
    <div class="vertical-line">
      <div class="slider slider-4"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderLoader"
};
</script>

<style lang="scss" scoped>
.slider-loader {
  width: 200px;
  height: 150px;
}

.vertical-line {
  width: 10px;
  height: 100%;
  border-radius: 20px;
  background-image: linear-gradient(273deg, #03b6da, #6348c6);
  position: relative;
}

.slider {
  width: 40px;
  height: 25px;
  border: 5px solid var(--v-secondary-base);
  background: white;
  position: absolute;
  left: -15px;
  bottom: calc(100% - 35px);
}

.slider-1 {
  animation: top-to-bottom 2s ease-in-out infinite alternate 1s;
}

.slider-2 {
  animation: top-to-bottom 2s ease-in-out infinite alternate 0.5s;
}

.slider-3 {
  animation: top-to-bottom 2s ease-in-out infinite alternate 0s;
}

.slider-4 {
  animation: top-to-bottom 2s ease-in-out infinite alternate 0.8s;
}

@keyframes top-to-bottom {
  100% {
    bottom: 10px;
  }
}
</style>
