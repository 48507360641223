<template>
  <div>
    <section class="hero-section" :style="{ height: heroHeight }">
      <v-container fill-height style="max-width: 1200px">
        <v-row align="center">
          <v-col>
            <div>
              <v-img :src="logoImage" max-width="900px" />
              <div class="pl-6 my-4 text-h6 text-md-h4 white--text">
                Find out how much that home is really worth.
              </div>
            </div>
            <div>
              <HomeSearch height="60" />
              <div class="pl-6 mt-4 text-h7 text-md-h5 white--text">
                Get the most accurate property valuation available in less than
                a minute.
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-container>
      <v-row>
        <v-col>
          <v-sheet class="py-4 ma-auto" max-width="1200">
            <article>
              <div class="mb-8">
                <h1 class="text-center text-h3">What is eHomefacts?</h1>
              </div>
              <div class="px-4">
                <p>
                  eHomefacts is a website that was designed specifically for
                  real estate investors. It utilizes up-to-date M.L.S. data,
                  property appraiser data, G.I.S. & other relevant data sources,
                  in order to provide rapid, accurate resale analysis of single
                  family residences in the Tampa Bay Area.
                </p>
              </div>
            </article>

            <hr class="my-8 mx-8" />

            <article>
              <div class="mb-8">
                <h1 class="text-center text-h3">
                  What makes us different?
                </h1>
              </div>
              <div class="px-4">
                <p>
                  Unlike other real estate valuation websites, eHomefacts allows
                  users to not only inspect the comparable properties used in
                  the valuation process, but it also enables users to easily
                  fine tune their resale price estimates in order to deliver the
                  BEST possible estimations of After Repair Values (A.R.V.).
                </p>

                <p>
                  In addition to compiling all of the best real estate data
                  sources into one simple search on one simple website,
                  eHomefacts is striving to provide an ever growing list of
                  tools to assist in the home flipping process. Our 'Rehab
                  Calculator' is set up to quickly and easily provide cost
                  estimations for investors who are evaluating the investment
                  potential of a property.
                </p>
              </div>
            </article>

            <hr class="my-8 mx-8" />

            <article>
              <div class="mb-8">
                <h1 class="text-center text-h3">
                  Where does eHomefacts work?
                </h1>
              </div>
              <div class="px-4">
                <p>
                  Currently eHomeFacts covers the Hillsborough, Pinellas, Pasco,
                  and Hernando County areas. At the present time it is ONLY set
                  up to function with Single Family Residences, with ONE
                  dwelling per land parcel. It is NOT yet eqquipped to handle
                  mutli-family homes (duplexes, triplex, quadplexes, etc) or
                  homes with detatched inlaw suites. While SFH's make up the
                  overwhelming majority of properties in any given region, we
                  are nevertheless working to expand our capabilities to include
                  all of the aforementioned property types as well as to expand
                  our geographical coverage area.
                </p>

                <p>
                  To get started, simply enter the address of a single family
                  residence in the Greater Tampa Bay Area, and hit the 'Search'
                  key.
                </p>
              </div>
            </article>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
    <ProLandingModal v-model="openProLandingDialog" />
  </div>
</template>

<script>
import HomeSearch from "@/components/Shared/HomeSearch";
import ProLandingModal from "@/components/ProLandingModal";

export default {
  name: "ProLanding",
  components: {
    HomeSearch,
    ProLandingModal
  },
  data() {
    return {
      openProLandingDialog: true
    };
  },
  computed: {
    logoImage() {
      return "/img/images/ehomefacts-logo.svg";
    },
    heroHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          return "400px";
        case "md":
          return "500px";
        case "lg":
          return "700px";
        case "xl":
          return "800px";
        default:
          return "500px";
      }
    }
  },
  async mounted() {
    if (this.$route.params.token) {
      const emailVerified = await this.$http.post("verify_email", {
        token: this.$route.params.token
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-section {
  height: 50vh;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../assets/images/home3.jpg");
  background-size: cover;
  background-position: center;
}

hr {
  background: linear-gradient(
    273deg,
    var(--v-primary-base),
    var(--v-secondary-base)
  );
  height: 5px;
}
</style>
