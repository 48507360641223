<template>
  <v-container class="pb-12">
    <v-row>
      <v-col>
        <h1>EHOME FACTS TERMS OF USE</h1>
        <p>
          This Terms and Conditions of Use is a binding agreement between you
          and eHomeFacts Inc and its affiliated companies (collectively, “eHome
          Facts”, “we”, “us”, or “our”). By using our Services, you agree to
          comply with and be bound by the following terms and conditions of use.
          By “Services,” we mean all products and services owned and operated by
          eHome Facts, including the content, features, data, and software made
          available through www.ehomefacts.com (the “Site”), our mobile
          applications, and other websites or applications we operate. Please
          review these terms and conditions carefully. If you do not agree to
          these terms and conditions, you should not use this site.
        </p>

        <ol>
          <li>
            <p>
              Agreement. This Term of Use agreement (the "Agreement") specifies
              the Terms and Conditions for access to and use of
              www.ehomefacts.com and describe the terms and conditions
              applicable to your access of and use of the Site. This Agreement
              may be modified at any time by eHome Facts upon posting of the
              modified Agreement. Any such modifications shall be effective
              immediately. You can view the most recent version of these terms
              at any time at www.ehomefacts.com. Each use by you shall
              constitute and be deemed your unconditional acceptance of this
              Agreement.
            </p>
          </li>
          <li>
            <p>
              Important Information About this Website. THE INFORMATION WE
              PROVIDE IN CONNECTION WITH AN OFFER, OR OTHERWISE IN CONNECTION
              WITH YOUR USE OF THIS SITE IS NOT INTENDED TO SUBSTITUTE FOR
              PROFESSIONAL LEGAL, FINANCIAL, TAX, OR REAL ESTATE ADVICE. YOU
              SHOULD ALWAYS CONSULT PROFESSIONAL ADVISORS BEFORE MAKING REAL
              ESTATE DECISIONS. UNLESS OTHERWISE SET FORTH IN A WRITTEN DOCUMENT
              SIGNED BY EHOME FACTS, EHOME FACTS IS ACTING AS A PRINCIPAL ON ITS
              OWN BEHALF AS A POTENTIAL BUYER. IT IS REPRESENTING ITS OWN
              INTERESTS AND DOES NOT REPRESENT YOU IN ANY SALE TRANSACTION. ALL
              INFORMATION PROVIDED ON OR THROUGH THE WEBSITE, INCLUDING, WITHOUT
              LIMITATION, THE ESTIMATED VALUE OF YOUR PROPERTY, IS FOR
              CONVENIENCE ONLY AND WITHOUT EXPRESS OR IMPLIED WARRANTY AS TO ITS
              ACCURACY OR VALIDITY. RELIANCE ON SUCH INFORMATION TO ENTER INTO
              ANY REAL ESTATE TRANSACTION IS SOLELY AT YOUR OWN RISK. WE
              RECOMMEND THAT YOU CONSULT RELIABLE, INDEPENDENT INFORMATION
              SOURCES TO PROVIDE YOU INFORMATION ON WHICH TO RELY. IF YOU HAVE
              ANY QUESTIONS IN THIS REGARD, YOU SHOULD CONSULT YOUR LEGAL OR
              OTHER PROFESSIONAL ADVISORS.
            </p>
          </li>
          <li>
            <p>
              Privacy. Your visit to our site is also governed by our Privacy
              Policy. Please review our Privacy Policy at
              www.ehomefacts.com/privacy-policy We reserve the right to amend
              the Privacy Policy at any time without prior notice to you.
            </p>
          </li>
          <li>
            <p>
              Ownership. The Site, including all features, functionality, tools
              and content thereof and available through our Services, including
              but not limited to text, images, audio, video, interfaces,
              information, data, and computer code, are and shall continue to be
              the property of eHome Facts or its content suppliers and is
              protected under applicable copyright, patent, trademark, and other
              proprietary rights. You acknowledge and agree that the Site, and
              all intellectual property rights therein are the exclusive
              property of eHome Facts. You may not use, copy, reproduce,
              distribute, license, sell, transfer, publish, post, publicly
              display, publicly perform, transmit, broadcast, adapt, modify,
              prepare derivative works based upon, or otherwise exploit any
              features, functionality, tools or content of the Website in any
              form or by any means, or sublicense the rights granted in the
              Terms, except as expressly permitted herein, without the prior
              written permission of eHome Facts. Under no circumstances will you
              acquire any ownership rights or other interest in any content by
              or through your use of this Site.
            </p>
          </li>
          <li>
            <p>
              Intended Audience. This website is intended for adults only. This
              website is not intended for any children under the age of 13.
            </p>
          </li>
          <li>
            <p>
              Trademarks. The “eHome Facts” trademark and others are either
              trademarks or registered trademarks of eHome Facts. Other product
              and company names mentioned on this Site may be trademarks of
              their respective owners.
            </p>
          </li>
          <li>
            <p>
              Site Use. eHome Facts grants you a limited, revocable,
              nonexclusive, nontransferable license to use the Site solely for
              your own personal use and not for republication, distribution,
              assignment, sublicense, sale, preparation of derivative works, or
              other use. eHome Facts reserves all right, title and interest not
              expressly granted under this license to the fullest extent
              permitted by law. Any use of our Services not expressly permitted
              under this Agreement is strictly prohibited. You agree not to copy
              materials on the site, reverse engineer or break into the site, or
              use materials, products or services in violation of any law. The
              use of this website is at the discretion of eHome Facts and eHome
              Facts may terminate your use of this website at any time. You
              agree not to sublicense, assign, or transfer the license granted
              to you under this Agreement. Any attempt to sublicense, assign, or
              transfer any of the rights, duties, or obligations in violation of
              the provisions of this Agreement is void.
            </p>
          </li>
          <li>
            <p>
              Registered Users. We reserve the right to restrict certain of our
              Services to registered users. In the event that any of our
              Services requires you to register prior to our granting you
              access, you agree to provide accurate, current, and complete
              account information, including but not limited to your name,
              address, telephone number, and email address, and to update this
              information as necessary to keep it accurate, current, and
              complete. You may not sell, transfer, or assign your account with
              us to anyone else. You are responsible for maintaining the
              confidentiality of your account name and password, and for all
              activities that occur while logged in under your account. You
              agree to notify us immediately at info@ehomefacts.com.
            </p>
          </li>
          <li>
            <p>
              Other Restrictions. You agree that you will not use our Services
              to: (1) impersonate any person or entity or misrepresent your
              affiliation with any person or entity; (b) engage in spamming,
              flooding, harvesting of email addresses or other personal
              information, spidering, screen scraping, database scraping, or any
              other activity with the purpose of obtaining lists of users or any
              other information, including specifically, property listings
              available through our Services; (c) attempt to decipher,
              decompile, disassemble or reverse engineer any of the computer
              code comprising or in any way making up a part of our Services;
              (d) violate any applicable local, state, national or international
              law; € send chain letters or pyramid schemes via our Services; or
              (f) attempt to gain unauthorized access to other computer systems
              through out Services.
            </p>
            <p>
              You agree that you will not use our Services in any manner that
              could damage, disable, overburden, or impair our Services or
              interfere with any other party’s use and enjoyment of our
              Services.
            </p>
          </li>
          <li>
            <p>
              10. Submissions. If you submit to us or post through our Services
              any property listing, testimonial, comment, review, suggestion, or
              any work of authorship (collectively, a “submission”) including,
              without limitation, submissions about any of our products or
              services, such submission may be used by us in any manner. Please
              do not submit or send any submission to us that you consider
              contains confidential or proprietary information. No submission
              sent to us will be considered or treated as confidential
              information. We do not pre-screen submissions and we will have no
              obligation to read any particular submission submitted or sent to
              us.
            </p>
            <p>
              By Submitting or sending a submission to us, you: (a) represent
              and warrant that the submission is original to you, that no other
              party has any rights thereto, and that any moral rights in such
              submission have been waived, and (b) grant us royalty-free,
              unrestricted, worldwide, perpetual, irrevocable, non-exclusive and
              fully transferable, assignable and sublicensable right and license
              to use, reproduce, publish, distribute, display, translate,
              summarize, modify and adapt such submission (in whole or part)
              and/or to incorporate it in other works in any form, media, or
              technology now known or later developed, in our sole discretion,
              with or without your name.
            </p>
          </li>
          <li>
            <p>
              No Endorsement. The Site may provide links to third-party
              websites, resources, or services. You acknowledge and agree that
              eHome Facts is not responsible or liable for (i) the availability,
              terms or practices of such websites, resources or services, or
              (ii) the content, products or services available on or through
              such websites, resources or services, including that any
              information provided is complete, accurate or up-to-date. Links to
              such websites, resources or services do not imply any endorsement
              by eHome Facts of such websites, resources or services or the
              content, products, or services available on or through such
              websites, resources, or services. You acknowledge that you have
              sole responsibility for and assume all risk arising from your use
              of any such websites, resources or services or the content,
              products, or services available on or through such websites or
              services.
            </p>
            <p>
              We will not be responsible or liable for any damage or harm
              resulting from your interactions with such websites or services,
              or the content, products, or services available on or through such
              websites or services.
            </p>
          </li>
          <li>
            <p>Offer & Purchase Process.</p>
            <ol type="a">
              <li>
                <p>
                  Offers. You may not request an offer: (i) if you are not able
                  to form legally binding contracts (for example, if you are
                  under the age of 18); or (ii) if you are barred or prohibited
                  from entering into contracts or conducting real estate
                  transactions under the laws of the United States or other
                  applicable jurisdiction.
                </p>
                <p>
                  To receive an offer, you must first create a seller account
                  and provide your first name, last name, property address and
                  email address. Additionally, you must acknowledge and accept
                  the Terms of Service and verify your email address. You will
                  then be directed to answer a series of questions regarding the
                  condition of the property which information will be utilized
                  in determining the amount of the offer. If you elect to
                  request an offer, you agree to provide complete, accurate and
                  up-to-date information about yourself and your property, and
                  to update that information as necessary to ensure that it
                  remains complete, accurate and up-to-date. Please note that
                  your name, address and other information that you submit when
                  you request an offer may be used and shared with our third
                  party service providers in accordance with our Privacy Policy.
                  By requesting an offer, you give us and our third party
                  service providers permission to contact you using your name,
                  address, and other information submitted when requesting an
                  offer.
                </p>
                <p>
                  We are under no obligation to make an offer for any property.
                  Additionally, we do not warrant the accuracy of the valuation
                  and cannot be held liable if it is incorrect. You can accept
                  the offer by clicking THE BUTTON, an eHome Facts agent will
                  prepare a Purchase Agreement with the necessary terms and send
                  the Purchase Agreement to the email address that you provide.
                  The eHome Facts agent will reach out to you via the telephone
                  number you provide in order to finalize the details and terms
                  of the Purchase Agreement.
                </p>
                <p>
                  We reserve the right to revoke any offer at our discretion,
                  prior to it being accepted, by notifying you of the revocation
                  by an e-mail which shall be deemed delivered at the time sent
                  by eHome Facts to your e-mail address. Once an offer expires
                  or is revoked, you will not be able to accept the offer. The
                  offer that you receive may be subject to certain terms and
                  conditions being met. The offer may include a purchase
                  addendum, this purchase addendum will supersede the purchase
                  contract. The offer is not an appraisal. It is the purchase
                  price eHome Facts is willing to pay for your home, subject to
                  additional terms, conditions and fees outlined in the offer.
                </p>
              </li>
              <li>
                <p>
                  Purchase Price, Costs and Fees. If we elect to make an offer
                  for your property, the offer will identify the purchase price
                  that we are willing to pay.
                </p>
                <p>
                  Please note that we determine the offer price by analyzing
                  various data sources, including information provided by you.
                  We reserve the right to change the price if we discover that
                  the condition of the house is not consistent with your
                  description, or that the information provided by you is
                  otherwise incomplete or inaccurate. Any such change will
                  require your approval, but if you disapprove, eHome Facts may
                  terminate the sale.
                </p>
                <p>
                  eHome Facts makes no representations or warranties that the
                  purchase price we offer will equal or exceed the fair market
                  value or the price you would obtain by listing your house with
                  a real estate agent.
                </p>
              </li>
              <li>
                <p>
                  Closing Costs, Taxes and Fees. The Purchase Agreement will
                  govern the closing costs, including title search, tile
                  insurance, transfer taxes, and recording. You will be
                  responsible to pay any title search, title insurance and
                  escrow fees, and any other additional closing costs, in a
                  manner customary in the county where the property is located,
                  as determined by the escrow company or closing attorney, as
                  applicable. Examples of such costs include (but are not
                  limited to) loan payoff costs, transfer taxes, recording fees,
                  attorney fees, liens or judgments against your property,
                  prorated homeowner association dues and/or property taxes due
                  through the closing date. In addition, if you have an existing
                  listing agreement with a real estate agent, you will be
                  responsible for determining any amount due to your agent and
                  deducting their commission from your net proceeds after you
                  close. eHome Facts does not pay or accept commissions, except
                  as disclosed in writing prior to your acceptance of the offer.
                </p>
              </li>
              <li>
                <p>
                  Purchase Agreement. The Purchase Agreement will be negotiated
                  between you and an eHome Facts Agent after the offer is
                  accepted. To be clear, the offer does not constitute the
                  Purchase Agreement or contain all of terms and conditions of
                  the sale. The Purchase Agreement will govern the terms of the
                  sale and any recourse. You must accept the Purchase Agreement
                  in order to proceed with the sale.
                </p>
                <p>
                  After you have reviewed and accepted the terms of the Purchase
                  Agreement in the manner directed by the eHome Facts Agent,
                  eHome Facts will deposit the earnest money as more fully set
                  forth in the Terms of the Purchase Agreement. The escrow agent
                  or closing attorney, as applicable, will then send you a
                  receipt confirming the deposit, and we will contact you to
                  schedule your home inspection.
                </p>
                <p>
                  We reserve the right to terminate the Purchase Agreement
                  during the inspection period set forth below in our sole
                  discretion for any discoveries made during the inspection
                  period including, but not limited to, the condition of the
                  house being inconsistent with your description, or that the
                  information provided by you is otherwise inaccurate.
                </p>
              </li>
              <li>
                <p>
                  Inspection. The inspection period begins the day after the
                  contract has been signed by both parties and continues for the
                  period set forth in the Purchase Agreement, unless otherwise
                  agreed in writing by the parties.
                </p>
                <p>
                  The inspection will be conducted by an eHome Facts
                  representative. You must be home during the inspection, which
                  typically takes less than 2 hours.
                </p>
              </li>
              <li>
                <p>
                  Walk-Through. One day prior to the closing date, eHome Facts
                  will conduct a final walk-through to verify that the required
                  repairs have been adequately made, and that the property is
                  otherwise still in the same condition it was during the
                  inspection. If the walk-through reveals that required repairs
                  have not been adequately completed or the property is not
                  otherwise still in the same condition it was during the
                  inspection, additional repairs may be required and the closing
                  may be delayed.
                </p>
              </li>
              <li>
                <p>
                  Closing. Closing will be scheduled for the date agreed to in
                  the Purchase Agreement. If you subsequently need to change
                  your closing date, you must notify us in writing as soon as
                  reasonably possible. We will accommodate change requests, when
                  reasonably practicable to do so as determined by us in our
                  sole discretion. You will receive a check or a wire transfer
                  for the purchase price (less any applicable fees) at closing.
                  Please be advised that cash offers are not available for any
                  purchase.
                </p>
              </li>
              <li>
                <p>
                  Moving Out. You are responsible to leave the property in the
                  same condition it was in during the walk-through. You are also
                  required, as a general rule, to leave any built-in appliances
                  and other fixtures (items that are attached in some way to the
                  property) unless otherwise agreed in writing. These include
                  (but are not limited to) your dishwasher, built-in microwave,
                  stove, oven, cooktops, ice machines, trash compactors, water
                  softeners, built-in refrigerator, R/O systems, window
                  treatments, central vacuum and central vacuum hoses, garage
                  door openers and remotes, pool equipment, built-in BBQ’s,
                  built-in fire pits, ceiling fans, light fixtures (including,
                  but not limited to, chandeliers), pool fencing, pool cleaning
                  systems, exterior landscape lighting and surround sound
                  speakers.
                </p>
                <p>
                  Depending on the location of your property, there may be some
                  appliances that you are entitled to take when you move. YOU
                  SHOULD CONSULT YOUR LEGAL OR OTHER PROFESSIONAL ADVISORS TO
                  ENSURE THAT YOU HAVE A CURRENT AND COMPLETE LIST.
                </p>
                <p>
                  You will be responsible for the cost to repair any damage
                  caused after the walk-through and replace any appliances and
                  other fixtures that you are not entitled to take.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Compliance with Laws. You agree to comply with all applicable laws
              regarding your use of the website and Services. You further agreed
              that information provided by you is truthful and accurate to the
              best of your knowledge. eHome Facts reserves the right to monitor
              access and use of the Site, and investigate and prosecute
              violations of any and all reports, complaints and claims, or
              otherwise suspected misconduct or violations of the law or the
              terms of this Agreement to the fullest extent of the law.
            </p>
          </li>
          <li>
            <p>
              Infringement. You agree that you will not use our Services in any
              manner that infringes the rights of any third party. If you are a
              copyright holder and believe your work has been used in our
              Services in a way that constitutes copyright infringement, please
              send a notice of infringement under the Digital Millennium
              Copyright Act (“DMCA”) to:
            </p>
            <p>
              eHome Facts Inc.<br />
              238 E. Davis Blvd, Suite 206<br />
              Tampa, Florida 33606<br />
              727-475-0107<br />
              info@ehomefacts.com
            </p>
          </li>
          <li>
            <p>
              Indemnification. You agree to indemnify, defend and hold eHome
              Facts and our officers, directors, partners, employees,
              affiliates, agents and licensors harmless from and against any and
              all liability, loss, claim, expense, damage and costs, including
              reasonable attorney's fees, arising out of or in any way related
              to (i) your violation of this Agreement or any applicable law;
              (ii) your access, use, or misuse of the Site, our Services, or
              Content and/or (iii) any infringement by you of the copyright or
              intellectual property rights of any third party. You must not
              settle any such claim or matter without the prior written consent
              of eHome Facts. We reserve the right, at our own expense, to
              assume the exclusive defense and control of any claim subject to
              indemnification by you, and you further agree that you will
              cooperate fully in the defense of any such claims.
            </p>
          </li>
          <li>
            <p>Disclaimers</p>
            <ol type="a">
              <li>
                <p>
                  No Warranty. TO THE FULLEST EXTENT PERMITTED BY LAW, OUR
                  SERVICES, OUR CONTENT AND THE INFORMATION ON THIS SITE IS
                  PROVIDED ON AN "AS IS," "AS AVAILABLE" BASIS, WITH ALL FAULTS
                  AND WITHOUTT WARRANTY OF ANY KIND. YOU AGREE THAT USE OF THIS
                  SITE IS AT YOUR SOLE RISK. EHOME FACTS, ON BEHALF OF ITSELF,
                  ITS AFFILIATES, AND THE PROVIDERS, DISCLAIMS ALL WARRANTIES OF
                  ANY KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES,
                  STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET
                  ENJOYMNET, QUALITY, ACCURACY, TITLE AND NON-INFRINGEMENT, AND
                  ANY WARRANTY ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.
                  TO THE EXTENT YOUR JURISDICTION DOES NOT ALLOW LIMITATIONS ON
                  WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU. YOUR SOLE
                  AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE SITE SHALL BE
                  TO DISCONTINUE USING THE SITE.
                </p>
              </li>
              <li>
                <p>
                  Assumption of Risk. YOU USE OUR SERVICES, OUR CONTENT, OR
                  OTHER CONTENT AVAILABLE THROUGH OUR SERVICES, AT YOUR OWN
                  DISCRETION AND RISK. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT
                  MAY RESULT FROM YOUR USE OF OUR SERVICES, OUR CONTENT, OR
                  OTHER CONTENT AVAILABLE THROUGH OUR SERVICES. YOU ARE SOLELY
                  RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING ANY
                  COMPUTER SYSTEM OR MOBILE DEVISE USED IN CONNECTION WITH OUR
                  SERVICES), OR THE LOSS OF DATA THAT MAY RESULT FROM THE USE OF
                  OUR SERVICES, OUR CONTENT, OR OTHER CONTENT AVAILABLE THORUGH
                  OUR SERVICES.ADDITIONALLY, YOU VISIT EHOME FACT’S REAL
                  PROPERTY AND SURROUNDING PREMISES (COLLECTINVELY, THE
                  “PREMISES”) AT YOUR OWN DISCRETION AND RISK. YOU ASSUME ANY
                  AND ALL RISK FOR ANY PROPERTY DAMAGE OR LOSS, OR BODILY,
                  MENTAL, OR PERSONAL INJURY, INCLUDING DEATH, RELATING TO OR
                  ARISING FROM VISITING THE PREMISES.
                </p>
              </li>
              <li>
                <p>
                  Not a Legal or Professional Advisor. You acknowledge and agree
                  that eHome Facts is not engaged in rendering legal, tax or
                  other professional services. If legal, tax or other
                  professional assistance is required, including but not limited
                  to the review of agreements and forms made available to you
                  through our Services, the services of a competent professional
                  should be sought.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Limitation of Liability. UNDER NO CIRCUMSTANCES WILL EHOME FACTS,
              NOR OUR AFFILIATES, NOR ANY OF THEIR RESPECTIVE EMPLOYEES, OR
              AGENTS INVOLVED IN THE CREATION, PRODUCTION AND DISTRIBUTION OF
              OUR SERVICES OR OUR CONTENT, BE LIABLE OR RESPONSIBLE FOR ANY
              DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES
              FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE),
              SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, INJURY, CLAIMS,
              LIABILIY OR OTHER CAUSE OF ACTION UNDER ANY LEGAL THEORY, ARISING
              OUT OF OR IN ANY WAY RELATING TO THE SITE, YOUR SITE USE, THE USE
              OR ATTEMPTED USE OF OUR SERVICES OR THE CONTENT, EVEN IF ADVISED
              OF THE POSSIBILITY OF SUCH DAMAGES. THIS INCLUDES BUT IS NOT
              LIMITED TO ANY CLAIM OR DAMAGE ARISING FROM FAILURE OF
              PERFORMANCE, ERROR, OMISSION, INACCURACY, INTERRUPTION, DELETION,
              DEFECT, DELAY IN OPERATION, COMPUTER VIRUS, SYSTEM FAILURE, THEFT,
              DESTRUCTION, UNAUTHORIZED ACCESS TO OR ALTERATION OF PERSONAL
              RECORDS, OR THE RELIANCE UPON OR USE OF DATA, INFORMATION,
              OPINIONS OR OTHER MATERIALS ACESSED THROUGH OUR SITE, SERVICES OR
              CONTENT. ADDITIONALLY, YOU AGREE THAT EHOME FACTS IS NOT LIABLE OR
              RESPONSIBLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
              THIRD PARTIES. YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE SITE
              AND/OR CONTENT IS TO CEASE ALL OF YOUR SITE USE.
            </p>
            <p>
              You may have additional rights under certain laws (including
              consumer laws) which do not allow the exclusion of implied
              warranties, or the exclusion or limitation of certain damages. If
              these laws apply to you, the exclusions or limitations in this
              Agreement that directly conflict with such laws may not apply to
              you.
            </p>
          </li>
          <li>
            <p>
              Use of Information. eHome Facts reserves the right, and you
              authorize us, to use and assign all information regarding site
              uses by you and all information provided by you in any manner
              consistent with our Privacy Policy.
            </p>
          </li>
          <li>
            <p>
              Copyrights and Copyright Agent. If you believe your work has been
              copied in a way that constitutes copyright infringement, or your
              intellectual property rights have otherwise been violated, please
              provide a notice containing all of the following information to
              our Copyright Agent:
            </p>
            <ol type="a">
              <li>
                <p>
                  An electronic or physical signature of the person authorized
                  to act on behalf of the owner of the copyright or other
                  intellectual property interest;
                </p>
              </li>
              <li>
                <p>
                  A description of the copyrighted work that you claim has been
                  infringed;
                </p>
              </li>
              <li>
                <p>
                  A description of where the material that you claim is
                  infringing is located on the Site;
                </p>
              </li>
              <li>
                <p>
                  Your address, telephone number, and e-mail address;
                </p>
              </li>
              <li>
                <p>
                  A statement by you that you have a good faith belief that the
                  disputed use is not authorized by the copyright owner, its
                  agent, or the law; and
                </p>
              </li>
              <li>
                <p>
                  A statement by you, made under penalty of perjury, that the
                  above information in your notice is accurate and that you are
                  the copyright owner or authorized to act on the copyright
                  owner's behalf.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>Applicable Law.</p>
            <p>
              You agree that the laws of the state of Florida, without regard to
              conflicts of law provisions will govern these Terms and Condition
              of Use and any dispute that may arise between you and eHome Facts
              or its affiliates.
            </p>

            <ol type="a">
              <li>
                <p>
                  Arbitration and Class Action Waiver. All disputes, claims,
                  controversies and matters arising out of or relating to or in
                  connection with this Agreement or the breach, termination,
                  enforcement, interpretation or validity hereof, or to the use
                  of the Site, or our Services or Content (collectively,
                  “Disputes”) shall be exclusively settled by binding
                  arbitration administered by the American Arbitration
                  Association under its Commercial Arbitration Rules (“AAA
                  Rules”), and judgment on the award rendered by the
                  arbitrator(s) may be entered in any court having jurisdiction
                  thereof. The arbitration shall take place in Tampa, FL, USA
                  before a single neutral arbitrator appointed in accordance
                  with the AAA Rules and shall be conducted in the English
                  language. You acknowledge that by agreeing to arbitration as
                  the exclusive forum for the resolution of all Disputes, you
                  are waiving the right to a trial by jury.
                </p>
                <p>
                  All arbitrations shall be conducted and resolved on an
                  individual basis and not a class-wide, multiple plaintiff or
                  similar basis. No arbitration shall be consolidated with any
                  other arbitration proceeding involving any other person or
                  entity.
                </p>
              </li>
              <li>
                <p>
                  Jurisdiction and Venue. In the event that arbitration is not
                  available, you and eHome Facts agree that any and all Disputes
                  will be brought in the federal or state courts located in
                  Tampa, Florida, and each agrees that such courts shall have
                  exclusive jurisdiction and venue for any such actions. Each
                  party consents to the jurisdiction of and venue in such courts
                  and waives any objection as to inconvenient forum.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <p>
              Severability. If any provision of this Agreement shall be adjudged
              by any court of competent jurisdiction to be unenforceable or
              invalid, that provision shall be limited or eliminated to the
              minimum extent necessary so that this Agreement will otherwise
              remain in full force and effect.
            </p>
          </li>
          <li>
            <p>
              Waiver. The failure of eHome Facts to exercise or enforce any
              right or provision of this Agreement shall not operate as a waiver
              of such right or provision. Any waiver of this Agreement by eHome
              Facts must be in writing and signed by an authorized
              representative of eHome Facts.
            </p>
          </li>
          <li>
            <p>
              Termination. eHome Facts may terminate this Agreement at any time,
              with or without notice, for any reason.
            </p>
          </li>
          <li>
            <p>
              Assignment. eHome Facts may assign this Agreement, or certain of
              our rights or obligations under this Agreement, to any party at
              any time without notice to you. You may not assign your rights or
              obligations under this Agreement, by operation of law or
              otherwise, without the prior written consent of eHome Facts.
            </p>
          </li>
          <li>
            <p>
              Consent to be Contacted; Notices. If you provide, or have
              provided, a telephone number to us, you expressly agree that we
              may contact you at that number in order to provide you with
              informational and service messages, including via text message
              and/or by using automated telephone technology. Standard message
              and data rates may apply. You can opt-out of text messages by
              replying “STOP” to the sender. You represent and warrant that you
              are the authorized subscriber for the telephone number(s) you have
              provided. Please be aware that we may record or monitor telephone
              calls for quality assurance. If you provide, or have provided, an
              email or mail address to us, you agree that we may provide notices
              to you through such means. Unless otherwise stated in the notices,
              the notices will become effective immediately.
            </p>
          </li>
          <li>
            <p>
              Relationship of the Parties. Nothing contained in this Agreement
              or your use of the Site shall be construed to constitute either
              party as a partner, joint venturer, employee or agent of the other
              party, nor shall either party hold itself out as such. Neither
              party has any right or authority to incur, assume or create, in
              writing or otherwise, any warranty, liability or other obligation
              of any kind, express or implied, in the name of or on behalf of
              the other party, it being intended by both parties that each shall
              remain independent contractors responsible for its own actions.
            </p>
          </li>
          <li>
            <p>
              Third Party Beneficiaries. Except as otherwise expressly set forth
              herein, the terms of this Agreement do not and are not intended to
              confer any rights or remedies upon any person other than the
              parties hereto.
            </p>
          </li>
          <li>
            <p>
              California Residents. Pursuant to California Civil Code §1789.3,
              California residents are also entitled to the following specific
              consumer rights notice:
            </p>
            <p>The name, and address of the provider of this Website is:</p>
            <p>
              eHome Facts LLC <br />
              238 E. Davis Blvd, Suite 206<br />
              Tampa, Florida 33606<br />
            </p>
            <p>
              Complaints regarding the Website or requests to receive further
              information regarding use of the Website may be sent to the above
              address or to info@ehomefacts.com.
            </p>
            <p>
              The Complaint Assistance Unit of the Division of Consumer Services
              of the California Department of Consumer Affairs may be contacted
              in writing at 1625 North Market Boulevard, Suite N112, Sacramento,
              CA 95834 or by telephone at (916) 445-1245 or (800) 952-5210.
              Hearing impaired persons may call TDD (800)-326-2297 or TDD
              (916)-928-1227, see www.dca.ca.gov for additional information.
            </p>
          </li>
          <li>
            <p>
              Entire Agreement. This Terms of Use constitutes the entire
              agreement between you and eHome Facts and governs the terms and
              conditions of your use of the Site, and supersedes all prior or
              contemporaneous communications and proposals, whether electronic,
              oral or written, between you and eHome Facts with respect to this
              Site. Notwithstanding the foregoing, you may also be subject to
              additional terms and conditions, posted policies (including but
              not limited to the Privacy Policy), guidelines, or rules that may
              apply when you use the website. eHome Facts may revise this Terms
              of Use at any time by updating this Agreement and posting it on
              the Site. Accordingly, you should visit the Site and review the
              Terms of Use periodically to determine if any changes have been
              made. Your continued use of this website after any changes have
              been made to the Terms of Use signifies and confirms your
              acceptance of any such changes or amendments to the Terms of Use.
            </p>
          </li>
          <li>
            <p>
              Contact Information.
            </p>
            <p>
              eHome Facts <br />
              238 E. Davis Blvd, Suite 206 <br />
              Tampa, Florida 33606 <br />
              727-475-0107 <br />
              info@ehomefacts.com <br />
            </p>
            <p>
              This Agreement was last modified on
              {{ new Date().toLocaleDateString() }}.
            </p>
          </li>
        </ol>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
