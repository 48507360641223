const state = () => ({
  showCashOffer: false,
  cashOffer: null,
  arv: null,
  rehab: null,
  fees: null
});

const mutations = {
  SET_CASH_OFFER_STATE(state, payload) {
    state.showCashOffer = payload;
  },
  SET_CASH_OFFER(state, payload) {
    state.cashOffer = payload;
  },
  SET_OFFER_DETAILS(state, payload) {
    state.cashOffer = payload.cashOffer;
    state.arv = payload.arv;
    state.rehab = payload.rehab;
    state.fees = payload.fees;
  }
};

const actions = {
  setCashOfferState(context, payload) {
    context.commit("SET_CASH_OFFER_STATE", payload);
  }
};

const getters = {
  showCashOffer: state => state.showCashOffer,
  cashOffer: state => state.cashOffer
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
