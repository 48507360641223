<template>
  <div class="map-compare-popup-container d-flex flex-column flex-start">
    <v-btn
      depressed
      class="compare-btn"
      @click="onCompareBtnClick"
      id="v-step-3"
    >
      {{ `Compare ${compareNumber}/${totalNum}` }}
    </v-btn>
    <div v-if="popupOpen" class="compare-popup">
      <map-compare-popup-get-started v-if="mapPopupViewMode === 1" />
      <map-compare-popup-compare v-if="mapPopupViewMode === 2" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MapComparePopupGetStarted from "@/components/MapComparePopupGetStarted";
import MapComparePopupCompare from "@/components/MapComparePopupCompare";

export default {
  name: "MapComparePopup",
  components: {
    MapComparePopupGetStarted,
    MapComparePopupCompare
  },
  props: {
    //
  },
  data: () => ({
    totalNum: 3,
    popupOpen: false
  }),
  computed: {
    ...mapGetters("homesStore", ["compareComps"]),
    ...mapState("viewsStore", {
      viewsState: state => state,
      snack: state => state.snack
    }),
    mapPopupViewMode() {
      return this.viewsState.mapPopupViewMode;
    },
    compareNumber() {
      return this.compareComps.length;
    }
  },
  methods: {
    onCompareBtnClick() {
      this.popupOpen = !this.popupOpen;
      if (this.compareNumber > 0) {
        this.$store.dispatch("viewsStore/setMapPopupViewMode", 2);
      } else {
        this.$store.dispatch("viewsStore/setMapPopupViewMode", 1);
      }
    }
  },
  watch: {
    compareNumber: {
      handler(newVal, oldVal) {
        if (newVal > 0 && newVal > oldVal) {
          this.popupOpen = true;
          this.$store.dispatch(
            "viewsStore/setMapPopupViewMode",
            this.viewsState.mapPopupViewMode
          );
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.map-compare-popup-container {
  position: absolute;
  bottom: 0%;
  left: 40px;
  width: calc(100% - 80px);

  .compare-btn {
    width: 180px;
    height: 50px;
    padding: 10px;
    background: #5c5e62 !important;
    border-radius: 0;
    font-family: "Helvetica Neue Bold";
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: none;
  }

  .compare-popup {
    width: 100%;
  }
}
</style>
