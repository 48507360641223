<template>
  <div class="home-picture-view-container">
    <v-tabs v-model="selectedTab" background-color="transparent">
      <v-tab v-for="tab in tabs" :key="tab">
        {{ tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item v-for="tab in tabs" :key="tab">
        <images-view
          v-if="tab === 'Images'"
          :hideSide="$vuetify.breakpoint.mobile"
          :images="images"
          :propertyDetails="propertyDetails"
        />
        <street-view v-if="tab === 'Street View'" :location="location" />
        <floorplan-view
          v-if="tab === 'Floorplan'"
          :floorplanImage="floorplanImage"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ImagesView from "@/components/ImagesView";
import StreetView from "@/components/StreetView";
import FloorplanView from "@/components/FloorplanView";

export default {
  name: "HomePictureView",
  components: {
    ImagesView,
    StreetView,
    FloorplanView
  },
  props: {
    propertyDetails: Object,
    location: Object,
    images: Array,
    floorplanImage: String
  },
  data: () => ({
    selectedTab: null,
    tabs: ["Images", "Street View", "Floorplan"]
  }),
  mounted() {
    if (!this.images || (this.images && !this.images.length)) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.home-picture-view-container {
  height: 100%;
}

::v-deep {
  .v-tabs-items {
    height: calc(100% - 48px);
  }

  .v-window__container {
    height: 100%;
  }

  .v-window-item {
    height: 100%;
  }
}
</style>
