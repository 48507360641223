import Vue from "vue";

import router from "@/routes/router";
import store from "@/stores";

import VueResizeText from "vue-resize-text";
Vue.use(VueResizeText);

import "@/services/ApiService";
import "@/plugins/googlemap";

import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
Vue.use(VueTour);

import vuetify from "@/plugins/vuetify";

import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

Vue.use(VueTelInputVuetify, {
  vuetify
});

import App from "@/App.vue";
import "@/styles/app.scss";

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
