<template>
  <div class="address-question">
    <p>
      To get started, please confirm the property address below.
    </p>
    <div class="my-6">
      <p>Property Address</p>
      <GmapAutocomplete
        :value="selectedAddress.address"
        class="gmap-autocomplete"
        @place_changed="getAddress"
      />
    </div>
    <p class="subtitle-1">
      If this is not the property that you wish to get a cash offer for, please
      close this window and enter the correct address in the search bar.
      Otherwise, please proceed.
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("homesStore", ["selectedAddress"])
  },
  methods: {
    getAddress(address) {
      this.$emit("answer", address.formatted_address);
    }
  }
};
</script>

<style lang="scss" scoped>
.pac-target-input {
  width: 100%;
  height: 100%;

  &:focus {
    outline: none;
  }
}

.gmap-autocomplete {
  width: 100%;
  height: 40px;
  background-color: white;
  border: 2px solid #a0c3d2;
  border-radius: 3px;
  padding-left: 15px;
  font-size: 1rem;

  &:focus {
    outline: none;
  }
}
</style>
