import Vue from "vue";
import Vuex from "vuex";
import createdPersistedState from "vuex-persistedstate";

import user from "@/stores/modules/user";
import homesStore from "@/stores/modules/homes";
import viewsStore from "@/stores/modules/views";
import auth from "@/stores/modules/auth";
import rehab from "@/stores/modules/rehab";
import cashoffer from "@/stores/modules/cashoffer";

Vue.use(Vuex);

const state = () => ({
  // Add states here
  maintenanceMode: false
});

const mutations = {
  // Add mutations here
};

const actions = {
  // Add actions here
};

const getters = {
  // Add getters here
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules: {
    user,
    homesStore,
    viewsStore,
    auth,
    rehab,
    cashoffer
  },
  plugins: [
    createdPersistedState({
      paths: ["user", "homesStore", "viewsStore", "auth", "rehab"]
    })
  ],
  strict: process.env.NODE_ENV !== "production"
});
