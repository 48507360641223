<template>
  <div class="pro-floorplan-view-container d-flex align-center justify-center">
    <img v-if="floorplanImage" :src="floorplanImage" class="floorplan-img" />
    <div v-else>
      <h1>No Floor Plan Available</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProFloorplanView",
  props: {
    floorplanImage: String
  },
  data: () => ({
    //
  }),
  mounted() {
    //
  }
};
</script>

<style lang="scss" scoped>
.pro-floorplan-view-container {
  width: 100%;
  height: 100%;

  .floorplan-img {
    width: auto;
    height: 100%;
  }
}
</style>
