<template>
  <div class="quick-compare-image-row-container d-flex">
    <v-row style="min-height: 500px">
      <v-col
        cols="4"
        md="4"
        class="pa-0 pr-4"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <div class="map-container" :style="infoContainerStyle">
          <div class="map-inner-wrapper">
            <div class="empty-header" />
            <google-map
              :properties="properties"
              :markers="markers"
              :displayComparePopup="false"
              size="sm"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="pa-0 px-2">
        <div class="info-container" :style="infoContainerStyle">
          <div class="info-inner-wrapper">
            <home-picture-view
              :propertyDetails="selectedProperty"
              :images="selectedProp.images"
              :location="selectedProp.location"
              :floorplanImage="selectedProp.floorplan"
            />
          </div>
        </div>
        <home-address-info selected :addressInfo="selectedInfo" />
      </v-col>
      <v-col cols="12" md="4" class="pa-0 pl-4">
        <div class="info-container" :style="infoContainerStyle">
          <div class="info-inner-wrapper">
            <home-picture-view
              :propertyDetails="compareData"
              :images="compareProp.images"
              :location="compareProp.location"
              :floorplanImage="compareProp.floorplan"
            />
          </div>
        </div>
        <home-address-info :addressInfo="compareInfo" />
        <div class="action-button-row d-flex justify-between">
          <v-btn
            v-if="!compareData.suggestedDiscard"
            depressed
            class="discard-btn"
            @click="onDiscardClick"
          >
            Discard
          </v-btn>
          <v-btn v-else depressed class="discard-btn" @click="onUnDiscardClick">
            UnDiscard
          </v-btn>
          <v-spacer />
          <v-btn depressed class="property-navigate-btn" @click="onPrevComp">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn depressed class="property-navigate-btn" @click="onNextComp">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <discard-comp-modal
      v-if="showDiscardCompModal"
      :dialog="showDiscardCompModal"
      :address="compareInfo.address"
      @cancel-discard-comp="onDiscardCompCancelled"
      @confirm-discard-comp="onDiscardCompConfirmed"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import GoogleMap from "@/components/Shared/GoogleMap";
import HomePictureView from "@/components/HomePictureView";
import HomeAddressInfo from "@/components/HomeAddressInfo";
import DiscardCompModal from "@/components/DiscardCompModal";

export default {
  name: "QuickCompareImageRow",
  components: {
    GoogleMap,
    HomePictureView,
    HomeAddressInfo,
    DiscardCompModal
  },
  props: {
    compareData: Object
  },
  data: () => ({
    showDiscardCompModal: false
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedProperty", "selectedAddress"]),
    markers() {
      return _.map(this.properties, item => {
        return {
          position: {
            lat: item.latitude,
            lng: item.longitude
          }
        };
      });
    },
    properties() {
      const selected = { ...this.selectedProperty, type: "selected" };
      const comp = { ...this.compareData, type: "suggested" };
      return [selected, comp];
    },
    infoContainerStyle() {
      let paddingPercent = 58.5;

      return {
        paddingTop: `${paddingPercent}%`
      };
    },
    cityInfo() {
      const { city, state, zipCode } = this.selectedAddress;
      const { subdivision } = this.selectedProperty;
      const cityAddress = `${city}, ${state} ${zipCode}`;
      return `${cityAddress} | ${subdivision}`;
    },
    selectedInfo() {
      const { subdivision } = this.selectedProperty;
      const { address } = this.selectedAddress;

      return {
        address,
        subdivision
      };
    },
    compareInfo() {
      const { address, subdivision } = this.compareData;

      return {
        address,
        subdivision
      };
    },
    selectedProp() {
      const {
        latitude: lat,
        longitude: lng,
        mlsImageUrls: images,
        floorplanURL: floorplan
      } = this.selectedProperty;
      return {
        location: {
          lat,
          lng
        },
        images,
        floorplan
      };
    },
    compareProp() {
      const {
        latitude: lat,
        longitude: lng,
        mlsImageUrls: images,
        floorplanURL: floorplan
      } = this.compareData;
      return {
        location: {
          lat,
          lng
        },
        images,
        floorplan
      };
    }
  },
  mounted() {
    //
  },
  methods: {
    onDiscardClick() {
      this.showDiscardCompModal = true;
    },
    onDiscardCompConfirmed() {
      this.showDiscardCompModal = false;
      this.$emit("discard-quick-compare", this.compareData);
    },
    onDiscardCompCancelled() {
      this.showDiscardCompModal = false;
    },
    onPrevComp() {
      this.$emit("prev-quick-compare", this.compareData);
    },
    onNextComp() {
      this.$emit("next-quick-compare", this.compareData);
    },
    onUnDiscardClick() {
      console.log("UnDiscard");
      this.$emit("undiscard-quick-compare", this.compareData);
    }
  }
};
</script>

<style lang="scss" scoped>
.quick-compare-image-row-container {
  margin-bottom: 20px;

  .map-container {
    position: relative;
    width: 100%;
    height: calc(100% - 48px);
  }

  .map-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .info-container {
    position: relative;
    width: 100%;
  }

  .info-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .empty-header {
    width: 100%;
    height: 48px;
  }

  .discard-btn {
    width: 90px;
    height: 34px;
    border: solid 1px #04b6da;
    border-radius: 0;
    font-family: "Helvetica Neue Medium";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #04b6da;
    text-transform: none;
    margin-top: 20px;
  }
}

::v-deep {
  .property-navigate-btn {
    width: 34px;
    height: 34px;
    min-width: 36px !important;
    border: solid 1px #04b6da !important;
    background: rgba(4, 182, 218, 0.1) !important;
    color: #04b6da;
    padding: 0 !important;
    margin-left: 10px;
  }
}
</style>
