<template>
  <div class="floorplan-view-container">
    <premium-floorplan-view v-if="!isAuthenticated || !isProUser" />
    <pro-floorplan-view v-else :floorplanImage="floorplanImage" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PremiumFloorplanView from "@/components/PremiumFloorplanView";
import ProFloorplanView from "@/components/ProFloorplanView";

export default {
  name: "FloorplanView",
  components: {
    ProFloorplanView,
    PremiumFloorplanView
  },
  props: {
    floorplanImage: String
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "isProUser"])
  }
};
</script>

<style lang="scss" scoped>
.floorplan-view-container {
  height: 100%;
}
</style>
