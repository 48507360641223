<template>
  <v-dialog
    v-model="$attrs.value"
    persistent
    max-width="800"
    content-class="rounded-0"
  >
    <div class="cash-offer-card">
      <div class="cash-offer-card-header">
        Get Cash Offer
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="cash-offer-card-body">
        <component
          :is="currentQuestion.component"
          @answer="getAnswer"
          :question-data="questions"
          :selectedAnswer="currentQuestionAnswer"
        >
          <template #question>
            {{ currentQuestion.question }}
          </template>
        </component>
      </div>

      <template>
        <v-progress-linear :value="progressPercentage"></v-progress-linear>
      </template>

      <div class="cash-offer-card-footer">
        <button class="cash-offer-card-back-btn" @click="back">
          {{ prevBtnText }}
        </button>

        <v-spacer></v-spacer>

        <button
          class="cash-offer-card-next-btn"
          @click="next"
          :class="{ 'btn-disabled': disabled }"
          :disabled="disabled"
        >
          {{ nextBtnText }}
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import CashOfferAddress from "./CashOfferAddress";
import CashOfferConditionStep from "./CashOfferConditionStep";
import CashOfferEstimateStep from "./CashOfferEstimateStep";
import CashOfferYesOrNoQuestion from "./CashOfferYesOrNoQuestion";
import CashOfferContactInfo from "./CashOfferContactInfo";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "CashOfferModal",
  props: ["address"],
  components: {
    CashOfferAddress,
    CashOfferConditionStep,
    CashOfferEstimateStep,
    CashOfferYesOrNoQuestion,
    CashOfferContactInfo
  },
  data() {
    return {
      currentPosition: 0,
      initQuestions: [
        {
          name: "address",
          component: "CashOfferAddress",
          answer: this.address
        },
        {
          name: "condition",
          component: "CashOfferConditionStep",
          answer: null
        }
      ],
      questions: [
        {
          name: "address",
          component: "CashOfferAddress",
          answer: this.address
        },
        {
          name: "condition",
          component: "CashOfferConditionStep",
          answer: null
        }
      ],
      excellentQuestions: [
        {
          name: "estimate",
          component: "CashOfferEstimateStep",
          answer: true
        },
        {
          name: "contact",
          component: "CashOfferContactInfo",
          answer: true
        }
      ],
      goodQuestions: [
        {
          name: "roof",
          question:
            "Has your roof been replaced and/or completely reshingled in the last 10 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "bath_updates",
          question:
            "Have you updated any of your bathrooms in the last 5 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "kitchen_updates",
          question: "Have you updated your kitchen in the last 5 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "settlement",
          question:
            "Does your house have any present signs of settlement or sinkhole activity?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "estimate",
          component: "CashOfferEstimateStep",
          answer: true
        },
        {
          name: "contact",
          component: "CashOfferContactInfo",
          answer: true
        }
      ],
      fairQuestions: [
        {
          name: "roof",
          question:
            "Has your roof been replaced and/or completely reshingled in the last 10 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "bath_updates",
          question:
            "Have you updated any of your bathrooms in the last 5 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "kitchen_updates",
          question: "Have you updated your kitchen in the last 5 years?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "settlement",
          question:
            "Does your house have any present signs of settlement or sinkhole activity?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "estimate",
          component: "CashOfferEstimateStep",
          answer: true
        },
        {
          name: "contact",
          component: "CashOfferContactInfo",
          answer: true
        }
      ],
      badQuestions: [
        {
          name: "settlement",
          question:
            "Does your house have any present signs of settlement or sinkhole activity?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "estimate",
          component: "CashOfferEstimateStep",
          answer: true
        },
        {
          name: "contact",
          component: "CashOfferContactInfo",
          answer: true
        }
      ],
      kitchenQuestions: [
        {
          name: "stone_countertops",
          question:
            "Are your counter tops made of granite or another polished stone?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "wood_cabinets",
          question: "Are your cabinets made of wood and/or plywood?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        },
        {
          name: "ss_appliances",
          question: "Are your kitchen appliances stainless steel?",
          component: "CashOfferYesOrNoQuestion",
          answer: null
        }
      ],
      settlementQuestions: [
        {
          name: "remediation",
          component: "CashOfferYesOrNoQuestion",
          question:
            "Has there ever been any prior settlement/sinkhole remediation performed on the property?",
          answer: null
        }
      ]
    };
  },
  computed: {
    ...mapGetters("cashoffer", ["showCashOffer"]),
    currentQuestion() {
      return this.questions[this.currentPosition];
    },
    currentQuestionAnswer() {
      return this.questions[this.currentPosition].answer;
    },
    disabled() {
      return this.questions[this.currentPosition].answer === null;
    },
    nextBtnText() {
      if (this.currentPosition === 0) {
        return "Get Started";
      }

      if (
        (this.currentPosition > 0 &&
          this.currentPosition < this.questions.length - 2) ||
        this.currentPosition === 1
      ) {
        return "Next";
      }

      if (this.currentPosition === this.questions.length - 2) {
        return "Contact an Agent?";
      }

      return "Done";
    },
    prevBtnText() {
      if (this.currentPosition === 0) {
        return "Cancel";
      }

      return "Back";
    },
    totalQuestions() {
      return (
        this.initQuestions.length +
        this.excellentQuestions.length +
        this.goodQuestions.length +
        this.fairQuestions.length +
        this.badQuestions.length
      );
    },
    progressPercentage() {
      if (
        (this.currentPosition + 1) / this.questions.length === 1 &&
        this.questions.length > 2
      ) {
        return 100;
      } else {
        return (this.currentPosition / this.totalQuestions) * 100;
      }
    }
  },
  created() {
    // console.log(this.address);
    // this.questions[0].answer = this.address;
  },
  methods: {
    ...mapActions("cashoffer", ["setCashOfferState"]),
    getAnswer(answer) {
      const prevAnswer = this.questions[this.currentPosition].answer;
      this.questions[this.currentPosition].answer = answer;
      // Set questions based upon the conditon of the home
      if (this.questions[this.currentPosition].name === "condition") {
        const condition = this.questions[this.currentPosition].answer;
        this.questions = _.cloneDeep(this.questionsByCondition(condition));
      }

      // Add additonal questions about the kitchen if recently updated
      if (this.questions[this.currentPosition].name === "kitchen_updates") {
        const answer = this.questions[this.currentPosition].answer;
        if (answer && !prevAnswer) {
          this.questions.splice(
            this.currentPosition + 1,
            0,
            ...this.kitchenQuestions
          );
        } else if (!answer && prevAnswer) {
          this.questions.splice(
            this.currentPosition + 1,
            this.kitchenQuestions.length
          );
        }
      }

      // Add additonal questions about settlement/sinkhole
      if (this.questions[this.currentPosition].name === "settlement") {
        const answer = this.questions[this.currentPosition].answer;
        if (!answer && (prevAnswer === null || prevAnswer)) {
          this.questions.splice(
            this.currentPosition + 1,
            0,
            ...this.settlementQuestions
          );
        } else if (answer && prevAnswer !== null && !prevAnswer) {
          this.questions.splice(
            this.currentPosition + 1,
            this.settlementQuestions.length
          );
        }
      }
    },
    back() {
      if (this.currentPosition > 0) {
        this.currentPosition -= 1;
      } else if (this.currentPosition === 0) {
        this.close();
      }
    },
    next() {
      if (this.currentPosition < this.questions.length - 1) {
        this.currentPosition += 1;
      } else if (this.currentPosition === this.questions.length - 1) {
        this.close();
      }
    },
    close() {
      this.setCashOfferState(false);
      this.questions = _.cloneDeep(this.initQuestions);
      this.currentPosition = 0;
    },
    questionsByCondition(condition) {
      const startQuestions = _.cloneDeep(this.questions.slice(0, 2));
      switch (condition) {
        case "excellent":
          return [...startQuestions, ..._.cloneDeep(this.excellentQuestions)];
        case "good":
          return [...startQuestions, ..._.cloneDeep(this.goodQuestions)];
        case "fair":
          return [...startQuestions, ..._.cloneDeep(this.fairQuestions)];
        case "bad":
          return [...startQuestions, ..._.cloneDeep(this.badQuestions)];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cash-offer-card {
  min-height: 550px;
  display: flex;
  flex-direction: column;
  font-family: "Helvetica Neue Medium";
  background: white;

  &-header {
    display: flex;
    padding: 20px;
    border-top: 10px solid #04b6da;
    font-size: 1.5rem;
    color: #5c5e62;
  }

  &-body {
    display: flex;
    flex-grow: 1;
    padding: 20px;
    border-top: 1px solid #9b9b9b;
    border-bottom: 1px solid #9b9b9b;
    font-size: 1.5rem;
    color: #5c5e62;
  }

  &-footer {
    display: flex;
    padding: 20px;
  }

  .btn-disabled {
    background-color: rgba(4, 182, 218, 0.3);
  }

  &-back-btn {
    color: #04b6da;
    padding: 10px 24px;
    border: 1px solid #04b6da;

    &:focus {
      outline: none;
    }
  }

  &-next-btn {
    color: white;
    padding: 10px 24px;
    background-color: #04b6da;

    &:focus {
      outline: none;
    }
  }
}
</style>
