<template>
  <v-dialog
    v-model="show"
    :fullscreen="$vuetify.breakpoint.mobile"
    max-width="600"
  >
    <v-card v-if="!forgotPassword">
      <v-card-title>
        <span class="headline">Sign In</span>
      </v-card-title>
      <v-card-text>
        <v-alert v-if="error" type="error">
          {{ error.response.data.message }}
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Username*"
              v-model="formData.username"
              @keyup.enter="onSignIn"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Password*"
              type="password"
              v-model="formData.password"
              @keyup.enter="onSignIn"
              required
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined color="primary" @click="forgotPassword = true"
          >Forgot Password?</v-btn
        >
        <v-spacer />
        <v-btn color="primary" text @click.stop="onSignIn">Sign In</v-btn>
        <v-btn color="primary" text @click.stop="show = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-container>
        <v-row>
          <v-col>
            <v-form ref="forgotPasswordForm" @submit.prevent="submit">
              <v-row>
                <v-col>
                  <h2>Enter the email address associated with the account</h2>
                  <p>
                    An email will be sent shortly with a link to reset your
                    password
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    v-model="email"
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    class="mr-4"
                    type="submit"
                    :loading="loading"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SignInModal",
  props: {
    value: Boolean
  },
  data: () => ({
    loading: false,
    forgotPassword: false,
    email: "",
    error: null,
    formData: {
      username: null,
      password: null
    }
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.error = "";
        this.formData.username = "";
        this.formData.password = "";
        this.forgotPassword = false;
      }
    }
  },
  methods: {
    ...mapActions("auth", ["login", "requestPasswordResetEmail"]),
    async onSignIn() {
      try {
        await this.login(this.formData);
        this.show = false;
        this.error = "";
      } catch (err) {
        console.error("-- Login Error --", err);
        this.error = err;
      }
    },
    async submit() {
      try {
        this.loading = true;
        await this.requestPasswordResetEmail(this.email);
      } catch (error) {
        console.error(error.response);
      } finally {
        this.loading = false;
        this.forgotPassword = false;
        this.show = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
