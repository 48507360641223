<template>
  <v-card
    hover
    :style="border"
    class="condition-card"
    :class="{ active: active }"
  >
    <img :src="imageSrc" />
    <div :style="textColor" class="condition-card-title">{{ title }}</div>
    <div class="condition-card-description">
      <slot name="description"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    title: String,
    color: String,
    active: Boolean
  },
  computed: {
    border() {
      return {
        border: `1px solid ${this.color}`
      };
    },
    textColor() {
      return {
        color: this.color
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.condition-card {
  padding: 20px 10px;
  border-radius: 5px;
  height: 100%;
  text-align: center;
  color: #5c5e62;

  &-title {
    margin: 15px 0;
    font-size: 1.5rem;
  }

  &-description {
    font-size: 1rem;
  }
}

.active {
  background-color: #e8f4fc;
}
</style>
