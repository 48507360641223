import Vue from "vue";
import axios from "axios";

Vue.prototype.$http = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common[
    "Authorization"
  ] = `Token ${token}`;
}

// Vue.prototype.$http.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.common["Authorization"] = `Token ${token}`;
//     }
//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }
// );

// Vue.prototype.$http.interceptors.response.use(
//   res => {
//     return res;
//   },
//   err => {
//     if (err.status === 401) {
//       // eslint-disable-next-line no-undef
//       $helpers.logout();
//     }
//     return Promise.reject(err);
//   }
// );
