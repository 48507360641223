export const ADDITIONAL_COMPS = [
  {
    address: "230 StarFruit Dr. Sanfransisco, CA 90302",
    state: "FL",
    city: "Tampa",
    zipCode: "33602",
    position: {
      lat: 29.792391,
      lng: -81.2652477
    },
    neighborhood: "Hampton",
    distance: 0.79,
    beds: 3,
    baths: 2,
    sqFt: 1460,
    lotSize: 5214,
    lastSoldDate: "Mar. 7, 2019",
    salePrice: 218900,
    dollarPerSqft: 149,
    buildMatch: 82.3,
    estARV: 230150,
    estMIN: 230090,
    estMAX: 240875,
    image:
      "https://res.cloudinary.com/dohvb78b6/image/upload/v1578760884/EHomeFacts/kitchen-2400367_640_xyhfbt.jpg",
    compare: false,
    discarded: true,
    preDiscarded: true
  },
  {
    address: "230 StarFruit Ct. Los Angeles, CA 90019",
    state: "FL",
    city: "Tampa",
    zipCode: "33602",
    position: {
      lat: 29.785491,
      lng: -81.695477
    },
    neighborhood: "Lakeview",
    distance: 0.23,
    beds: 2,
    baths: 2.5,
    sqFt: 1290,
    lotSize: 6948,
    lastSoldDate: "Jan. 25, 2019",
    salePrice: 220000,
    dollarPerSqft: 171,
    buildMatch: 69.3,
    estARV: 222469,
    estMIN: 220390,
    estMAX: 230875,
    image:
      "https://res.cloudinary.com/dohvb78b6/image/upload/v1578760883/EHomeFacts/kitchen-1940174_640_syac40.jpg",
    compare: false,
    discarded: true,
    preDiscarded: true
  },
  {
    address: "230 StarFruit Rd. Tampa, FL 33602",
    state: "FL",
    city: "Tampa",
    zipCode: "33602",
    position: {
      lat: 27.723491,
      lng: -81.5267477
    },
    neighborhood: "Meadowlawn",
    distance: 0.06,
    beds: 3,
    baths: 3,
    sqFt: 1530,
    lotSize: 7571,
    lastSoldDate: "Aug. 8, 2018",
    salePrice: 235000,
    dollarPerSqft: 154,
    buildMatch: 67.9,
    estARV: 241073,
    estMIN: 230390,
    estMAX: 240875,
    image:
      "https://res.cloudinary.com/dohvb78b6/image/upload/v1578760883/EHomeFacts/home-1680800_640_iajwmg.jpg",
    compare: false,
    discarded: true,
    preDiscarded: true
  }
];
