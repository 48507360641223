export default {
  methods: {
    $_addressMixin_removeFirstSpace(str) {
      return (str && str.replace(/^\s/, "")) || "";
    },
    $_addressMixin_separateAddress(address) {
      const splitedAddress = (address && address.split(",")) || [];
      if (splitedAddress.length > 0) {
        const streetAddress = splitedAddress[0];
        const city = this.$_addressMixin_removeFirstSpace(splitedAddress[1]);
        const stateZip = this.$_addressMixin_removeFirstSpace(
          splitedAddress[2]
        );
        const state = stateZip.split(" ")[0];
        const zipCode = stateZip.split(" ")[1];

        return {
          address,
          streetAddress,
          city,
          state,
          zipCode
        };
      } else {
        return null;
      }
    }
  }
};
