<template>
  <v-container>
    <v-row v-if="formmatedQuestionData.condition === 'excellent'">
      <v-col class="text-center excellent--condition">
        <p>The house is most likely worth the estimated ARV amount of:</p>
        <div class="mt-16">
          <h1>
            ${{ $_numbersMixin_getNumberWithCommas(selectedProperty.arv) }}
          </h1>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col class="text-center estimated--rehab--cost">
        <p>
          Estimated Rehab Cost:
        </p>

        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <h1 v-else>
          ${{ $_numbersMixin_getNumberWithCommas(range[0]) }} - ${{
            $_numbersMixin_getNumberWithCommas(range[1])
          }}
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import numbersMixin from "@/mixins/numbersMixin";
import { mapGetters } from "vuex";

export default {
  name: "RehabCalculatorEstimateStep",
  props: ["questionData"],
  mixins: [numbersMixin],
  data: () => ({
    cost: 0,
    range: [],
    loading: false
  }),
  computed: {
    ...mapGetters("homesStore", ["selectedProperty"]),
    formmatedQuestionData() {
      const data = {};
      this.questionData.forEach(q => {
        data[q.name] = q.answer;
      });

      data.est_arv = this.selectedProperty.arv;
      data.living_area = this.selectedProperty.livingArea;
      data.baths = this.selectedProperty.baths;

      return data;
    }
  },
  methods: {
    async calculateRehabCostsAPI() {
      this.loading = true;

      try {
        const res = await this.$http.post("/rehab", this.formmatedQuestionData);
        this.cost = res.data.rehab_estimate;
        this.range = res.data.rehab_range;
      } catch (error) {
        console.error("Error getting rehab calculation:", error);
      }

      this.loading = false;
    }
  },
  mounted() {
    if (this.formmatedQuestionData.condition !== "excellent") {
      this.calculateRehabCostsAPI();
    }
  },
  watch: {
    questionData(newVal, oldVal) {
      if (this.formmatedQuestionData.condition !== "excellent") {
        this.calculateRehabCostsAPI();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.excellent--condition {
  p {
    font-size: 1.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #5c5e62;
  }

  h1 {
    padding: 1rem 0;
    color: #04b6da;
    font-size: 3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #04b6da;
  }
}

.estimated--rehab--cost {
  p {
    font-size: 1.2rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #5c5e62;
  }

  h1 {
    padding: 1rem 0;
    color: #04b6da;
    font-size: 5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #04b6da;
  }
}
</style>
