import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/stores";

// Router Setup
import routes from "./routes";

Vue.use(VueRouter);

// Configure Router
const router = new VueRouter({
  linkActiveClass: "active",
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log(savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters["auth/isAuthenticated"]) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
