<template>
  <v-dialog v-model="show" width="500">
    <FreeUserSignUpForm @close="close" />
  </v-dialog>
</template>

<script>
import FreeUserSignUpForm from "./FreeUserSignUpForm";
export default {
  components: {
    FreeUserSignUpForm
  },
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    close() {
      this.show = false;
      // this.$emit("close");
    }
  }
};
</script>

<style></style>
